/* eslint-disable camelcase */
import { Avatar, Typography } from '@mui/material'
import { amber, blue, deepPurple, green, grey, pink } from '@mui/material/colors'
import { formatHelpers } from '@partnerslate/core'
import { Dialog, ProjectDetailContent } from '@partnerslate/ui-components'
import { ProjectDetail } from '@partnerslate/ui-components/lib/ProjectDetails/domain/project'
import React, { useEffect } from 'react'

import { EngagementStatusDisplay } from '@/domain/engagements'

import * as instrumentation from '../../helpers/instrumentation'

type ProjectDetailModalProps = {
  project?: ProjectDetail | null
  engagementStatus: EngagementStatusDisplay
  onClose: () => void
}
export default function ProjectDetailModal({
  project,
  engagementStatus,
  onClose,
}: ProjectDetailModalProps): JSX.Element | null {
  useEffect(() => {
    if (project) {
      instrumentation.viewProjectDetailModal(project.id)
    }
  }, [project])

  if (!project || !engagementStatus) {
    return null
  }

  return (
    <Dialog onClose={onClose} open={!!project} title={project.name} size="lg">
      <ProjectDetailContent
        project={project}
        avatar={<ProjectAvatar project={project} engagementStatus={engagementStatus} />}
      />
    </Dialog>
  )
}

type ProjectAvatarProps = {
  project: ProjectDetail
  engagementStatus: EngagementStatusDisplay
}
function ProjectAvatar({ project, engagementStatus }: ProjectAvatarProps) {
  return (
    <Avatar
      sx={{
        bgcolor: getEngagementStatusColor(engagementStatus),
        width: 56,
        height: 56,
        mr: 1,
      }}
      variant="rounded"
    >
      <Typography color="white" variant="h1">
        {formatHelpers.firstLetterCase(project.name)}
      </Typography>
    </Avatar>
  )
}

export function getEngagementStatusColor(status: EngagementStatusDisplay): string {
  const colors: Record<EngagementStatusDisplay, string> = {
    nda: amber[800],
    initial_discussion: blue[400],
    tech_review: blue[700],
    trial_and_development: deepPurple[400],
    negotiating_agreement: pink[400],
    pre_production: green.A400,
    production: green.A700,
    inactive_no_longer_interested: grey[400],
    inactive_on_hold: grey[400],
    inactive_brand_unresponsive: grey[400],
    inactive_coman_unresponsive: grey[400],
  }
  return colors[status]
}
