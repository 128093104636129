import * as React from 'react'

import { EmailIcon } from '@/components'
import BaseForm from '@/components/base-form'
import { SubmitButton } from '@/components/base-form/buttons'
import { EmailField } from '@/components/base-form/fields'
import FormError from '@/components/base-form/form-error'
import { Size } from '@/components/button/types'
import Strings from '@/constants'
import { endpoints } from '@/helpers/services'
import { resetPasswordPayload } from '@/redux/api-payloads'

import { ResetPasswordSchema } from '../schemas'
import styles from './styles.scss'

enum FieldNames {
  username = 'username',
}

const initialValues = {
  [FieldNames.username]: '',
}

// eslint-disable-next-line @typescript-eslint/ban-types
type ResetPasswordFormProps = {}

const ResetPasswordForm: React.FC<ResetPasswordFormProps> = () => (
  <BaseForm
    initialValues={initialValues}
    validationSchema={ResetPasswordSchema}
    onSubmitPayload={resetPasswordPayload}
    formKey={endpoints.getResetPassword}
  >
    <div className={styles.loginInputs}>
      <EmailField
        name={FieldNames.username}
        icon={<EmailIcon />}
        label={Strings.auth.login.email}
        placeholder={Strings.inputs.enterHere}
      />
    </div>
    <FormError formKey={endpoints.getResetPassword} />
    <div className={styles.buttons}>
      <SubmitButton
        ariaLabel={Strings.general.next}
        title={Strings.general.next}
        size={Size.large}
        className={styles.submitButton}
      />
    </div>
  </BaseForm>
)

export default ResetPasswordForm
