import { call } from 'redux-saga/effects'

import toaster from '@/components/toast'

export function* endRequest(action: {
  type: string
  payload: {
    name: string
    pending: boolean
    error: ApiError | undefined
    meta: {
      toast?: boolean
      emitDetails?: boolean
    }
  }
}): GeneratorType {
  const { error, meta } = action.payload
  const { toast, emitDetails } = meta || {}

  if (toast) {
    if (error) {
      if (emitDetails) {
        const { details } = error
        yield call(toaster.error, undefined, details)
      } else {
        yield call(toaster.error)
      }
    } else {
      yield call(toaster.success)
    }
  }
}
