/* eslint-disable @typescript-eslint/explicit-module-boundary-types */
import { ModalActions } from './constants'
import eventManager from './event-manager'
import ModalTypes from './types'

const emitEvent = (
  event: typeof ModalActions[keyof typeof ModalActions],
  type?: keyof ModalTypes,
  payload?: Record<string, unknown>,
) => {
  if (eventManager.mounted === true) {
    eventManager.emit(event, type, payload)
  }
}

const modal = {
  renderModal: (type: keyof ModalTypes, args: Record<string, unknown>) =>
    emitEvent(ModalActions.RENDER_MODAL, type, args),
  remove: () => emitEvent(ModalActions.REMOVE_MODAL),
  setContent: (content: Record<string, unknown>) =>
    emitEvent(ModalActions.SET_DETAIL_CONTENT, undefined, content),
}

eventManager
  .register(ModalActions.DID_MOUNT, () => {
    eventManager.mounted = true
  })
  .register(ModalActions.WILL_UNMOUNT, () => {
    eventManager.mounted = false
  })

export default modal
