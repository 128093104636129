import { normalize, NormalizedSchema, schema } from 'normalizr'
import { Company, User } from 'partnerslate-models'

const companySchema = new schema.Entity(
  'companies',
  {},
  {
    idAttribute: 'slug',
    processStrategy: (company) => new Company(company),
  },
)

const companiesSchema = new schema.Array(companySchema)

const userSchema = new schema.Entity(
  'user',
  {
    companies: [companySchema],
  },
  {
    processStrategy: (user) => new User(user),
  },
)

const usersSchema = new schema.Array(userSchema)

const companiesNormalizer = (
  company: Array<Company>,
): NormalizedSchema<{ companies: { [id: string]: Company } }, string> =>
  normalize(company, companiesSchema)

const userNormalizer = (user: User): NormalizedSchema<{ user: { [id: string]: User } }, string> =>
  normalize(user, userSchema)

const usersNormalizer = (
  users: Array<User>,
): NormalizedSchema<{ user: { [id: string]: User } }, string> => normalize(users, usersSchema)

export { companiesNormalizer, userNormalizer, usersNormalizer }
