import { FieldOption } from 'partnerslate-models'
import React from 'react'

import styles from './styles.scss'

type RadioButtonProps = {
  onChange: (value: FieldOption) => void
  checked: boolean
  label: string
  description: string | undefined
  value: FieldOption
  testId?: string | undefined
}

function RadioButton(props: RadioButtonProps): JSX.Element {
  const { label, value, description, checked, onChange, testId } = props
  const id = `${label}-${value.id}`
  return (
    <li className={styles.radioBtn}>
      <label htmlFor={id} className={styles.label}>
        <div>
          <input
            aria-label={id}
            id={id}
            type="radio"
            checked={checked}
            onChange={() => onChange(value)}
            data-testid={testId}
          />
          <h4>{label}</h4>
        </div>
        {description ? <span className={styles.description}>{description}</span> : null}
      </label>
    </li>
  )
}

RadioButton.defaultProps = {
  testId: undefined,
}

export default RadioButton
