import { joinStr } from '@clearsummit/carabiners'
import React from 'react'

import { Images } from '@/constants'

import styles from './styles.scss'

interface Props {
  image: string
  className?: string
}

function ProfileImage(props: Props): JSX.Element {
  const { image, className } = props
  return (
    <div className={joinStr(styles.editProfileImage, className)}>
      <img src={image || Images.user} alt={image} />
    </div>
  )
}

ProfileImage.defaultProps = {
  className: undefined,
}

export default ProfileImage
