import React from 'react'

import EmailLink from '../email-link'

type Props = {
  color?: string | undefined
  linkText?: string
}
export default function EmailLinkSupport({ color, linkText }: Props): JSX.Element {
  return <EmailLink email="support@partnerslate.com" color={color} linkText={linkText} />
}
