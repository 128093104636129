import { Box, Typography } from '@mui/material'
import React, { useEffect, useState } from 'react'

import { MoveToProductionConfirmationModal } from '@/components/MoveToProductionConfirmationModal'
import {
  BrandEditableEngagementStages,
  ComanEditableEngagementStages,
  Engagement,
  isInactiveOrInNdaStage,
} from '@/domain/engagements'
import { useUpdateEngagementStatus } from '@/helpers/services/queries'

import { BrandStatusSelect } from './BrandStatusSelect'
import { ComanStatusSelect } from './ComanStatusSelect'

type StatusDropdownProps = {
  engagement: Engagement
}

// eslint-disable-next-line max-lines-per-function
export function StatusSelector({ engagement }: StatusDropdownProps): JSX.Element {
  const { isLoading, mutate } = useUpdateEngagementStatus()

  const [isInactive, setIsInactive] = useState(false)
  const [engagementToMoveToProduction, setEngagementToMoveToProduction] =
    useState<Engagement | null>(null)

  useEffect(() => {
    setIsInactive(
      [
        'inactive_no_longer_interested',
        'inactive_on_hold',
        'inactive_brand_unresponsive',
        'inactive_coman_unresponsive',
      ].includes(engagement.stage),
    )
  }, [engagement])

  const handleStatusChange = (
    newStatus: BrandEditableEngagementStages | ComanEditableEngagementStages,
  ) => {
    if (newStatus === 'production') {
      setEngagementToMoveToProduction(engagement)
      return
    }

    mutate({ engagementId: engagement.id, status: newStatus })
  }

  const displaySelectElement =
    engagement.perspective === 'brand' ? (
      <BrandStatusSelect
        currentStatus={engagement.stage as BrandEditableEngagementStages}
        onStatusChange={handleStatusChange}
        isStatusUpdating={isLoading}
        showOnlyInactiveStates={isInactiveOrInNdaStage(engagement)}
      />
    ) : (
      <ComanStatusSelect
        currentStatus={engagement.stage as ComanEditableEngagementStages}
        onStatusChange={handleStatusChange}
        isStatusUpdating={isLoading}
        showOnlyInactiveStates={isInactiveOrInNdaStage(engagement)}
      />
    )

  return (
    <Box display="flex" alignItems="center">
      {isInactive ? (
        <Typography variant="h6" color="error" mr={1}>
          Inactive
        </Typography>
      ) : null}
      {displaySelectElement}
      <MoveToProductionConfirmationModal
        engagementToMoveToProduction={engagementToMoveToProduction}
        setEngagementToMoveToProduction={setEngagementToMoveToProduction}
      />
    </Box>
  )
}
