import { instrumentation } from '@partnerslate/core'
import { SplitFactory } from '@splitsoftware/splitio-react'
import React, { useEffect } from 'react'
// @ts-ignore $scope
import { createRoot } from 'react-dom/client'
import { QueryClient, QueryClientProvider } from 'react-query'
import { Provider } from 'react-redux'
import { Route, Router, Switch, useHistory } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'

import { PrivateRoute } from '@/components'
import config from '@/config'
import { Routes } from '@/constants'
import history, { trackPageView } from '@/helpers/history'
import configureStore from '@/redux'
import App from '@/screens'
import ComanEngagementsScreen from '@/screens/coman-engagements'
import CompanyProfile from '@/screens/company-profile'
import DevTools from '@/screens/devtools'
import EngagementDetails from '@/screens/engagement-details'
import EngagementDocuments from '@/screens/engagement-details/expanded-pages/documents'
import {
  EngagementInterMessages,
  EngagementIntraNotes,
} from '@/screens/engagement-details/expanded-pages/messages'
import EngagementTodos from '@/screens/engagement-details/expanded-pages/todos'
import FourOhFour from '@/screens/four-oh-four'
import LeadJoin from '@/screens/lead-join'
import Loginpage from '@/screens/login'
import Logout from '@/screens/logout'
import Profile from '@/screens/profile'
import ResetPassword from '@/screens/reset-password'
import ResetPasswordChange from '@/screens/reset-password-change'
import Search from '@/screens/search'
import TeamInviteAcceptScreen from '@/screens/team-invite-accept'
import VerifyEmail from '@/screens/verify-email'

import mixpanel from './helpers/mixpanel'
import { OurThemeProvider } from './helpers/mui'

// Normalize must load first or it will overwrite our styles
require('react-toastify/dist/ReactToastify.css')
require('normalize.css/normalize.css')
require('@/../styles/fonts.scss')
require('@/../styles/defaults.scss')

require('@/../styles/tailwind.css')

const queryClient = new QueryClient()

if (config.mixpanel) {
  instrumentation.initMixpanel(config.mixpanel.token).then(mixpanel.initialize)
}

type ExternalRedirectProps = {
  from: string
  to: string
}
function ExternalRedirect({ from, to }: ExternalRedirectProps) {
  return (
    <Route
      exact
      path={from}
      component={() => {
        window.location.replace(to)
        return null
      }}
    />
  )
}

function AppRoutes() {
  const historyObject = useHistory()

  useEffect(() => {
    // track first page load
    trackPageView()

    // track subsequent page loads
    historyObject.listen(trackPageView)
  }, [historyObject])

  return (
    <Switch>
      <Route exact path={Routes.Login} component={Loginpage} />
      <ExternalRedirect from={Routes.SignUp} to="/register" />
      <Route path={Routes.ResetPassword} component={ResetPassword} />
      <Route exact path={Routes.ResetPasswordChange} component={ResetPasswordChange} />
      <Route exact path={Routes.LeadJoin} component={LeadJoin} />
      <Route exact path={Routes.TeamInviteAccept} component={TeamInviteAcceptScreen} />
      <Route exact path={Routes.VerifyEmail} component={VerifyEmail} />
      <Route exact path={Routes.CompanyProfile} component={CompanyProfile} />
      <Route exact path={Routes.MyProfile} component={CompanyProfile} />
      <Route exact path={Routes.FourOhFour} component={FourOhFour} />
      <Route exact path={Routes.DevTools} component={DevTools} />
      <PrivateRoute exact path={Routes.Logout} component={Logout} />
      <PrivateRoute exact path={Routes.EngagementDetails} component={EngagementDetails} />
      <PrivateRoute exact path={Routes.EngagementDocuments} component={EngagementDocuments} />
      <PrivateRoute exact path={Routes.EngagementMessages} component={EngagementInterMessages} />
      <PrivateRoute exact path={Routes.EngagementNotes} component={EngagementIntraNotes} />
      <PrivateRoute exact path={Routes.EngagementTodos} component={EngagementTodos} />
      <PrivateRoute exact path={Routes.Search} component={Search} />
      <PrivateRoute exact path={Routes.Engagements} component={ComanEngagementsScreen} />
      <PrivateRoute exact path={Routes.Profile} component={Profile} />
      <PrivateRoute exact path={Routes.ProfileSubscribe} component={Profile} />
      <PrivateRoute exact path={Routes.ProfileCompanyDetails} component={Profile} />
      <PrivateRoute exact path={Routes.ProfileTeam} component={Profile} />
      <PrivateRoute exact path={Routes.ProfilePaymentSettings} component={Profile} />
      <PrivateRoute exact path={Routes.ProfileYourInformation} component={Profile} />
      <PrivateRoute exact path={Routes.ProfileChangePassword} component={Profile} />
      <Route path="*">
        <FourOhFour />
      </Route>
    </Switch>
  )
}

function Application() {
  return (
    <OurThemeProvider>
      <QueryClientProvider client={queryClient}>
        <Provider store={configureStore()}>
          <Router history={history}>
            <SplitFactory config={config.splitConfig}>
              <App>
                <AppRoutes />
                <ToastContainer />
              </App>
            </SplitFactory>
          </Router>
        </Provider>
      </QueryClientProvider>
    </OurThemeProvider>
  )
}

const app = document.getElementById('app')
const root = createRoot(app!)
root.render(<Application />)
