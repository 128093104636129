import React from 'react'

import { ExternalAnchor } from '@/components'
import Strings, { MarketingLinks } from '@/constants'

import type { Props } from './default-modal'
import DefaultModal from './default-modal'

function DowngradeSubscriptionModal({ failCallback, close }: Props): JSX.Element {
  return (
    <DefaultModal
      title={Strings.profile.downgradePlan}
      paragraphs={[Strings.profile.areYouSureDowngrade]}
      successText={Strings.profile.keepPremium}
      successCallback={close}
      failCallback={failCallback}
      failText={Strings.profile.switchToFree}
      close={close}
    >
      <ExternalAnchor
        ariaLabel={Strings.profile.learnMoreAboutSubscriptions}
        label={Strings.profile.learnMoreAboutSubscriptions}
        path={MarketingLinks.aboutSubscription}
        newWindow
      />
    </DefaultModal>
  )
}

export default DowngradeSubscriptionModal
