import { FieldOption, FieldTypes } from 'partnerslate-models'
import React from 'react'

import { CheckboxArray } from '@/components'

import type { CompanyFilter } from '.'
import styles from './styles.scss'

type Props = {
  type: FieldTypes
  options: Array<FieldOption>
  selectedFilters: Record<string, CompanyFilter>
  setSelectedFilters: (filters: Record<string, CompanyFilter>) => void
}

function FilterSelector(props: Props): JSX.Element {
  const { type, options, selectedFilters, setSelectedFilters } = props

  const getFilterValues = (): CompanyFilter => {
    const values = selectedFilters[type] ? selectedFilters[type] : []
    return values
  }

  const handleChange = (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | boolean
      | FieldOption
      | Array<FieldOption>,
  ) => {
    const values = Array.isArray(e) ? e : []
    const updatedFilter: CompanyFilter = values

    setSelectedFilters({
      ...selectedFilters,
      [type]: updatedFilter,
    })
  }

  return (
    <div className={styles.selector}>
      <CheckboxArray
        name={type}
        ariaLabel={type}
        options={options}
        onChange={handleChange}
        value={getFilterValues()}
      />
    </div>
  )
}

export default FilterSelector
