import React from 'react'

import Spinner from './spinner'
import styles from './styles.scss'

function Loading(): JSX.Element {
  return (
    <div className={styles.loadingContainer}>
      <div className={styles.loadingContent}>
        <Spinner />
      </div>
    </div>
  )
}

export default Loading
