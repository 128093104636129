import React from 'react'

import Strings from '@/constants'

import type { Props } from './default-modal'
import DefaultModal from './default-modal'

function DeleteAccountModal({ successCallback, close }: Props): JSX.Element {
  return (
    <DefaultModal
      title={Strings.profile.areYouSureYouWant}
      paragraphs={[Strings.profile.ifYouDelete]}
      successText={Strings.profile.deleteAccount}
      successCallback={successCallback}
      close={close}
    />
  )
}

export default DeleteAccountModal
