import { User } from 'partnerslate-models'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Redirect } from 'react-router-dom'

import TestIds from '@/accessibility/test-ids'
import { SupportEmailAnchor } from '@/components/anchor'
import { Routes } from '@/constants'
import { ActionCreators, StoreState } from '@/redux'
import { VerifyEmailPayload, verifyEmailPayload } from '@/redux/api-payloads'
import selectors from '@/selectors'

import styles from './styles.scss'

interface StateProps {
  user: User
}

interface DispatchProps {
  resendVerifyEmail: (data: VerifyEmailPayload) => void
}

type VerifyEmailProps = StateProps & DispatchProps & RouteComponentProps

export function VerifyEmailScreen(props: VerifyEmailProps): JSX.Element {
  const { resendVerifyEmail, user, location } = props

  if (!user.id) {
    return <Redirect to={{ pathname: Routes.Login, search: location.search }} />
  }

  const handleResendClick = () => {
    const data = { username: user.email }
    resendVerifyEmail(data)
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.formContainer}>
          <h1 className={styles.title}>Verify Email Address</h1>
          <p className={styles.explainer}>You're Almost Done!</p>
          <p className={styles.explainer}>
            You've been sent an email that contains a confirmation link. Please follow the
            instructions in the verification email to activate your account.
          </p>
          <p className={styles.explainer}>
            If you don't see the verification email in your inbox, please check spam/junk. If you
            don't receive it after 5 minutes you can{' '}
            <button
              type="button"
              onClick={handleResendClick}
              className={styles.resendLink}
              data-account-id={user.id}
              data-testid={TestIds.VerifyEmail.Submit}
            >
              request a new confirmation email
            </button>{' '}
            (note: this will invalidate your original confirmation).
          </p>
          <p className={styles.explainer}>
            If you still can't find the verification email, please{' '}
            <SupportEmailAnchor text="contact PartnerSlate support" />.
          </p>
        </div>
      </div>
      <div className={styles.rightContainer} />
    </div>
  )
}

const mapStateToProps = (state: StoreState): StateProps => ({
  user: selectors.user.getUser(state),
})

const mapDispatchToProps = {
  resendVerifyEmail: (data: VerifyEmailPayload) =>
    ActionCreators.api.makeRequest.dispatch(verifyEmailPayload(data)),
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(VerifyEmailScreen))
