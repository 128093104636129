import { Link } from '@mui/material'
import React from 'react'

type Props = {
  email: string
  color?: string
  linkText?: string
}
export default function EmailLink({ email, color = 'primary.main', linkText }: Props): JSX.Element {
  return (
    <Link href={`mailto:${email}`} color={color} underline="none">
      {linkText || email}
    </Link>
  )
}
