/* eslint-disable react/state-in-constructor */
import React from 'react'
import { connect } from 'react-redux'

import { PrimaryButtonAnchor } from '@/components/anchor'
import Strings, { Routes } from '@/constants'
import { ResetPasswordForm } from '@/forms'
import { endpoints } from '@/helpers/services'
import { StoreState } from '@/redux'
import selectors from '@/selectors'

import styles from './styles.scss'

const STEP_INITIAL = 'initial'
const STEP_SUCCESS = 'success'

type StateProps = {
  pending: boolean
  error: ErrorDetailsType | null
}

type ResetPasswordProps = StateProps

type ResetPasswordState = {
  step: string
  pending: boolean
}

export class ResetPasswordScreen extends React.Component<ResetPasswordProps, ResetPasswordState> {
  state = {
    step: STEP_INITIAL,
    pending: false,
  }

  static getDerivedStateFromProps(
    nextProps: ResetPasswordProps,
    prevState: ResetPasswordState,
  ): ResetPasswordState {
    const { pending, error } = nextProps
    if (prevState.pending && !pending && !error) {
      return { pending, step: STEP_SUCCESS }
    }
    return { ...prevState, pending }
  }

  renderStep = (step: string): React.ReactElement | null => {
    switch (step) {
      case STEP_INITIAL:
        return (
          <>
            <p className={styles.subtitle}>{Strings.auth.resetPassword.subtitle}</p>
            <ResetPasswordForm />
          </>
        )
      case STEP_SUCCESS:
        return (
          <>
            <p className={styles.subtitle}>{Strings.auth.resetPassword.success.subtitle}</p>
            <PrimaryButtonAnchor
              label={Strings.auth.resetPassword.success.nextButton}
              ariaLabel={Strings.auth.resetPassword.success.nextButton}
              path={Routes.Login}
              className={styles.homeAnchor}
            />
          </>
        )
      default:
        return null
    }
  }

  render(): React.ReactElement {
    const { step } = this.state

    return (
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.formContainer}>
            <h1 className={styles.title}>{Strings.auth.resetPassword.title}</h1>
            {this.renderStep(step)}
          </div>
        </div>
        <div className={styles.rightContainer} />
      </div>
    )
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  pending: selectors.api.getPending(state, endpoints.getResetPassword),
  error: selectors.api.getError(state, endpoints.getResetPassword),
})

export default connect(mapStateToProps)(ResetPasswordScreen)
