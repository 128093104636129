import React from 'react'

import { Images } from '@/constants'

export function EmailIcon(): JSX.Element {
  return (
    <svg width="24" height="20">
      <image xlinkHref={Images.mail} width="24" height="20" />
    </svg>
  )
}

export function PasswordIcon(): JSX.Element {
  return (
    <svg width="20" height="20">
      <image xlinkHref={Images.lock} width="20" height="20" />
    </svg>
  )
}
