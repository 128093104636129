/** @jsx jsx */
import { jsx } from '@emotion/core'
import * as React from 'react'

import InputError from './input-error'
import InputLabel from './input-label'
import jsStyles from './styles'
import styles from './styles.scss'

interface RequiredProps {
  name: string
  placeholder: string | undefined
  onChange: (value: React.ChangeEvent<HTMLInputElement>) => void
}

type DefaultProps = {
  requestClose: () => void
  ariaLabel: string
  error: string
  success: boolean
  required: boolean
  disabled: boolean
  type:
    | 'button'
    | 'checkbox'
    | 'color'
    | 'date'
    | 'datetime-local'
    | 'email'
    | 'file'
    | 'hidden'
    | 'image'
    | 'month'
    | 'number'
    | 'password'
    | 'radio'
    | 'range'
    | 'reset'
    | 'search'
    | 'submit'
    | 'tel'
    | 'text'
    | 'time'
    | 'url'
    | 'week'
    | 'name'
  visible: boolean
  className: string | null
  accessory: string | null
  dropdown: React.ReactNode | null
  onFocus: ((e: React.FocusEvent<HTMLInputElement>) => void) | undefined
  onBlur: ((e: React.FocusEvent<HTMLInputElement>) => void) | undefined
  label: string
  successImage: string | null
  errorImage: string | null
  icon: JSX.Element | string | null
  testId: string | undefined
  value: string | undefined
}

export type InputProps = RequiredProps & Partial<DefaultProps>

class Input extends React.Component<InputProps> {
  inputRef: HTMLInputElement | null | undefined

  static defaultProps: DefaultProps = {
    ariaLabel: '',
    type: 'text',
    dropdown: null,
    visible: false,
    error: '',
    success: false,
    onFocus: undefined,
    onBlur: undefined,
    className: null,
    accessory: null,
    label: '',
    requestClose: () => {},
    successImage: null,
    errorImage: null,
    icon: '',
    testId: undefined,
    value: undefined,
    required: false,
    disabled: false,
  }

  onFocus = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { onFocus } = this.props
    if (onFocus) {
      onFocus(e)
    }
  }

  onBlur = (e: React.FocusEvent<HTMLInputElement>): void => {
    const { onBlur } = this.props
    if (onBlur) {
      onBlur(e)
    }
  }

  onChange = (e: React.ChangeEvent<HTMLInputElement>): void => {
    const { onChange } = this.props
    onChange(e)
  }

  focus = (): void => {
    if (this.inputRef) {
      this.inputRef.focus()
    }
  }

  renderDropdown = (): React.ReactNode | null => {
    const { dropdown, visible } = this.props
    if (visible && dropdown) {
      return dropdown
    }
    return null
  }

  renderAccessory = (): JSX.Element | null => {
    const { accessory, success, error, successImage, errorImage } = this.props
    let imgSrc = null
    let alt = ''
    if (accessory) {
      imgSrc = accessory
      alt = 'accessory'
    } else if (success) {
      imgSrc = successImage
      alt = 'success'
    } else if (error) {
      imgSrc = errorImage
      alt = 'error'
    }
    if (imgSrc) {
      return (
        <button type="button" onClick={this.focus} className={styles.accessory}>
          <img className={styles.accessory} src={imgSrc} alt={alt} />
        </button>
      )
    }
    return null
  }

  renderError = (): string | null => {
    const { error } = this.props
    if (error) {
      return error
    }
    return null
  }

  renderIcon = (): JSX.Element | null => {
    const { icon, name } = this.props
    if (icon) {
      if (typeof icon === 'string') {
        return <img className={styles.icon} src={icon} alt={`icon for input ${name}`} />
      }
      return icon
    }
    return null
  }

  render(): JSX.Element {
    const { label, name, ariaLabel, error, icon, testId, required, ...rest } = this.props
    // Delete props that shouldn't be passed to the dom
    delete rest.visible
    delete rest.success
    delete rest.errorImage
    delete rest.successImage
    delete rest.requestClose

    return (
      <div className={styles.inputContainer}>
        <InputLabel required={required} label={label} inputName={name} />
        <div className={styles.input}>
          <input
            ref={(ref) => {
              this.inputRef = ref
            }}
            aria-label={ariaLabel}
            id={name}
            name={name}
            {...rest}
            css={jsStyles.input({ icon, error: !!error })}
            className={styles.baseInput}
            onFocus={this.onFocus}
            onBlur={this.onBlur}
            onChange={this.onChange}
            data-testid={testId}
          />
          {this.renderIcon()}
          {this.renderAccessory()}
          {this.renderDropdown()}
        </div>
        <InputError error={this.renderError()} />
      </div>
    )
  }
}

export default Input
