import React from 'react'

import Strings from '@/constants'

import type { Props } from './default-modal'
import DefaultModal from './default-modal'

function SubscriptionSuccessModal({ successCallback, close }: Props): JSX.Element {
  return (
    <DefaultModal
      title={Strings.profile.youAreNowPremium}
      paragraphs={[Strings.profile.allSetWithPremium]}
      successText={Strings.general.ok}
      successCallback={successCallback}
      close={close}
    />
  )
}

export default SubscriptionSuccessModal
