/** @jsx jsx */
import { generateUUID } from '@clearsummit/carabiners'
import { jsx } from '@emotion/core'
import * as React from 'react'

import jsStyles from './styles'
import styles from './styles.scss'
import CheckboxProps, { DefaultProps } from './types'

class Checkbox extends React.Component<CheckboxProps> {
  id = generateUUID()

  static defaultProps: DefaultProps = {
    label: '',
    error: false,
    disabled: false,
    onBlur: undefined,
    labelClassName: undefined,
    testId: undefined,
  }

  render(): JSX.Element {
    const { ariaLabel, name, label, disabled, value, onBlur, onChange, labelClassName, testId } =
      this.props
    const { id } = this
    return (
      <label className={styles.checkbox} htmlFor={id}>
        {typeof label === 'string' ? <span className={labelClassName}>{label}</span> : label}
        <input
          type="checkbox"
          name={name}
          aria-label={ariaLabel}
          id={id}
          disabled={disabled}
          checked={value}
          onBlur={onBlur}
          onChange={() => onChange(!value)}
          data-testid={testId}
        />
        <span css={jsStyles.span(this.props)} className={styles.checkmark} />
      </label>
    )
  }
}

export default Checkbox
