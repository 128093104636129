import React from 'react'

import Strings, { Images } from '@/constants'

import styles from './styles.scss'

function Spinner(): JSX.Element {
  return (
    <div className={styles.spinner}>
      <img className={styles.circular} src={Images.spinner} alt={Strings.loading} />
    </div>
  )
}

export default Spinner
