import { FieldOption } from 'partnerslate-models'
import * as React from 'react'

import CustomError from '../custom-error'
import CustomLabel from '../custom-label'
import StyleSelect from '../styled-select'
import dropdownStyles, { customComponents, multiComponents } from './dropdown-styles'
import styles from './styles.scss'

interface RequiredProps {
  onChange: (value: string | Array<string>) => void
  options: Array<FieldOption>
  ariaLabel: string
  name: string
}

interface DefaultProps {
  label: string
  error: string | null | undefined
  disabled: boolean
  isMulti: boolean | null
  hideSelectedOptions?: boolean | null
  closeMenuOnSelect: boolean | null
  value?: string | Array<string>
  placeholder?: string
  blurInputOnSelect?: boolean | null
  controlShouldRenderValue?: boolean | null
  menuIsOpen?: boolean | null
  onBlur: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined
  onFocus: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined
  testId: string | undefined
  components: typeof customComponents
  required: boolean
}

type DropdownProps = Partial<DefaultProps> & RequiredProps

export class BaseDropdown extends React.Component<DropdownProps> {
  onChange = (option: FieldOption | Array<FieldOption>): void => {
    const { onChange, isMulti } = this.props

    if (isMulti && Array.isArray(option)) {
      onChange(option.map((opt) => opt.value))
    } else if (option && !Array.isArray(option)) {
      onChange(option.value)
    } else {
      const val = isMulti ? [] : ''
      onChange(val)
    }
  }

  getSelectedValues = (
    formikValue: string | Array<string>,
    options: Array<FieldOption>,
  ): FieldOption | Array<FieldOption> => {
    if (Array.isArray(formikValue)) {
      return options.filter((opt) => formikValue.includes(opt.value))
    }
    return options.filter((opt) => opt.value === formikValue)
  }

  render(): React.ReactElement {
    const {
      ariaLabel,
      name,
      label,
      placeholder,
      options,
      error,
      disabled,
      onBlur,
      testId,
      components,
      isMulti,
      closeMenuOnSelect,
      value,
      required,
      onFocus,
      ...rest
    } = this.props

    const formikValue = value || []

    return (
      <div className={styles.flexColumn}>
        <CustomLabel label={label} name={name} required={required || false} />
        <StyleSelect
          // @ts-ignore
          id={name}
          className={styles.dropdown}
          styles={dropdownStyles}
          aria-label={ariaLabel}
          name={name}
          placeholder={placeholder}
          options={options}
          components={components}
          error={!!error}
          isDisabled={disabled}
          onBlur={onBlur}
          onFocus={onFocus}
          inputId={testId}
          closeMenuOnSelect={closeMenuOnSelect}
          {...rest}
          value={this.getSelectedValues(formikValue, options)}
          isMulti={isMulti}
          onChange={this.onChange}
        />
        <CustomError error={error} />
      </div>
    )
  }
}

export function MultiDropdown(props: DropdownProps): React.ReactElement<DropdownProps> {
  return <BaseDropdown isMulti components={multiComponents} closeMenuOnSelect={false} {...props} />
}

function Dropdown(props: DropdownProps): React.ReactElement<DropdownProps> {
  return <BaseDropdown {...props} />
}

export default Dropdown
