import {
  FormControl,
  LinearProgress,
  ListSubheader,
  MenuItem,
  Select,
  SelectChangeEvent,
  Typography,
} from '@mui/material'
import React, { Key } from 'react'

import {
  BrandEditableEngagementStages,
  BrandInactiveEngagementStages,
  ENGAGEMENT_STATUSES_BY_BRAND,
  StatusDisplay,
} from '@/domain/engagements'

type EngagementStatusSelectProps = {
  currentStatus: BrandEditableEngagementStages
  onStatusChange: (newStatus: BrandEditableEngagementStages) => void
  isStatusUpdating: boolean
  showOnlyInactiveStates: boolean
}

const getMenuOptions = (showOnlyInactiveStates: boolean) => {
  return Object.keys(ENGAGEMENT_STATUSES_BY_BRAND).reduce((acc: JSX.Element[], k) => {
    if (k === 'nda' && !showOnlyInactiveStates) return acc

    const s: StatusDisplay = ENGAGEMENT_STATUSES_BY_BRAND[k as BrandEditableEngagementStages]
    const label = s.name
    const renderItem = (
      <MenuItem key={k} value={k}>
        <Typography color={s.textColor}>{label}</Typography>
      </MenuItem>
    )
    if (
      !acc.some(
        ({ key }: { key: Key | null }) => typeof key === 'string' && key.startsWith('inactive'),
      ) &&
      k.startsWith('inactive')
    ) {
      acc.push(
        <ListSubheader key="inactive" sx={{ fontSize: '14px' }}>
          INACTIVE
        </ListSubheader>,
      )
    }
    if (
      !showOnlyInactiveStates ||
      (showOnlyInactiveStates &&
        (k === 'nda' || (BrandInactiveEngagementStages as ReadonlyArray<string>).includes(k)))
    ) {
      acc.push(renderItem)
    }
    return acc
  }, [])
}

export function BrandStatusSelect({
  currentStatus,
  onStatusChange,
  isStatusUpdating,
  showOnlyInactiveStates,
}: EngagementStatusSelectProps): JSX.Element {
  const handleStatusChange = (event: SelectChangeEvent) => {
    const newStatus = event.target.value as BrandEditableEngagementStages
    onStatusChange(newStatus)
  }

  return (
    <FormControl fullWidth size="small">
      <Select
        value={currentStatus}
        onChange={handleStatusChange}
        disabled={isStatusUpdating}
        sx={{
          '.MuiTypography-root, .MuiTypography-body1': {
            textOverflow: 'ellipsis',
            overflowX: 'hidden',
          },
        }}
      >
        {getMenuOptions(showOnlyInactiveStates)}
      </Select>
      {isStatusUpdating ? <LinearProgress /> : null}
    </FormControl>
  )
}
