import * as yup from 'yup'

import Strings, { Regex } from '@/constants'

export enum FieldNames {
  nameOnCard = 'nameOnCard',
  cardNumber = 'cardNumber',
  cardExpiration = 'cardExpiration',
  cvc = 'cvc',
  companyName = 'companyName',
  companyPhone = 'companyPhone',
  slug = 'slug',
}

const phoneRegExp = /^[+]?[3]?[0-9]{3}[0-9]{3}[0-9]{4,6}$/

export const defaultEmailSchema = yup.string().email().required().label('Email')

export const defaultTextRequiredSchema = yup.string().required()

export const defaultPasswordPatternSchema = yup
  .string()
  .min(8, Strings.auth.passwordValidErr.min)
  .max(50, Strings.auth.passwordValidErr.max)
  .matches(Regex.oneNumber, Strings.auth.passwordValidErr.oneNumber)
  .matches(Regex.oneSpecialChar, Strings.auth.passwordValidErr.oneSpecialChar)
  .required(Strings.auth.signup.passwordSubtext)

export const defaultConfirmPassword = yup
  .string()
  .oneOf([yup.ref('password')], Strings.auth.signup.passwordMatchError)
  .required(Strings.auth.signup.confirmRequired)

export const defaultCurrentPassword = yup.string().required(Strings.auth.signup.confirmRequired)

export const defaultPasswordSchema = yup.string().required(Strings.auth.passwordValidErr.required)

export const defaultCheckboxSchema = yup.boolean().oneOf([true]).required()

const AddressSchema = yup
  .object()
  .required()
  .shape({
    address1: yup.string().ensure().required('Please provide street address'),
    address2: yup.string().ensure().notRequired(),
    city: yup.string().ensure().required('Please provide a city'),
    postalCode: yup.string().ensure().required('Please provide a ZIP or postal code'),
    state: yup
      .string()
      .ensure()
      .when('countryCode', {
        is: (countryCode: string) => ['US', 'CA'].includes(countryCode),
        then: (s: yup.StringSchema) => s.required('Please provide a state or province'),
      }),
    countryCode: yup.string().ensure().required('Please provide a country'),
  })

export const LoginSchema = yup.object().shape({
  username: defaultEmailSchema,
  password: defaultPasswordSchema,
})

export const ResetPasswordSchema = yup.object().shape({
  username: defaultEmailSchema,
})

export const ResetPasswordChangeSchema = yup.object().shape({
  password: defaultPasswordPatternSchema,
  confirmPassword: defaultConfirmPassword,
})

export const LeadJoinSchema = yup.object().shape({
  password: defaultPasswordPatternSchema,
  confirmPassword: defaultConfirmPassword,
  tos: yup.boolean().required(Strings.inputs.tos.error).oneOf([true], Strings.inputs.tos.error),
})

export const SignUpPersonalInformationSchema = yup.object().shape({
  email: defaultEmailSchema,
  firstName: yup.string().required(Strings.inputs.firstName.error),
  lastName: yup.string().required(Strings.inputs.lastName.error),
  password: defaultPasswordPatternSchema,
  confirmPassword: defaultConfirmPassword,
})

export const UpdateCompanyProfileSchema = yup.object().shape({
  website: yup
    .string()
    .matches(
      /((https?):\/\/)?(www.)?[a-z0-9]+(\.[a-z]{2,}){1,3}(#?\/?[a-zA-Z0-9#]+)*\/?(\?[a-zA-Z0-9-_]+=[a-zA-Z0-9-%]+&?)?$/,
      Strings.inputs.website.error,
    ),
})

export const UpdateCompanySchema = yup.object().shape({
  [FieldNames.companyName]: yup
    .string()
    .required(Strings.inputs.companyName.error)
    .max(32, Strings.inputs.validation.maxChar(32)),
  [FieldNames.companyPhone]: yup
    .string()
    .required(Strings.inputs.phone.error)
    .matches(phoneRegExp, Strings.inputs.phone.error),
  address: AddressSchema,
})

export const UpdateCompanyFieldsSchema = yup.object().shape({})

export const UpdateUserSchema = yup.object().shape({
  email: defaultEmailSchema,
  firstName: yup.string().required(Strings.inputs.firstName.error),
  lastName: yup.string().required(Strings.inputs.lastName.error),
})

export const ChangePasswordSchema = yup.object().shape({
  oldPassword: defaultCurrentPassword,
  password: defaultPasswordPatternSchema,
  confirmPassword: defaultConfirmPassword,
})

export const UpdateCardSchema = yup.object().shape({
  [FieldNames.nameOnCard]: yup.string().required(Strings.profile.nameOnCardError),
  [FieldNames.cardNumber]: yup.boolean().oneOf([true]).required(),
  [FieldNames.cardExpiration]: yup.boolean().oneOf([true]).required(),
  [FieldNames.cvc]: yup.boolean().oneOf([true]).required(),
})

export default FieldNames
