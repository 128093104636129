import { ApiPayload, reduxSet as apiAC } from '@partnerslate/radio-dispatch'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { useHistory, useParams } from 'react-router-dom'
import { Dispatch } from 'redux'

import { Loading } from '@/components'
import { Routes } from '@/constants'
import { InviteInformation } from '@/domain/team'
import { SignUpTeamInviteForm } from '@/forms'
import { SignUpFormValues } from '@/forms/signup-team-invite'
import { instrumentation } from '@/helpers'
import { setAuth } from '@/helpers/auth'
import Services from '@/helpers/services'
import { BackendError } from '@/helpers/services/backendError'
import { sendSignUpInviteAccept } from '@/helpers/services/backendGateway'
import { useInviteInformation } from '@/helpers/services/queries'
import { loginPayload, UserAndCompany } from '@/redux/api-payloads'
import { reduxSet as userAC } from '@/redux/user'

import styles from './styles.scss'

type DispatchProps = {
  storeUser: (data: UserAndCompany) => any
  makeRequest: (payload: ApiPayload<unknown>) => void
}

export function TeamInviteAcceptScreen({ storeUser, makeRequest }: DispatchProps): JSX.Element {
  const { token } = useParams<{ token: string }>()
  const { data: inviteInformation, error } = useInviteInformation(token)

  if (error) {
    return <Error />
  }

  if (!inviteInformation) {
    return <Loading />
  }

  return (
    <TeamInviteAccept
      makeRequest={makeRequest}
      storeUser={storeUser}
      token={token}
      inviteInformation={inviteInformation}
    />
  )
}

type TeamInviteAcceptScreenProps = {
  token: string
  inviteInformation: InviteInformation
} & DispatchProps

export function TeamInviteAccept({
  makeRequest,
  storeUser,
  token,
  inviteInformation,
}: TeamInviteAcceptScreenProps): JSX.Element {
  const history = useHistory()
  const [error, setError] = useState<string | null>(null)

  useEffect(() => {
    instrumentation.acceptingTeamInvite(inviteInformation.companyId)
  }, [inviteInformation])

  const handleSubmit = async (values: SignUpFormValues) => {
    try {
      const response = await sendSignUpInviteAccept(values, token)

      // login via redux so the store has the user for when we redirect
      const payload = loginPayload({
        username: values.email,
        password: values.password,
      }) as ApiPayload<any, any, any>
      await makeRequest(payload)

      // login again manually also so we can get the auth tokens
      const [loggedInUser] = await Services.login({
        username: values.email,
        password: values.password,
      })

      // @ts-ignore
      const tokens = loggedInUser.data.token
      // set token manually
      await setAuth(tokens)

      instrumentation.acceptedTeamInvite(inviteInformation.companyId)
      // this might do nothing not sure, it was here
      await storeUser(response)
      setError(null)

      history.push(Routes.Login)
    } catch (e) {
      if (e instanceof BackendError) {
        const backendError = e as BackendError
        setError(backendError.humanReadableError())
      } else {
        setError('An error occured')
      }
    }
  }

  const initialFormValues = {
    email: inviteInformation.email,
    password: '',
    confirmPassword: '',
    firstName: '',
    lastName: '',
    jobTitle: '',
  }

  return (
    <div className={styles.container}>
      <div className={styles.leftContainer} />
      <div className={styles.rightContainer}>
        <div className={styles.formContainer}>
          <h1>Join {inviteInformation.companyName}</h1>
          <p className={styles.subtitle}>
            You've been invited to join this team. We'll just need a few details first.
          </p>
          <div className={styles.typesSelectorContainer}>
            <SignUpTeamInviteForm
              onSubmit={handleSubmit}
              initialValues={initialFormValues}
              error={error}
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function Error() {
  // This error message may not, in fact, be 100% truthful.
  //
  // We currently show this error message if we encounter
  //  *any* error in retrieving information about the invite. We would get a 410 error is the invite was indeed no longer
  //  active, but if we got a 500, or a timeout, or whatever then we'd still end up here, even if the invite is actually
  //  still active, but our servers are having a bad day.
  return (
    <div className={styles.errorContainer}>
      <p>Sorry, your invitation is no longer active.</p>
      <p>If you need a new invite, please contact your primary PartnerSlate account holder.</p>
    </div>
  )
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => {
  return {
    storeUser: (userAndCompany: UserAndCompany) => {
      dispatch(userAC.storeUserFromInvite.dispatch(userAndCompany))
    },

    makeRequest: (payload: ApiPayload<unknown>) => dispatch(apiAC.makeRequest.dispatch(payload)),
  }
}

export default connect(undefined, mapDispatchToProps)(TeamInviteAcceptScreen)
