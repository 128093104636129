/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import React from 'react' // eslint-disable-line @typescript-eslint/no-unused-vars

import { ActionButton, ProfileImage } from '@/components'
import Strings, { Images } from '@/constants'

import styles from './styles.scss'

interface Props {
  image: string
  onClick?: () => void
}

export function EditProfileImage({ image, onClick }: Props): JSX.Element {
  return (
    <>
      <ProfileImage image={image} />
      <ActionButton
        className={styles.uploadPhoto}
        icon={Images.camera}
        onClick={onClick}
        ariaLabel={Strings.general.camera}
        iconAlt={Strings.general.camera}
      />
    </>
  )
}

EditProfileImage.defaultProps = {
  onClick: () => {},
}

export default EditProfileImage
