// eslint-disable-next-line import/no-extraneous-dependencies
import * as Sentry from '@sentry/browser' // we need to import `@sentry/browser` here, not `@sentry/react`, because Sentry.Replay is only available there

const MIXPANEL_TOKEN_NON_PROD = 'c671de97a0425dea4b7872bf8b22feea'
const MIXPANEL_TOKEN_PROD = 'e211a2deaa8f603456a93ca2cedab90a'

const HELLOSIGN_CLIENT_ID_NON_PROD = 'df9b5d27a19a953870842bc07edc5fb3'
const HELLOSIGN_CLIENT_ID_PROD = '9520fdf2e6532039743a52b89285f7cb'

const SPLIT_CONFIG_NON_PROD: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: 'ik6tug5uuqj5jig7btga8j6m6i65sfod0ofq',
    key: 'anon', // punt on uniquely identifying users for now
  },
}

const SPLIT_CONFIG_PROD: SplitIO.IBrowserSettings = {
  core: {
    authorizationKey: 'qrdqo3bcvhdnu6qi0mdrcafrnnecvj30uud6',
    key: 'anon', // punt on uniquely identifying users for now
  },
}

const SENTRY_DSN =
  'https://d6c097ec03524c7fa62fcdc4bef8dbeb@o91293.ingest.sentry.io/4505330832703488'
const NULL_SENTRY_OPTIONS = {}

// Sentry SDK requires this to be a globalton, so we can't create
// a new one inside `sentryOptionsForEnv(...)`
const sentryReplayGlobalton = new Sentry.Replay({
  maskAllText: false,
})

const sentryOptionsForEnv = (env: string, buildstamp: string): Sentry.BrowserOptions => ({
  dsn: SENTRY_DSN,
  environment: `PS-clientside-${env}`,
  release: buildstamp,
  replaysSessionSampleRate: 1.0,
  replaysOnErrorSampleRate: 1.0,
  integrations: [sentryReplayGlobalton],
  ignoreErrors: [
    'pagead/viewthroughconversion', // errors caused by ad blocker blackholing Google Ads reporting (we think): https://partnerslate.sentry.io/issues/4261206639/events/b73cf8ce30e54c19b0cb6fb32624e2d0/?project=4505330832703488
    /Object Not Found Matching Id:\d+, MethodName:\w+, ParamCount:\d+/,
    "Cannot read properties of undefined (reading 'domInteractive')",
  ], // c.f. https://github.com/getsentry/sentry-javascript/issues/3440 https://forum.sentry.io/t/unhandledrejection-non-error-promise-rejection-captured-with-value/14062 https://github.com/cefsharp/CefSharp/blob/ed1763ee9510dca9e54ab766ea7cf305f71d2aa2/CefSharp/Internals/JavascriptObjectRepository.cs#L298
})

interface ConfigType {
  isProduction: boolean
  buildstamp: string
  staticPath: string
  API_URL: string
  showsAPIBodies: boolean
  stripeApiKey: string
  mixpanel: null | {
    token: string
  }
  splitConfig: SplitIO.IBrowserSettings
  sentryOptions: Sentry.BrowserOptions
  hellosignClientId: string
}

const { buildstamp, static_path: staticPath, app_env: appEnv } = (window as any).partnerSlate || {}

const test: ConfigType = {
  isProduction: false,
  buildstamp: 'automated-tests',
  sentryOptions: NULL_SENTRY_OPTIONS,
  staticPath: '/static',
  API_URL: '/api/v1/',
  showsAPIBodies: false,
  stripeApiKey: 'DUMMY',
  mixpanel: null,
  splitConfig: SPLIT_CONFIG_NON_PROD,
  hellosignClientId: 'DUMMY',
}

const localdev: ConfigType = {
  isProduction: false,
  buildstamp,
  sentryOptions: sentryOptionsForEnv('localdev', buildstamp),
  staticPath: '/static',
  API_URL: '/api/v1/',
  showsAPIBodies: process.env.NODE_ENV !== 'test',
  stripeApiKey: 'pk_test_FIBXsDAwT3dWaMoPgWFGjaiz',
  mixpanel: {
    token: MIXPANEL_TOKEN_NON_PROD,
  },
  splitConfig: SPLIT_CONFIG_NON_PROD,
  hellosignClientId: HELLOSIGN_CLIENT_ID_NON_PROD,
}

const dev: ConfigType = {
  isProduction: false,
  buildstamp,
  sentryOptions: sentryOptionsForEnv('dev', buildstamp),
  staticPath: staticPath ? staticPath.slice(0, -1) : '',
  API_URL: 'https://partnerslate-dev.herokuapp.com/api/v1/',
  showsAPIBodies: false,
  stripeApiKey: 'pk_test_FIBXsDAwT3dWaMoPgWFGjaiz',
  mixpanel: {
    token: MIXPANEL_TOKEN_NON_PROD,
  },
  splitConfig: SPLIT_CONFIG_NON_PROD,
  hellosignClientId: HELLOSIGN_CLIENT_ID_NON_PROD,
}

const stg: ConfigType = {
  isProduction: false,
  buildstamp,
  sentryOptions: sentryOptionsForEnv('stg', buildstamp),
  staticPath: staticPath ? staticPath.slice(0, -1) : '',
  API_URL: 'https://partnerslate-stg.herokuapp.com/api/v1/',
  showsAPIBodies: false,
  stripeApiKey: 'pk_test_FIBXsDAwT3dWaMoPgWFGjaiz',
  mixpanel: {
    token: MIXPANEL_TOKEN_NON_PROD,
  },
  splitConfig: SPLIT_CONFIG_NON_PROD,
  hellosignClientId: HELLOSIGN_CLIENT_ID_NON_PROD,
}

const prd: ConfigType = {
  isProduction: true,
  buildstamp,
  sentryOptions: sentryOptionsForEnv('prd', buildstamp),
  staticPath: staticPath ? staticPath.slice(0, -1) : '',
  API_URL: 'https://partnerslate-prd.herokuapp.com/api/v1/',
  showsAPIBodies: false,
  stripeApiKey: 'pk_live_1dU5Hj7rzjIzXMBVrJGrnXNw',
  mixpanel: {
    token: MIXPANEL_TOKEN_PROD,
  },
  splitConfig: SPLIT_CONFIG_PROD,
  hellosignClientId: HELLOSIGN_CLIENT_ID_PROD,
}

const configs = {
  test,
  localdev,
  dev,
  stg,
  prd,
}
type Environments = keyof typeof configs

const environment: Environments = appEnv || 'localdev'

const config: ConfigType = configs[environment]

export default config
