import S3Service from '../S3Service'

const s3Adaptor = async (
  fn: (args: {
    presignedUrl: string
    file: File
  }) => Promise<{ status: number; data: Record<string, unknown> }>,
  args: { presignedUrl: string; file: File },
) => {
  let resp
  let err
  try {
    const response = await fn(args)
    resp = { statusCode: response.status, data: response.data }
  } catch (e) {
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    err = { statusCode: e.response.status, details: e.response.data.error }
  }
  return [resp, err]
}

const S3Services = () => {
  const uploadFile = ({ url, file }: { url: string; file: File }) =>
    s3Adaptor(S3Service.uploadFile, { presignedUrl: url, file })

  return {
    uploadFile,
  }
}

const services = S3Services()

export default services
