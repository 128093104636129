import 'rc-slider/assets/index.css'

import RCSlider from 'rc-slider'
import React from 'react'

import styles from './styles'

interface Props {
  onChange: (zoom: number) => void
  min: number
  max: number
  value?: number
}

function Slider({ onChange, min, max, value }: Props): JSX.Element {
  return (
    <RCSlider
      onChange={onChange}
      handleStyle={styles.handleStyle}
      trackStyle={styles.trackStyle}
      railStyle={styles.railsStyle}
      min={min}
      max={max}
      value={value}
      step={0.1}
      reverse
    />
  )
}

Slider.defaultProps = {
  value: undefined,
}

export default Slider
