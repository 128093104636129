import { Box, styled, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import { Images, Routes } from '@/constants'
import { Engagement } from '@/domain/engagements'

type Props = {
  engagement: Engagement
}
export default function BackLink({ engagement }: Props): JSX.Element {
  return (
    <Box p={2}>
      <Link aria-label="Engagement" to={Routes.EngagementDetailSlug(engagement.id)}>
        <Typography variant="h4" color="primary.main" display="flex" pb={5.5}>
          <Box>
            <LeftArrow src={Images.leftArrow} alt="" />
          </Box>
          Back to {engagement.project.name} Engagement
        </Typography>
      </Link>
    </Box>
  )
}

const LeftArrow = styled('img')(({ theme }) => ({
  width: '13px',
  marginRight: theme.spacing(1),
}))
