/** @jsx jsx */
import { joinStr } from '@clearsummit/carabiners'
import { jsx } from '@emotion/core'
import React from 'react'

import ButtonProps, { DefaultProps, Size } from '@/components/button/types'

import jsStyles from './styles'
import styles from './styles.scss'

class Button extends React.Component<ButtonProps> {
  static defaultProps: DefaultProps = {
    size: Size.regular,
    variant: 'primary',
    title: '',
    className: '',
    onClick: () => {},
    type: 'button',
    disabled: false,
    children: null,
    testId: undefined,
    icon: undefined,
  }

  renderIcon = (iconAlt: string | undefined | null): JSX.Element | null => {
    const { icon } = this.props
    return icon ? (
      <img className={styles.icon} src={icon} alt={iconAlt || 'icon image for input'} />
    ) : null
  }

  render(): JSX.Element {
    const {
      ariaLabel,
      children,
      onClick,
      title,
      type,
      size,
      variant,
      className,
      testId,
      iconAlt,
      ...rest
    }: ButtonProps = this.props
    return (
      <button
        type={type} // eslint-disable-line react/button-has-type
        onClick={onClick}
        aria-label={ariaLabel}
        className={joinStr(styles.button, className)}
        css={jsStyles.button(size, variant)}
        data-testid={testId}
        {...rest}
      >
        {this.renderIcon(iconAlt)}
        {children}
        {title ? <span>{title}</span> : null}
      </button>
    )
  }
}

export function SecondaryButton(props: ButtonProps): React.ReactElement {
  return <Button variant="secondary" {...props} />
}

export function TransparentButton({ className, ...rest }: ButtonProps): React.ReactElement {
  return <Button variant="secondary" className={joinStr(styles.transparent, className)} {...rest} />
}

export function ActionButton({ className, ...rest }: ButtonProps): React.ReactElement {
  return <Button variant="action" className={joinStr(styles.action, className)} {...rest} />
}

export function LinkButton({ className, ...rest }: ButtonProps): React.ReactElement {
  return <Button variant="link" className={joinStr(styles.link, className)} {...rest} />
}

interface StateFulButtonProps extends ButtonProps {
  selected: boolean
}

export function TransparentStatefulButton({
  className,
  icon,
  selected,
  ...rest
}: StateFulButtonProps): React.ReactElement {
  return (
    <Button
      variant="secondary"
      className={joinStr(styles.transparent, styles.stateful, className)}
      css={jsStyles.statefulButton(selected)}
      {...rest}
    >
      <svg width="24" height="24">
        <image xlinkHref={icon} width="24" height="24" />
      </svg>
    </Button>
  )
}

export default Button
