import {
  CircularProgress,
  Grid,
  Step,
  StepButton,
  StepLabel,
  Stepper,
  useMediaQuery,
  useTheme,
} from '@mui/material'
import React, { useState } from 'react'

import {
  EditableEngagementStages,
  Engagement,
  EngagementStatusDisplay,
  milestonesForEngagement,
  MilestoneStatus,
} from '@/domain/engagements'
import * as instrumentation from '@/helpers/instrumentation'
import { useUpdateEngagementStatus } from '@/helpers/services/queries'

type Props = {
  engagement: Engagement
  setEngagementToMoveToProduction: (engagement: Engagement | null) => void
}

export default function Milestones(props: Props): JSX.Element | null {
  const { engagement, setEngagementToMoveToProduction } = props
  const [changingMilestoneIdx, setChangingMilestoneIdx] = useState<number | null>(null)
  const milestones = milestonesForEngagement(engagement)
  const changeEngagementStatus = useUpdateEngagementStatus()
  const theme = useTheme()
  const isMobile = useMediaQuery(theme.breakpoints.down('md'))

  const handleStatusChange = (newStatus: EngagementStatusDisplay) => {
    if (newStatus === 'nda') return
    if (newStatus === 'production') {
      setEngagementToMoveToProduction(engagement)
      return
    }

    changeEngagementStatus.mutate(
      { engagementId: engagement.id, status: newStatus as EditableEngagementStages },
      {
        onSettled(e) {
          if (e) {
            instrumentation.changedEngagementStatus({
              engagement: e,
              oldStatus: engagement.stage,
              newStatus,
              inactiveState: 'NOT_INACTIVE',
            })
          }
          setChangingMilestoneIdx(null)
        },
      },
    )
  }

  const handleStepperChange = (statusIdx: number) => () => {
    const newMilestone = milestones[statusIdx]
    const newStatus = newMilestone.stage
    setChangingMilestoneIdx(statusIdx)
    handleStatusChange(newStatus)
  }

  if (isMobile) {
    return null
  }

  return (
    <Grid item md={12} mb={4} display={{ xs: 'none', md: 'block' }}>
      <Stepper alternativeLabel nonLinear>
        {milestones.map((m, idx) => {
          const isActive = m.status === MilestoneStatus.InProgress
          const isCompleted = m.status === MilestoneStatus.Completed
          const isUpdating = idx === changingMilestoneIdx && changeEngagementStatus.isLoading

          return (
            <Step key={m.title} active={isActive} completed={isCompleted}>
              <StepButton
                onClick={handleStepperChange(idx)}
                disabled={
                  m.stage === 'nda' ||
                  engagement.stage === 'nda' ||
                  engagement.stage === m.stage ||
                  engagement.perspective === 'brand'
                }
              >
                <StepLabel StepIconComponent={isUpdating ? LoadingStepIcon : undefined}>
                  {m.title}
                </StepLabel>
              </StepButton>
            </Step>
          )
        })}
      </Stepper>
    </Grid>
  )
}

function LoadingStepIcon() {
  return <CircularProgress size={24} />
}
