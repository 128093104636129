import { ApiPayload } from '@partnerslate/radio-dispatch'
import React from 'react'

import { PasswordIcon } from '@/components'
import BaseForm from '@/components/base-form'
import { SubmitButton } from '@/components/base-form/buttons'
import { PasswordField } from '@/components/base-form/fields'
import MuiFormError from '@/components/base-form/mui-form-error'
import { Size } from '@/components/button/types'
import Strings from '@/constants'
import services, { endpoints } from '@/helpers/services'
import type { ResetPasswordChangePayload } from '@/redux/api-payloads'

import { ResetPasswordChangeSchema } from '../schemas'
import styles from './styles.scss'

enum FieldNames {
  password = 'password',
  confirmPassword = 'confirmPassword',
}

const initialValues = {
  [FieldNames.password]: '',
  [FieldNames.confirmPassword]: '',
  token: '',
}

type ResetPasswordChangeFormProps = {
  onSubmit: (
    values: ResetPasswordChangePayload,
  ) => ApiPayload<typeof services, ResetPasswordChangePayload>
}

const ResetPasswordChangeForm: React.FC<ResetPasswordChangeFormProps> = ({
  onSubmit,
}: ResetPasswordChangeFormProps) => (
  <BaseForm
    initialValues={initialValues}
    validationSchema={ResetPasswordChangeSchema}
    onSubmitPayload={onSubmit}
    formKey={endpoints.postResetPassword}
  >
    <PasswordField
      name={FieldNames.password}
      label={Strings.auth.resetPasswordChange.enterNewPassword}
      placeholder={Strings.inputs.enterHere}
      icon={<PasswordIcon />}
    />
    <PasswordField
      name={FieldNames.confirmPassword}
      label={Strings.auth.resetPasswordChange.confirmNewPassword}
      placeholder={Strings.inputs.enterHere}
      icon={<PasswordIcon />}
    />
    <MuiFormError formKey={endpoints.postResetPassword} isSupportLinkVisible />
    <div className={styles.buttons}>
      <SubmitButton
        size={Size.large}
        title={Strings.auth.resetPasswordChange.nextButton}
        ariaLabel={Strings.auth.resetPasswordChange.nextButton}
        className={styles.submitButton}
      />
    </div>
  </BaseForm>
)

export default ResetPasswordChangeForm
