import { Box } from '@mui/material'
import React from 'react'

import { ComanStatusSelect } from '@/components/engagements/ComanStatusSelect'
import {
  ComanEditableEngagementStages,
  Engagement,
  isInactiveOrInNdaStage,
} from '@/domain/engagements'
import * as instrumentation from '@/helpers/instrumentation'
import { useUpdateEngagementStatus } from '@/helpers/services/queries'

type Props = {
  engagement: Engagement
  setEngagementToMoveToProduction: (engagement: Engagement | null) => void
}

export function StatusCell({ engagement, setEngagementToMoveToProduction }: Props): JSX.Element {
  const { isLoading, mutate } = useUpdateEngagementStatus()

  const handleStatusChange = (newStatus: ComanEditableEngagementStages) => {
    if (newStatus === 'production') {
      setEngagementToMoveToProduction(engagement)
      return
    }
    mutate(
      { engagementId: engagement.id, status: newStatus },
      {
        onSuccess: () => {
          instrumentation.updatedEngagementStatus({
            engagement,
            oldStatus: engagement.stage,
            newStatus,
          })
          setEngagementToMoveToProduction(null)
        },
      },
    )
  }

  return (
    <Box width="80%">
      <ComanStatusSelect
        currentStatus={engagement.stage as ComanEditableEngagementStages}
        onStatusChange={handleStatusChange}
        isStatusUpdating={isLoading}
        showOnlyInactiveStates={isInactiveOrInNdaStage(engagement)}
      />
    </Box>
  )
}
