import React, { useState } from 'react'
import Cropper from 'react-easy-crop'

export interface CropArea {
  x: number // x/y are the coordinates of the top/left corner of the cropped area
  y: number
  width: number // width of the cropped area
  height: number // height of the cropped area
}

interface Props {
  image: string
  onCrop: (crop: { croppedArea: CropArea; croppedAreaPixels: CropArea }) => void
  aspect: number
  zoom: number
  onZoomChange: (zoom: number) => void
}

function ImageCropper({ image, onCrop, aspect, zoom, onZoomChange }: Props): JSX.Element {
  const [crop, setCrop] = useState({ x: 0, y: 0 })
  return (
    <Cropper
      image={image}
      crop={crop}
      zoom={zoom}
      aspect={aspect}
      onCropChange={setCrop}
      onCropComplete={(croppedArea: CropArea, croppedAreaPixels: CropArea) =>
        onCrop({ croppedArea, croppedAreaPixels })
      }
      onZoomChange={onZoomChange}
      showGrid={false}
    />
  )
}

export default ImageCropper
