import React from 'react'

import { Input } from '@/components'

import { InputProps } from '.'

function EmailField({ name, placeholder, onChange, ...rest }: InputProps): JSX.Element {
  return <Input type="email" {...rest} placeholder={placeholder} name={name} onChange={onChange} />
}

EmailField.defaultProps = Input.defaultProps

export default EmailField
