import { createSearchObject } from '@clearsummit/carabiners'
import { Box, Card, Grid, ScopedCssBaseline, Typography } from '@mui/material'
import { ApiPayload } from '@partnerslate/radio-dispatch'
import React from 'react'
import { RouteComponentProps } from 'react-router-dom'

import { Images } from '@/constants'
import { FutureThemeProvider } from '@/helpers/mui'
import services from '@/helpers/services'
import { ResetPasswordChangePayload, resetPasswordChangePayload } from '@/redux/api-payloads'

import LeadJoinForm from '../../forms/lead-join'

function LeadJoinScreen(props: RouteComponentProps): JSX.Element {
  const resetPassword = (
    data: ResetPasswordChangePayload,
  ): ApiPayload<typeof services, ResetPasswordChangePayload> => {
    const { location } = props
    const { token } = createSearchObject(location.search)
    const requestData = { ...data, token }

    return resetPasswordChangePayload(requestData)
  }

  const rightImageStyle = {
    backgroundImage: `url(${Images.loginBackground})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    overflow: 'hidden',
    width: '100%',
    height: 'auto',
    flexGrow: 1,
  }

  return (
    <ScopedCssBaseline>
      <FutureThemeProvider>
        <Box bgcolor="background.default" height="100%">
          <Grid
            container
            maxWidth="xl"
            sx={{
              flexDirection: 'row',
              justifyContent: 'flex-start',
              flexWrap: 'nowrap',
              overflow: 'hidden',
              width: '100vw',
              maxWidth: '100vw',
              height: '100%',
            }}
          >
            <Grid
              container
              sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                width: '600px',
                flexShrink: 0,
                px: 4,
              }}
            >
              <Card variant="outlined" sx={{ p: 6 }}>
                <Typography
                  variant="h3"
                  sx={{
                    my: 4,
                    textAlign: 'center',
                    fontWeight: 'bold',
                  }}
                >
                  Welcome to PartnerSlate!
                </Typography>
                <Typography variant="h5" sx={{ my: 4 }}>
                  To continue your engagement, finish creating your PartnerSlate account below.
                </Typography>
                <LeadJoinForm onSubmit={resetPassword} />
              </Card>
            </Grid>
            <Box sx={rightImageStyle} />
          </Grid>
        </Box>
      </FutureThemeProvider>
    </ScopedCssBaseline>
  )
}

export default LeadJoinScreen
