import { DescriptionOutlined } from '@mui/icons-material'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import React from 'react'

import { StatusSelector } from '@/components/engagements/StatusSelector'
import { Engagement } from '@/domain/engagements'

type HeadingProps = {
  engagement: Engagement
  showProjectDetailFn: () => void
}
export default function Heading({ engagement, showProjectDetailFn }: HeadingProps): JSX.Element {
  return (
    <Box marginBottom={4.5}>
      <Grid container>
        <Grid item xs>
          <Typography color="text.primary" variant="h4">
            Project:{' '}
            <Typography variant="h4" display="inline-block">
              {engagement.project.name}
            </Typography>
            <Tooltip title="Project details">
              <IconButton color="primary" onClick={showProjectDetailFn}>
                <DescriptionOutlined />
              </IconButton>
            </Tooltip>
          </Typography>
        </Grid>
        <StatusSelector engagement={engagement} />
      </Grid>
    </Box>
  )
}
