import { Box, Container, ScopedCssBaseline } from '@mui/material'
import React from 'react'

import { FutureThemeProvider } from '@/helpers/mui'

type Props = {
  children: React.ReactNode
  showSidebar?: boolean
}
export default function ScreenContainer({ children, showSidebar }: Props): JSX.Element {
  return (
    <ScopedCssBaseline>
      <FutureThemeProvider>
        <Box bgcolor="background.default" height="100%">
          <Container
            component="main"
            maxWidth="xl"
            sx={{
              px: { sm: showSidebar ? 0 : 2 },
              py: showSidebar ? 0 : 2,
            }}
          >
            {children}
          </Container>
        </Box>
      </FutureThemeProvider>
    </ScopedCssBaseline>
  )
}
