import { createSearchObject } from '@clearsummit/carabiners'
import { reduxSet as apiAC } from '@partnerslate/radio-dispatch'
import * as Sentry from '@sentry/react'
import { Company, User } from 'partnerslate-models'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Dispatch } from 'redux'

import { Modal } from '@/components'
import { ModalActions } from '@/components/modal/constants'
import eventManager from '@/components/modal/event-manager'
import Navbar from '@/components/Navbar'
import { AppContentID } from '@/constants'
import { endpoints } from '@/helpers/services'
import { StoreState } from '@/redux'
import { verifyEmailPostPayload } from '@/redux/api-payloads'
import selectors from '@/selectors'

import config from '../config'
import { AppContext, initialValues } from './context'
import jsStyles from './styles'
import styles from './styles.scss'

type StateProps = {
  user: User
  company: Company
  userPending: boolean
}

type DispatchProps = {
  verifyEmail: (token: string) => void
}

type AppProps = {
  children: null | undefined | React.ReactElement | Array<React.ReactElement>
}

type Props = AppProps & StateProps & DispatchProps & RouteComponentProps<{ verifyToken: string }>

interface State {
  modalOpen: boolean
}

export class App extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props)
    this.state = initialValues
  }

  componentDidMount(): void {
    const { location, verifyEmail } = this.props
    eventManager.register(ModalActions.ACTIVATE_APP_SCROLL, this.activateAppScroll)
    eventManager.register(ModalActions.DEACTIVATE_APP_SCROLL, this.deactivateAppScroll)

    Sentry.init(config.sentryOptions)

    if (location.search) {
      const params = createSearchObject(location.search)
      if (params.verifyToken) {
        verifyEmail(params.verifyToken)
      }
    }
  }

  deactivateAppScroll = (): void => {
    this.setState({ modalOpen: false }, () => {
      document.getElementsByTagName('body')[0].style.overflow = 'hidden'
    })
  }

  activateAppScroll = (): void => {
    this.setState({ modalOpen: true }, () => {
      document.getElementsByTagName('body')[0].style.overflow = 'visible'
    })
  }

  render(): JSX.Element {
    const { children, user, company, userPending } = this.props
    const { modalOpen } = this.state

    return (
      <AppContext.Provider value={this.state}>
        <div id={AppContentID} css={jsStyles.modalCheck(modalOpen)}>
          <Navbar user={user} company={company} isLoadingUser={userPending} />
          <div className={styles.main}>
            <div className={styles.content}>{children}</div>
          </div>
        </div>
        <Modal />
      </AppContext.Provider>
    )
  }
}

const mapStateToProps = (state: StoreState): StateProps => {
  const user = selectors.user.getUser(state)
  const company = selectors.company.getCompany(state, user.company)

  return {
    user,
    company,
    userPending: selectors.api.getPending(state, endpoints.me),
  }
}

const mapDispatchToProps = (dispatch: Dispatch): DispatchProps => ({
  verifyEmail: (token: string) =>
    dispatch(apiAC.makeRequest.dispatch(verifyEmailPostPayload({ token }))),
})

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(App))
