import styled from '@emotion/styled'

export const InputContainer = styled.div`
  ${({
    fullWidth,
    noMargin,
    autoGrow,
    counterVisible,
  }: {
    fullWidth?: boolean
    noMargin?: boolean
    autoGrow?: boolean
    counterVisible?: boolean
  }) =>
    (fullWidth
      ? `width: 100%;

      textarea {
        box-sizing: border-box;
        width: 100%;
      }`
      : ``) +
    (noMargin
      ? `margin: 0;

      textarea {
        margin: 0;
      }`
      : ``) +
    (autoGrow
      ? `textarea {
        height: auto;
        min-height: initial;
        padding-bottom: 10px;
        resize: none;
      }`
      : ``) +
    (counterVisible
      ? `width: fit-content;
      
      textarea {
        margin-bottom: 0px;
      }`
      : ``)}
`
