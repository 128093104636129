import React, { useEffect, useState } from 'react'

import Strings, { Images } from '@/constants'
import { fileToBase64 } from '@/helpers/image'

import Button, { ActionButton, SecondaryButton } from '../../button'
import Cropper, { CropArea } from '../../image-cropper'
import Slider from '../../slider'
import styles from './styles.scss'

export interface Props {
  image: File
  callback: (data: { image: File; crop: CropArea }) => void
  aspect: number
  close: () => void
}

function ImageCropperModal({ image, callback, aspect, close }: Props): JSX.Element {
  const [zoom, setZoom] = useState(3)
  const [imageBase64, setImage] = useState('')
  const [crop, onCrop] = useState({
    croppedArea: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
    croppedAreaPixels: {
      x: 0,
      y: 0,
      width: 0,
      height: 0,
    },
  })
  useEffect(() => {
    const convert = async () => {
      const converted = await fileToBase64(image)
      if (typeof converted === 'string') {
        setImage(converted)
      }
    }
    convert()
  }, [image])
  return (
    <div className={styles.contentContainer}>
      <ActionButton
        onClick={close}
        className={styles.close}
        icon={Images.blackX}
        ariaLabel={Strings.general.close}
        iconAlt={Strings.general.close}
      />
      <div className={styles.cropperModalContainer}>
        <h1>{Strings.profile.editPhoto}</h1>
        <div className={styles.cropperContainer}>
          <Cropper
            image={imageBase64}
            onCrop={onCrop}
            aspect={aspect}
            zoom={zoom}
            onZoomChange={setZoom}
          />
        </div>
        <div className={styles.zoom}>
          <p>{Strings.general.zoom}</p>
          <Slider onChange={setZoom} min={1} max={3} value={zoom} />
        </div>
        <div className={styles.buttonsContainer}>
          <SecondaryButton
            ariaLabel={Strings.general.cancel}
            title={Strings.general.cancel}
            onClick={() => {
              close()
            }}
          />
          <Button
            ariaLabel={Strings.general.save}
            title={Strings.general.save}
            onClick={() => {
              callback({ image, crop: crop.croppedAreaPixels })
              close()
            }}
          />
        </div>
      </div>
    </div>
  )
}

export default ImageCropperModal
