import React from 'react'

import { ItemWithIcon } from '.'

export function ReviewAdditionalShareDocuments(): JSX.Element {
  return (
    <>
      We've suggested that the Brand share additional documents, such as:
      <ul>
        <li>The product formula</li>
        <li>Photos of their product & packaging</li>
        <li>
          <ItemWithIcon text="Design documents" />
        </li>
        <li>
          <ItemWithIcon text="Packaging specs" />
        </li>
        <li>
          <ItemWithIcon text="Process authority letter" />
        </li>
        <li>A company overview or pitch deck (for startups & emerging brands)</li>
      </ul>
      We suggest you review any documents they've supplied in preparation of your intro call. Pull
      together any open questions you might have.
    </>
  )
}

export function ScheduleIntroCall(): JSX.Element {
  return (
    <>
      Once you've reviewed the shared documents, it's time to schedule an introductory call with the
      brand. In your first call, you should be ready to discuss:
      <ul>
        <li>An introduction to your business and capabilities</li>
        <li>Any R&D / formulation needs</li>
        <li>Any equipment / processing needs</li>
        <li>Any turnkey service needs</li>
        <li>Estimated timeline for this project</li>
      </ul>
      Some questions for you to consider:
      <ul>
        <li>Are they serious and prepared to engage?</li>
        <li>Will they be flexible on necessary formula or packaging adjustments?</li>
      </ul>
      Be sure to provide next steps:
      <ul>
        <li>Request any additional documentation needed</li>
        <li>Explain your process to reach production</li>
      </ul>
    </>
  )
}
