import React from 'react'

import { Button, SecondaryButton } from '@/components'
import { Size } from '@/components/button/types'
import Strings from '@/constants'

import styles from './styles.scss'

type Props = {
  isDirty: boolean
  apply: () => void
  clear: () => void
}

function ActionBar(props: Props): JSX.Element {
  const { isDirty, apply, clear } = props
  return (
    <div className={styles.actionBar}>
      <SecondaryButton
        ariaLabel={Strings.general.clearAll}
        title={Strings.general.clearAll}
        onClick={clear}
        size={Size.small}
      />
      <Button
        ariaLabel={Strings.general.apply}
        title={Strings.general.apply}
        onClick={apply}
        disabled={!isDirty}
      />
    </div>
  )
}

export default ActionBar
