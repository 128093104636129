import CloseIcon from '@mui/icons-material/Close'
import { Box, Card, CardContent, Grid, IconButton, Typography } from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import { Routes } from '@/constants'
import { Engagement } from '@/domain/engagements'
import { clickedCloseExpandedView } from '@/helpers/instrumentation'

type Props = {
  children: React.ReactNode
  isFullHeight?: boolean
  title?: string
  engagement?: Engagement
}
export default function ExpandedCardGrid({
  children,
  isFullHeight = false,
  title,
  engagement,
}: Props): JSX.Element {
  const HEIGHT_FROM_CARD_TO_NAVBAR_TOP = '243px'
  const maxHeight = isFullHeight ? `calc(100vh - ${HEIGHT_FROM_CARD_TO_NAVBAR_TOP})` : '100%'
  const height = maxHeight

  return (
    <Grid container justifyContent="center">
      <Grid item xs={12} sm={8}>
        {title && (
          <Typography variant="h2" pb={2.5} textAlign="center">
            {title}
          </Typography>
        )}
        <Card
          sx={{
            position: 'relative',
            height,
            maxHeight,
            display: 'flex',
            flexDirection: 'column',
          }}
        >
          {engagement && (
            <Box sx={{ textAlign: 'end', position: 'absolute', top: 5, right: 5 }}>
              <Link
                aria-label="Go back to Engagement"
                to={Routes.EngagementDetailSlug(engagement.id)}
              >
                <IconButton onClick={clickedCloseExpandedView} disableRipple>
                  <CloseIcon />
                </IconButton>
              </Link>
            </Box>
          )}
          <CardContent sx={{ pt: 6, display: 'flex', flex: 1, flexDirection: 'column', maxHeight }}>
            {children}
          </CardContent>
        </Card>
      </Grid>
    </Grid>
  )
}
