import React from 'react'

import { SecondaryButton } from '@/components'
import { Size } from '@/components/button/types'
import Strings from '@/constants'
import mixpanel from '@/helpers/mixpanel'

import styles from './styles.scss'

type Props = {
  setEditing: (editing: boolean) => void
  visible: boolean
  editing: boolean
}

function EditingButtons({ setEditing, visible, editing }: Props): JSX.Element | null {
  const primaryButtonText = editing ? Strings.profile.saveProfile : Strings.profile.editProfile

  if (visible) {
    return (
      <div>
        {!editing && (
          <SecondaryButton
            className={styles.editButton}
            size={Size.small}
            title={primaryButtonText}
            ariaLabel={primaryButtonText}
            onClick={() => {
              setEditing(true)
              mixpanel.editProfile()
            }}
          />
        )}
      </div>
    )
  }
  return null
}

export default EditingButtons
