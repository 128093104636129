import { featureFlags } from '@partnerslate/core'
import { useSelector } from 'react-redux'

import selectors from '@/selectors'

// simple wrappers which hide the need to lookup the current user from redux

export const useFeatureFlag = (flagName: string): boolean => {
  const currentUser = useSelector(selectors.user.getUser)
  const currentUserId = currentUser.valid ? currentUser.id : null
  return featureFlags.useFeatureFlag(flagName, currentUserId)
}

export const useFeatureFlagIfAvailable = (flagName: string): boolean | null => {
  const currentUser = useSelector(selectors.user.getUser)
  const currentUserId = currentUser.valid ? currentUser.id : null
  return featureFlags.useFeatureFlagIfAvailable(flagName, currentUserId)
}
