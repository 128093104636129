/* eslint-disable max-lines-per-function */
/* eslint-disable camelcase */
import { AxiosError, AxiosResponse } from 'axios'
import { CompanyPayload, ModelTypes } from 'partnerslate-models'

import { ProductTypes } from '@/constants'
import {
  AttachCompanyImagePayload,
  ChangePasswordPayload,
  VerifyEmailPayload,
} from '@/redux/api-payloads'

import type { APIRequestArgs } from '../ApiService'
import ApiService from '../ApiService'

export const LOGIN_USER_URL = '/accounts/login/'
const ME_URL = '/accounts/me/'
const RESET_PASSWORD = '/accounts/reset-password/'
const CHANGE_PASSWORD = '/accounts/change-password/'
const LOGOUT_USER_URL = 'accounts/logout/'

const VERIFY_EMAIL_URL = '/accounts/verify-email/'

const COMPANY_DETAIL = (slug: string) => `/companies/${slug}/`
const ATTACH_FILE_UPLOAD = (slug: string) => `${COMPANY_DETAIL(slug)}attach/`
const COMPANY_SEARCH = '/companies/search/'

const CHECKOUT_SESSION = '/customers/checkout/'
const CANCEL_SUBSCRIPTION = (subscriptionId: string) => `/customers/${subscriptionId}/cancel/`
const UPDATE_SUBSCRIPTION = (subscriptionId: string) => `/customers/${subscriptionId}/update/`
const DELETE_SOURCE = (cardId: string) => `/sources/${cardId}/delete/`

const FILE_UPLOAD = (id?: string) => `/files/${id ? `${id}/` : ''}`

export type AxiosAdapterSuccessResponse<T = any> = {
  statusCode: number
  data: T
}
export type AxiosAdapterErrorResponse = {
  statusCode: number
  details: any
}

type AxiosAdaptorResponse =
  | [AxiosAdapterSuccessResponse, undefined]
  | [undefined, AxiosAdapterErrorResponse]

const axiosAdaptor = async (
  fn: (args: APIRequestArgs) => Promise<AxiosResponse>,
  args: APIRequestArgs,
): Promise<AxiosAdaptorResponse> => {
  try {
    const response = await fn(args)
    const resp = { statusCode: response?.status, data: response.data }
    return [resp, undefined]
  } catch (e) {
    if (e instanceof AxiosError && e.response) {
      const err = { statusCode: e.response?.status, details: e.response.data.error }
      return [undefined, err]
    }
    throw e
  }
}

export const Api = {
  get: (arg: APIRequestArgs): Promise<AxiosAdaptorResponse> => axiosAdaptor(ApiService.get, arg),
  post: (arg: APIRequestArgs): Promise<AxiosAdaptorResponse> => axiosAdaptor(ApiService.post, arg),
  put: (arg: APIRequestArgs): Promise<AxiosAdaptorResponse> => axiosAdaptor(ApiService.put, arg),
  patch: (arg: APIRequestArgs): Promise<AxiosAdaptorResponse> =>
    axiosAdaptor(ApiService.patch, arg),
  delete: (arg: APIRequestArgs): Promise<AxiosAdaptorResponse> =>
    axiosAdaptor(ApiService.delete, arg),
}

/**
 * @param {api} ApiService
 * @return Object passed to the api saga, each value must be an asyn function that returns Array<ResponeType, ErrorType>
 * supported by the radio-disptach api-saga
 */
const ApiServices = () => {
  // General pagination endpoint
  const getNextPage = (url: string, responseType: keyof typeof ModelTypes) =>
    Api.get({ url, responseType })

  const login = (data: { username: string; password: string }) =>
    Api.post({ url: LOGIN_USER_URL, payload: data, responseType: ModelTypes.User })

  const me = () => Api.get({ url: ME_URL, responseType: ModelTypes.User })

  const updateMe = (data: any) =>
    Api.post({ url: ME_URL, payload: data, responseType: ModelTypes.User })

  const getResetPassword = (data: { username: string }) =>
    Api.get({
      url: RESET_PASSWORD,
      payload: {
        username: encodeURI(data.username),
      },
    })

  const postResetPassword = (data: any) => Api.post({ url: RESET_PASSWORD, payload: data })

  const changePassword = (data: ChangePasswordPayload) =>
    Api.post({ url: CHANGE_PASSWORD, payload: data })

  const logoutUser = () =>
    Api.post({ url: LOGOUT_USER_URL, payload: { refresh: ApiService?.authToken?.refresh } })

  const verifyEmailGet = (data: VerifyEmailPayload) =>
    Api.get({ url: VERIFY_EMAIL_URL, payload: { username: data.username } })

  const verifyEmailPost = (data: { token: string }) =>
    Api.post({ url: VERIFY_EMAIL_URL, payload: { token: data.token } })

  const fetchCompany = (slug: string) =>
    Api.get({ url: COMPANY_DETAIL(slug), responseType: ModelTypes.Company })

  const updateCompany = (payload: CompanyPayload) =>
    Api.patch({
      url: COMPANY_DETAIL(payload.slug || ''),
      payload,
      responseType: ModelTypes.Company,
    })

  const postFileUpload = (payload: { name: string; type: string }) =>
    Api.post({ url: FILE_UPLOAD(), payload })

  const getCompanySearchResults = ({
    text,
    url,
    filters,
  }: {
    text: string
    url?: string
    filters?: Record<string, string>
  }) =>
    Api.get({
      url: url || COMPANY_SEARCH,
      payload: url ? undefined : { text, ...filters },
    })

  const attachCompanyImage = (payload: AttachCompanyImagePayload) =>
    Api.post({
      url: ATTACH_FILE_UPLOAD(payload.companySlug),
      payload: { image_type: payload.imageType, company_slug: payload.companySlug, id: payload.id },
      responseType: ModelTypes.Company,
    })

  const deletePhoto = ({ type, id }: { id: string; type: string }) =>
    Api.delete({ url: FILE_UPLOAD(id), payload: { type } })

  const deleteMe = () => Api.delete({ url: ME_URL })

  const createCheckoutSession = ({
    product,
    companySlug,
    mode,
  }: {
    product: ProductTypes
    companySlug: string
    mode: 'subscription' | 'setup'
  }) => Api.post({ url: CHECKOUT_SESSION, payload: { product, company_slug: companySlug, mode } })

  const cancelSubscription = ({
    subscriptionId,
  }: {
    product: ProductTypes
    subscriptionId: string
  }) => Api.delete({ url: CANCEL_SUBSCRIPTION(subscriptionId) })

  const updateSubscription = ({
    cardId,
    subscriptionId,
  }: {
    cardId: string
    subscriptionId: string
  }) => Api.patch({ url: UPDATE_SUBSCRIPTION(subscriptionId), payload: { card_id: cardId } })

  const deleteCard = (data: { cardId: string; subscriptionId: string }) =>
    Api.delete({
      url: DELETE_SOURCE(data.cardId),
      payload: { subscription_id: data.subscriptionId },
    })

  return {
    getNextPage,
    login,
    me,
    updateMe,
    getResetPassword,
    postResetPassword,
    changePassword,
    logoutUser,
    verifyEmailGet,
    verifyEmailPost,
    fetchCompany,
    postFileUpload,
    attachCompanyImage,
    deletePhoto,
    updateCompany,
    getCompanySearchResults,
    deleteMe,
    createCheckoutSession,
    cancelSubscription,
    updateSubscription,
    deleteCard,
  }
}

const services = ApiServices()

export default services
