/* eslint-disable react/state-in-constructor */
import { FieldOption } from 'partnerslate-models'
import * as React from 'react'

import { Button } from '@/components'

import { Picker } from '../dropdown-styles'
import StyleSelect from '../styled-select'
import dropdownStyles, { customComponents } from './picker-styles'
import jsStyles from './styles'
import styles from './styles.scss'

interface RequiredProps {
  onChange: (value: Array<FieldOption>) => void
  options: Array<{
    options: Array<{
      value?: string
      label: string
      description?: string
      options?: Array<FieldOption>
    }>
    group: string
  }>
  ariaLabel: string
  placeholder: string
  name: string
  title: string
  onApply: (value: Array<FieldOption>) => void
}

interface DefaultProps {
  onBlur: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined
  label: string | undefined
  error: string | null | undefined
  disabled: boolean
  value?: string | undefined
  blurInputOnSelect?: boolean | null
  controlShouldRenderValue?: boolean | null
  menuIsOpen?: boolean | null
  testId?: string | undefined
}

type DropdownProps = Partial<DefaultProps> & RequiredProps
type State = { isOpen: boolean; openBranches: Array<string>; selectedOptions: Array<FieldOption> }

export class TreePicker extends React.Component<DropdownProps, State> {
  state = {
    isOpen: false,
    openBranches: [],
    selectedOptions: [],
  }

  toggleOpen = (): void => {
    this.setState((state) => ({ isOpen: !state.isOpen }))
  }

  // eslint-disable-next-line react/no-unused-class-component-methods
  onSelectChange = (): void => {
    this.toggleOpen()
  }

  onChange = (option: Array<FieldOption>): void => {
    const { onChange } = this.props
    onChange(option)
  }

  handleOpenBranch = (value: string): void => {
    const { openBranches } = this.state
    let newList: Array<string> = []
    newList = [...openBranches]
    if (newList.includes(value)) {
      newList = newList.filter((val) => val !== value)
    } else {
      newList = [...newList, value]
    }

    this.setState({
      openBranches: newList,
    })
  }

  render(): React.ReactElement {
    const { isOpen, openBranches, selectedOptions } = this.state
    const {
      ariaLabel,
      name,
      placeholder,
      error,
      disabled,
      onBlur,
      title,
      options,
      label,
      onApply,
      testId,
    } = this.props
    return (
      <div className={styles.flexColumn}>
        <Picker
          onClose={this.toggleOpen}
          target={
            <Button
              type="button"
              onClick={this.toggleOpen}
              variant="secondary"
              title={title}
              ariaLabel="state button"
              className={styles.button}
              css={jsStyles.button(isOpen)}
              testId={testId}
            />
          }
        >
          <StyleSelect
            id={name}
            className={styles.dropdown}
            styles={dropdownStyles}
            aria-label={ariaLabel}
            name={name}
            value={selectedOptions}
            placeholder={placeholder}
            onChange={this.onChange}
            options={options}
            components={customComponents}
            error={!!error}
            openBranches={openBranches}
            onOpenBranch={this.handleOpenBranch}
            isDisabled={disabled}
            onBlur={onBlur}
            menuIsOpen={isOpen}
            isMulti
            closeMenuOnSelect={false}
            hideSelectedOptions={false}
            label={label}
            onApply={onApply}
          />
        </Picker>
      </div>
    )
  }
}

export default TreePicker
