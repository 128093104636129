import { createBrowserHistory } from 'history'

import { viewedPage } from './instrumentation'

export type LocationState = {
  toRoute?: string
}

const history = createBrowserHistory()

export function trackPageView(): void {
  const pageUrl = window.location.href

  viewedPage(pageUrl)
}

export default history
