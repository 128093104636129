import { css, SerializedStyles } from '@emotion/core'

import styles from '../../../styles/variables.scss'

const {
  buttonShadowGrey,
  primary,
  white,
  buttonHeight,
  smallAction,
  mobileBreakpoint,
  buttonWidthMobile,
} = styles

const Styles = {
  button: (
    size: 'regular' | 'small' | 'large' | undefined,
    variant: string | undefined,
  ): SerializedStyles => css`
    @media (max-width: ${mobileBreakpoint}) {
      min-width: ${buttonWidthMobile};
    }

    width: 200px;
    height: 38px;
    @media (min-width: ${mobileBreakpoint}) {
      width: 243px;
      height: ${buttonHeight};
    }

    ${size === 'small' &&
    `
        width: 104px;
        height: ${buttonHeight};
    `}

    ${size === 'large' &&
    `
        width: 100%;
        height: ${buttonHeight};
    `}

    background: ${primary};
    border: 2px solid ${primary};
    color: ${white};
    outline: none;

    :hover {
      opacity: 0.7;
    }

    ${variant === 'primary' &&
    `
            box-shadow: 2px 2px 9px ${buttonShadowGrey};
    `}

    ${variant === 'secondary' &&
    `
        background-color: transparent;
        border: 2px solid ${primary};
        span {
            color: ${primary};
        }

        :hover {
            opacity: 0.7;
        }
    `}

    ${variant === 'action' &&
    `
        background-color: transparent;
        border: 0px solid transparent;
        width: ${smallAction};
        min-width: ${smallAction};
        height: ${smallAction};
        img {
            margin: 0;
            width: ${smallAction};
            height: ${smallAction};
        }

        :hover {
            opacity: 0.7;
        }
    `}
  `,
  statefulButton: (selected: boolean): SerializedStyles => css`
    ${selected &&
    `
        span {
            color: ${primary} !important;
        }
        svg {
            filter: invert(58%) sepia(97%) saturate(351%) hue-rotate(112deg) brightness(94%) contrast(91%);
        }
    `}
  `,
}
export default Styles
