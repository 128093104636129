import { FieldOption, FieldTypes } from 'partnerslate-models'
import React, { useState } from 'react'

import { ActionButton, BlueLabel } from '@/components'
import Strings, { Images } from '@/constants'
import mixpanel from '@/helpers/mixpanel'
import { getFieldOptions } from '@/helpers/referenceData'

import ActionBar from './action-bar'
import FilterOption from './filter-option'
import FilterSelector from './filter-selector'
import styles from './styles.scss'

export type CompanyFilter = Array<FieldOption>

type Props = {
  isDirty: boolean
  setIsDirty: (val: boolean) => void
  setFilters: React.Dispatch<React.SetStateAction<Record<string, CompanyFilter>>>
}

const getFilterTypes = (): Array<FieldTypes> => {
  const filters: Array<FieldTypes> = [
    FieldTypes.manufacturerType,
    FieldTypes.facilityLocation,
    FieldTypes.productCategories,
    FieldTypes.packagingFormats,
    FieldTypes.processingCapabilities,
    FieldTypes.certifications,
    FieldTypes.productionRunSize,
    FieldTypes.additionalServices,
  ]
  return filters
}

function CompanySearchFilter(props: Props): JSX.Element {
  const { setFilters, isDirty, setIsDirty } = props
  const [title, setTitle] = useState(Strings.search.filters)
  const [selectedFilter, setSelectedFilter] = useState<FieldTypes | undefined>(undefined)
  const [selectedFilters, setSelectedFilters] = useState<Record<string, CompanyFilter>>({})

  const handleBack = () => {
    setSelectedFilter(undefined)
    setTitle(Strings.search.filters)
  }

  const handleSelectFilter = (filters: Record<string, CompanyFilter>) => {
    setSelectedFilters(filters)
    setFilters(filters)
    setIsDirty(true)
  }

  const handleSelectType = (field: FieldTypes) => {
    setSelectedFilter(field)
    setTitle(Strings.general[field])
  }

  const handleApply = () => {
    const selectedFilterKeys = Object.keys(selectedFilters)
    for (let i = 0; i < selectedFilterKeys.length; i++) {
      const filter = selectedFilters[selectedFilterKeys[i]]
      if (filter.length > 0) {
        mixpanel.searchFilter(selectedFilterKeys[i])
      }
    }
    setIsDirty(false)
    handleBack()
  }

  const handleClear = () => {
    const clear = {}
    setSelectedFilters(clear)
    setFilters(clear)
    setIsDirty(false)
    handleBack()
  }

  const getTypeFilterQuantity = (filterType: FieldTypes): number =>
    selectedFilters[filterType] ? selectedFilters[filterType].length : 0

  const filters = getFilterTypes()

  return (
    <div className={styles.filters}>
      <header>
        {selectedFilter ? (
          <div className={styles.btnArea}>
            <ActionButton
              icon={Images.angleLeft}
              onClick={handleBack}
              ariaLabel={Strings.profile.companyDetails}
              iconAlt={Strings.search.filters}
            />
          </div>
        ) : (
          <span className={styles.placeholder} />
        )}
        <h2 className={styles.title}>
          {title}
          {selectedFilter && getTypeFilterQuantity(selectedFilter) > 0 ? (
            <BlueLabel text={`${getTypeFilterQuantity(selectedFilter)}`} />
          ) : null}
        </h2>
      </header>
      {selectedFilter ? (
        <FilterSelector
          type={selectedFilter}
          options={getFieldOptions(selectedFilter)}
          selectedFilters={selectedFilters}
          setSelectedFilters={handleSelectFilter}
        />
      ) : (
        filters.map((type) => (
          <FilterOption
            key={type}
            selected={getTypeFilterQuantity(type)}
            type={type}
            setSelectedFilter={handleSelectType}
          />
        ))
      )}
      <ActionBar apply={handleApply} isDirty={isDirty} clear={handleClear} />
    </div>
  )
}

export default CompanySearchFilter
