import React from 'react'
import { NavLink as BaseNavLink, NavLinkProps } from 'react-router-dom'

import styles from './styles.scss'

const NavLink: React.FC<NavLinkProps> = ({ to, children, ...rest }: NavLinkProps) => (
  <BaseNavLink className={styles.navlink} activeClassName={styles.selected} to={to} {...rest}>
    {children}
  </BaseNavLink>
)

export default NavLink
