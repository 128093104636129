import { joinStr } from '@clearsummit/carabiners'
import * as React from 'react'
import { connect } from 'react-redux'

import Strings, { Images } from '@/constants'
import { EndpointsWithIgnored } from '@/helpers/services'
import { StoreState } from '@/redux'
import selectors from '@/selectors'

import styles from './styles.scss'

interface FormErrorProps {
  /** FormKey to grab and reset submissions errors in the store and get error state
   * as we migrate away from redux fetching, the formKey wouldn't be used so we purposefully
   * pass IGNORED here as props
   */
  formKey: EndpointsWithIgnored // eslint-disable-line react/no-unused-prop-types
  className?: string | undefined
  error?: string | Array<{ field: string; message: string }> | null
}

interface Props extends FormErrorProps {
  error: string | Array<{ field: string; message: string }> | null
}

function FormError({ error, className }: Props) {
  return error ? (
    <p className={joinStr(styles.error, className)}>
      <img src={Images.error} alt={Strings.general.error} />
      {Array.isArray(error) ? error.map((e) => `${e.message}\n`) : error}
    </p>
  ) : null
}

FormError.defaultProps = {
  className: undefined,
  error: null,
}

const mapStateToProps = (state: StoreState, props: FormErrorProps) => ({
  // As we transition away from redux fetching, more errors will be passed as props
  error: props.error || selectors.api.getError(state, props.formKey),
})

export default connect(mapStateToProps)(FormError)
