import React from 'react'

import TailwindReset from '@/components/tailwind-reset'

export type Props = {
  title: string
  desc: string
  children: React.ReactNode
}

export function HeaderCardLayout({ title, desc, children }: Props): JSX.Element {
  return (
    <TailwindReset>
      <div className="bg-white shadow overflow-hidden mx-auto md:my-10 md:rounded-lg max-w-screen-md">
        <div className="px-5 py-6 sm:px-6">
          <h3 className="text-2xl leading-6 font-medium text-gray-900">{title}</h3>
          <p className="mt-2 text-sm text-gray-400">{desc}</p>
        </div>
        {children}
      </div>
    </TailwindReset>
  )
}

export default HeaderCardLayout
