import FieldOption from 'partnerslate-models/lib/field-option'
import React from 'react'

import { Dropdown } from '@/components/base-form/fields'
import { COUNTRIES } from '@/domain/address'

const FIELD_OPTIONS: Array<FieldOption> = COUNTRIES.map(({ id, label }) => {
  return new FieldOption({ id, label })
})

function CountryDropdown(): JSX.Element {
  return (
    <Dropdown
      name="address.countryCode"
      label="Country *"
      placeholder="select a value"
      ariaLabel="Country (required)"
      options={FIELD_OPTIONS}
      testId="Address/country"
    />
  )
}

export default CountryDropdown
