import { Image } from 'partnerslate-models'
import React, { useState } from 'react'

import Strings, { Images } from '@/constants'

import { TransparentButton } from '../button'
import styles from './styles.scss'

type Props = {
  photos: Image[]
  initialPhoto: number
  close: () => void
  visible: boolean
}

function PhotoLightbox(props: Props): JSX.Element | null {
  const { visible } = props
  const { photos, initialPhoto, close } = props
  const [current, setCurrent] = useState(initialPhoto)

  return visible ? (
    <div className={styles.lightbox}>
      <div className={styles.close}>
        <TransparentButton ariaLabel={photos[current].name} onClick={close}>
          <img src={Images.closeIconLightbox} alt={photos[current].name} />
        </TransparentButton>
      </div>
      <div className={styles.btnSpacer}>
        {current !== 0 ? (
          <button type="button" onClick={() => setCurrent(current - 1)}>
            <div className={styles.utilityBtn}>
              <img src={Images.angleLeftWhite} alt={photos[current].name} />
            </div>
          </button>
        ) : null}
      </div>
      <div className={styles.main}>
        <img className={styles.photo} src={photos[current].url} alt={photos[current].name} />
        <span>{Strings.profile.imageOf(current + 1, photos.length)}</span>
      </div>
      <div className={styles.btnSpacer}>
        {current < photos.length - 1 ? (
          <button type="button" onClick={() => setCurrent(current + 1)}>
            <div className={styles.utilityBtn}>
              <img src={Images.angleRightWhite} alt={photos[current].name} />
            </div>
          </button>
        ) : null}
      </div>
    </div>
  ) : null
}

export default PhotoLightbox
