import React, { useState } from 'react'
import { Redirect, RouteComponentProps } from 'react-router'

import { Loading } from '@/components'
import ContentExplorerOrNothing from '@/components/box-content-explorer'
import { Routes } from '@/constants'
import { useEngagementDetails } from '@/helpers/services/queries'
import FourOhFour from '@/screens/four-oh-four'

import ScreenContainer from '../screen-container'
import BackLink from './back-link'
import ExpandedCardGrid from './expanded-card-grid'

export type RouteParams = {
  engagementId: string
}
type Props = RouteComponentProps<RouteParams>

export default function EngagementDocuments({ match }: Props): JSX.Element {
  const { engagementId } = match.params
  const { data: engagement, isLoading } = useEngagementDetails(engagementId)
  const [isFullHeight, setIsFullHeight] = useState(false)

  if (isLoading) {
    return <Loading />
  }

  if (!engagement) {
    return <FourOhFour />
  }

  // We dont' want to allow users to see the todos for an engagement that is still in the NDA stage.
  // We dont' take into account engagement.nda_stage because the engagement might have an off platform NDA
  if (engagement.stage === 'nda') {
    return <Redirect to={Routes.EngagementDetailSlug(engagementId)} />
  }

  const onPreviewChanged = (isPreviewOpen: boolean): void => {
    setIsFullHeight(isPreviewOpen)
  }

  return (
    <ScreenContainer>
      <BackLink engagement={engagement} />
      <ExpandedCardGrid title="Documents" isFullHeight={isFullHeight} engagement={engagement}>
        <ContentExplorerOrNothing
          boxDetails={engagement.boxAccessDetails}
          engagement={engagement}
          isExpanded
          onPreviewChanged={onPreviewChanged}
        />
      </ExpandedCardGrid>
    </ScreenContainer>
  )
}
