import React, { Component } from 'react'

import styles from './styles.scss'

interface CustomErrorProps extends Partial<React.PropsWithChildren<Component>> {
  error: string | null | undefined
}

function CustomError({ error }: CustomErrorProps): React.ReactElement {
  return <span className={styles.error}>{error}</span>
}

export default CustomError
