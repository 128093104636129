import React from 'react'

import Strings, { Images } from '@/constants'

import Button, { ActionButton, SecondaryButton } from '../../button'
import styles from './styles.scss'

export type SearchPaywallProps = {
  results: number
  onCancel: () => void
  onPremium: () => void
}

function SearchPaywallModal({ results, onCancel, onPremium }: SearchPaywallProps): JSX.Element {
  const title = Strings.search.paywall.title(results)

  return (
    <div className={styles.defaultModal}>
      <ActionButton
        onClick={onCancel}
        className={styles.close}
        icon={Images.blackThinX}
        ariaLabel={Strings.general.close}
        iconAlt={Strings.general.close}
      />
      <h1>{title}</h1>
      <div className={styles.defaultModalContents}>
        <span>{Strings.search.paywall.para1}</span>
        <span>{Strings.search.paywall.para2}</span>
      </div>
      <div className={styles.buttonRow}>
        <SecondaryButton
          onClick={onCancel}
          ariaLabel={Strings.general.cancel}
          title={Strings.general.cancel}
        />
        <Button
          ariaLabel={Strings.search.paywall.checkPremium}
          title={Strings.search.paywall.checkPremium}
          onClick={onPremium}
        />
      </div>
    </div>
  )
}

export default SearchPaywallModal
