import { ApiPayload } from '@partnerslate/radio-dispatch'
import type { CompanyPayload } from 'partnerslate-models'
import { Company, FieldTypes } from 'partnerslate-models'
import { BLANK_ADDRESS } from 'partnerslate-models/lib/company'
import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router'
import { Dispatch } from 'redux'

import TestIds from '@/accessibility/test-ids'
import { BaseForm, Loading } from '@/components'
import { SubmitBar } from '@/components/base-form/buttons'
import { CropArea } from '@/components/image-cropper'
import modal from '@/components/modal/modal-events'
import { ImageTypes, Routes } from '@/constants'
import { UpdateCompanyProfileSchema } from '@/forms/schemas'
import mixpanel from '@/helpers/mixpanel'
import services, { endpoints } from '@/helpers/services'
import { ActionCreators, StoreState } from '@/redux'
import {
  CompanyResponse,
  deletePhotoPayload,
  fetchCompanyPayload,
  updateCompanyPayload,
} from '@/redux/api-payloads'
import selectors from '@/selectors'

import FourOhFour from '../four-oh-four'
import EditableDetailsFields from './editable-details-fields'
import SideBar from './sidebar'
import { ProfileArea } from './styles'
import styles from './styles.scss'

interface Props extends RouteComponentProps<{ profileSlug: string }> {
  company: Company
  userCompany: Company
  fetchCompany: (slug: string) => void
  fetchCompanyError: ErrorDetailsType | null
  fetchCompanyMeta: { [key: string]: unknown } | null
  uploadBannerImage: (data: {
    image: File
    crop?: CropArea
    type: ImageTypes
    companySlug: string
  }) => void
  deletePhoto: (id: string, type: ImageTypes, slug: string) => void
  photoPending: boolean
  photoMeta: { [key: string]: unknown } | null
  deletePending: boolean
  deleteMeta: { [key: string]: unknown } | null
}

enum FieldNames {
  website = 'website',
  about = 'about',
  servicesAndCapabilities = 'services_and_capabilities',
}

const getInitialValues = (company: Company) => {
  const initialValues = {
    id: company.id,
    slug: company.slug,
    [FieldNames.servicesAndCapabilities]: company.servicesAndCapabilities,
    [FieldNames.website]: company.website,
    [FieldNames.about]: company.about,
    [FieldTypes.manufacturerType]: company.manufacturerType,
    [FieldTypes.distributionType]: company.distributionType,
    [FieldTypes.brandStage]: company.brandStage,
    [FieldTypes.productCategories]: company.productCategories,
    [FieldTypes.facilityLocation]: company.facilityLocations,
    [FieldTypes.numberOfFacilityLocations]: company.numberOfFacilityLocations,
    [FieldTypes.packagingFormats]: company.packagingFormats,
    [FieldTypes.processingCapabilities]: company.processingCapabilities,
    [FieldTypes.certifications]: company.certifications,
    [FieldTypes.productionRunSize]: company.productionRunSize,
    [FieldTypes.additionalServices]: company.additionalServices,
    [FieldTypes.servicesOffered]: company.servicesOffered,
    [FieldTypes.businessType]: company.businessType,
    [FieldTypes.matchPreferences]: company.matchPreferences,
    [FieldTypes.comanPreviousBrandSizes]: company.comanPreviousBrandSizes,
    [FieldTypes.diverseBackgrounds]: company.diverseBackgrounds,
    [FieldTypes.diverseSocialImpacts]: company.diverseSocialImpacts,
  }

  return initialValues
}

// eslint-disable-next-line max-lines-per-function
export function CompanyProfile({
  company,
  userCompany,
  fetchCompany,
  fetchCompanyError,
  fetchCompanyMeta,
  match,
  uploadBannerImage,
  photoPending,
  photoMeta,
  deletePhoto,
  deleteMeta,
  deletePending,
}: Props): React.ReactElement {
  const [editing, setEditing] = useState(false)
  const [editingField, setEditingField] = useState<FieldTypes | undefined>(undefined)
  const { profileSlug } = match.params
  const openCropper = React.useCallback(
    (image: File, aspect: number) => {
      modal.renderModal('cropper', {
        image,
        callback: (data: { image: File; crop?: CropArea }) =>
          uploadBannerImage({ ...data, type: ImageTypes.banner, companySlug: company.slug }),
        aspect,
      })
    },
    [company.slug, uploadBannerImage],
  )
  const openProfileCropper = React.useCallback(
    (image: File, aspect: number) => {
      modal.renderModal('cropper', {
        image,
        callback: (data: { image: File; crop?: CropArea }) =>
          uploadBannerImage({
            ...data,
            type: ImageTypes.companyProfile,
            companySlug: company.slug,
          }),
        aspect,
      })
    },
    [company.slug, uploadBannerImage],
  )

  useEffect(() => {
    if (!company.valid) {
      fetchCompany(profileSlug)
    }
  }, [company.valid, fetchCompany, profileSlug])

  useEffect(() => {
    if (company.valid) {
      if (userCompany.slug !== company.slug) {
        mixpanel.viewProfile(company)
      } else {
        mixpanel.viewOwnProfile()
      }
    }
  }, [company, userCompany.slug])

  if (fetchCompanyError) {
    const fetchSlug = fetchCompanyMeta?.slug || ''
    if (fetchSlug === match.params.profileSlug) {
      return <FourOhFour />
    }
  }

  const handleSubmit = (
    payload: Company,
  ): ApiPayload<typeof services, CompanyPayload, CompanyResponse | undefined> => {
    // prevent the company's address from being sent in the update payload. We do this because this may be a company
    // with a legacy single-line address which hasn't been updated yet. If we try and send that address back to the server
    // then the entire update will fail validation.
    payload.address = BLANK_ADDRESS // eslint-disable-line no-param-reassign

    setEditing(false)
    setEditingField(undefined)
    return updateCompanyPayload(payload)
  }

  if (company.valid) {
    return (
      <ProfileArea className={styles.profileArea} editing={editing}>
        <div className={styles.body}>
          <BaseForm
            initialValues={getInitialValues(company)}
            onSubmitPayload={(data) =>
              handleSubmit(
                new Company({
                  ...company,
                  ...data,
                }),
              )
            }
            validationSchema={UpdateCompanyProfileSchema}
            formKey={endpoints.updateCompany}
          >
            <EditableDetailsFields
              company={company}
              userCompany={userCompany}
              editing={editing}
              setEditing={setEditing}
              openCropper={openCropper}
              openProfileCropper={openProfileCropper}
              deletePhoto={deletePhoto}
              deletePending={deletePending}
              uploadBannerImage={uploadBannerImage}
              photoPending={photoPending}
              photoMeta={photoMeta}
              deleteMeta={deleteMeta}
            />
            <SideBar
              company={company}
              userCompany={userCompany}
              editing={editing}
              editingField={editingField}
              setEditingField={setEditingField}
              setEditing={setEditing}
            />
            <SubmitBar
              submitTestId={TestIds.Profile.CompanyDetails}
              showAlways={editing}
              onCancel={() => {
                setEditing(false)
                setEditingField(undefined)
              }}
            />
          </BaseForm>
        </div>
      </ProfileArea>
    )
  }
  return <Loading />
}

const mapStateToProp = (
  state: StoreState,
  { match }: RouteComponentProps<{ profileSlug: string }>,
) => {
  const user = selectors.user.getUser(state)
  const slug = match.path === Routes.MyProfile ? user.company : match.params.profileSlug
  return {
    user,
    userCompany: selectors.company.getCompany(state, user.company),
    company: selectors.company.getCompany(state, slug),
    fetchCompanyError: selectors.api.getError(state, endpoints.fetchCompany),
    fetchCompanyMeta: selectors.api.getMeta(state, endpoints.fetchCompany),
    photoPending:
      selectors.api.getPending(state, endpoints.attachCompanyImage) ||
      selectors.api.getPending(state, endpoints.uploadFile) ||
      selectors.api.getPending(state, endpoints.postFileUpload),
    photoMeta:
      selectors.api.getMeta(state, endpoints.attachCompanyImage) ||
      selectors.api.getMeta(state, endpoints.uploadFile) ||
      selectors.api.getMeta(state, endpoints.postFileUpload),
    deletePending: selectors.api.getPending(state, endpoints.deletePhoto),
    deleteMeta: selectors.api.getMeta(state, endpoints.deletePhoto),
  }
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  fetchCompany: (slug: string) =>
    dispatch(ActionCreators.api.makeRequest.dispatch(fetchCompanyPayload(slug))),
  uploadBannerImage: (data: { image: File; crop?: CropArea; companySlug: string }) =>
    dispatch(ActionCreators.user.uploadPhoto.dispatch(data)),
  deletePhoto: (id: string, type: ImageTypes, slug: string) =>
    dispatch(ActionCreators.api.makeRequest.dispatch(deletePhotoPayload({ id, type, slug }))),
  updateCompany: (data: Company) =>
    dispatch(ActionCreators.api.makeRequest.dispatch(updateCompanyPayload(data))),
})

export default connect(mapStateToProp, mapDispatchToProps)(CompanyProfile)
