import React, { Component } from 'react'

import styles from './styles.scss'

interface Props extends Partial<React.PropsWithChildren<Component>> {
  label: string | undefined
  inputName: string | undefined
}

interface PartialProps {
  required: boolean
}

function InputLabel({
  label,
  inputName,
  required = false,
}: Props & Partial<PartialProps>): JSX.Element {
  return (
    <label id={label} className={styles.inputLabel} htmlFor={inputName}>
      {label}
      {required ? ' *' : ''}
    </label>
  )
}

export default InputLabel
