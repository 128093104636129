import { Box, Typography } from '@mui/material'
import { Dialog } from '@partnerslate/ui-components'
import React, { useEffect } from 'react'

import EmailLink from '@/components/email-link'
import { Engagement } from '@/domain/engagements'
import { instrumentation } from '@/helpers'
import { useUpdateEngagementStatus } from '@/helpers/services/queries'
import { CongratsOnMoveToProd } from '@/screens/coman-engagements/images'

type MoveToProductionConfirmationModalProps = {
  engagementToMoveToProduction: Engagement | null
  setEngagementToMoveToProduction: (engagement: Engagement | null) => void
}

export function MoveToProductionConfirmationModal({
  engagementToMoveToProduction,
  setEngagementToMoveToProduction,
}: MoveToProductionConfirmationModalProps): JSX.Element | null {
  const updateEngagementStatus = useUpdateEngagementStatus()

  useEffect(() => {
    if (engagementToMoveToProduction)
      instrumentation.productionConfirmationModalOpen(engagementToMoveToProduction)
  }, [engagementToMoveToProduction])

  if (!engagementToMoveToProduction) return null

  const { perspective } = engagementToMoveToProduction
  const isComanPerspective = perspective === 'partner'

  const handleMoveToProduction = () => {
    if (!engagementToMoveToProduction) return

    instrumentation.productionConfirmationConfirmed(engagementToMoveToProduction)

    updateEngagementStatus.mutate(
      {
        engagementId: engagementToMoveToProduction.id,
        status: 'production',
      },
      {
        onSuccess: (engagement) => {
          instrumentation.updatedEngagementStatus({
            engagement,
            oldStatus: engagementToMoveToProduction.stage,
            newStatus: 'production',
          })
          setEngagementToMoveToProduction(null)
        },
      },
    )
  }

  const handleClose = () => {
    instrumentation.productionConfirmationModalAbandoned(engagementToMoveToProduction)
    setEngagementToMoveToProduction(null)
  }

  return (
    <Dialog
      title=""
      onClose={handleClose}
      open={!!engagementToMoveToProduction}
      onSubmit={handleMoveToProduction}
      submitLabel="Confirm"
      showCancel
      showSubmit
      isSubmitLoading={updateEngagementStatus.isLoading}
    >
      <Box display="flex" flexDirection="column" alignItems="center" sx={{ mb: 1 }}>
        <Typography variant="h2">Congratulations on making it to production!</Typography>
        <img
          src={CongratsOnMoveToProd}
          alt="A gift box to celebrate going to production"
          height={150}
        />
      </Box>
      {isComanPerspective ? (
        <>
          <Typography>Since you moved this engagement to Production:</Typography>
          <ul>
            <li>
              PartnerSlate will follow up with the brand to close out their project on the
              marketplace.
            </li>
            <li>
              Our billing department will also follow up with you to get a copy of the invoice.
              Alternatively, you can email a copy of the invoice to&nbsp;
              <EmailLink email="billing@partnerslate.com" />.
            </li>
          </ul>
        </>
      ) : null}
      <Typography align={isComanPerspective ? 'inherit' : 'center'}>
        If you did not mean to move this engagement to Production then hit Cancel
      </Typography>
    </Dialog>
  )
}
