import { Grid } from '@mui/material'
import React from 'react'

import { Routes } from '@/constants'
import { Engagement } from '@/domain/engagements'

import { getInterTeamTitleByPerspective } from '../../getInterTeamTitleByPerspective'
import { DocumentsPreviewCard, MessagesPreviewCard, TodosPreviewCard } from './preview-cards'

type Props = {
  engagement: Engagement
}

function EngagementStageTechnicalReview({ engagement }: Props): JSX.Element {
  return (
    <Grid container columnSpacing={3} rowSpacing={4}>
      <DocumentsPreviewCard engagement={engagement} />
      <TodosPreviewCard engagement={engagement} />
      <MessagesPreviewCard
        route={Routes.EngagementNotesSlug(engagement.id)}
        engagement={engagement}
        title="Internal Notes"
        messagesType="intra"
      />
      <MessagesPreviewCard
        route={Routes.EngagementMessagesSlug(engagement.id)}
        engagement={engagement}
        title={getInterTeamTitleByPerspective(engagement)}
        messagesType="inter"
      />
    </Grid>
  )
}

export default EngagementStageTechnicalReview
