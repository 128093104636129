import { useFormikContext } from 'formik'
import { Location } from 'history'
import React, { useEffect, useState } from 'react'
import { Prompt } from 'react-router'

import history from '@/helpers/history'

import modal from '../modal/modal-events'

function UnsavedChangesConfirmation(): JSX.Element {
  const formik = useFormikContext()
  const [confirmedNavigation, setConfirmedNavigation] = useState(false)
  const [lastLocation, setLastLocation] = React.useState<Location | null>(null)

  useEffect(() => {
    if (confirmedNavigation && lastLocation) {
      history.push(lastLocation)
    }
  }, [confirmedNavigation, lastLocation])

  const renderConfirmationModal = (location: Location) => {
    modal.renderModal('unsavedChanges', {
      successCallback: () => {
        if (!formik.isValid) return

        formik.submitForm().then(() => {
          setConfirmedNavigation(true)
          modal.remove()
          history.push(location)
        })
      },
      failCallback: () => {
        setConfirmedNavigation(true)
        modal.remove()
      },
    })
  }

  return (
    <Prompt
      when={formik.dirty}
      message={(location: Location) => {
        if (!confirmedNavigation) {
          setLastLocation(location)
          renderConfirmationModal(location)
          return false
        }
        return true
      }}
    />
  )
}

export default UnsavedChangesConfirmation
