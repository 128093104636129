import { FieldTypes } from 'partnerslate-models'
import React from 'react'

import { ActionButton, BlueLabel } from '@/components'
import Strings, { Images } from '@/constants'

import styles from './styles.scss'

type Props = {
  type: FieldTypes
  selected: number
  setSelectedFilter: (type: FieldTypes) => void
}

function FilterOption(props: Props): JSX.Element {
  const { type, setSelectedFilter, selected } = props

  return (
    <div
      role="presentation"
      onClick={() => setSelectedFilter(type)}
      className={styles.filterOption}
    >
      <div>
        <div>
          <h3>{Strings.general[type]}</h3>
        </div>
      </div>
      <div className={styles.btnArea}>
        {selected > 0 ? <BlueLabel text={`${selected} ${Strings.search.selected}`} /> : null}
        <ActionButton
          icon={Images.angleRight}
          onClick={() => setSelectedFilter(type)}
          ariaLabel={Strings.profile.editField(type)}
          iconAlt={Strings.profile.editField(type)}
        />
      </div>
    </div>
  )
}

export default FilterOption
