import { Company } from 'partnerslate-models'
import React from 'react'
import { Link } from 'react-router-dom'

import { Avatar } from '@/components'
import { CompanySearchDescriptionLimit, Routes } from '@/constants'
import { displayFlattenedAddress } from '@/domain/address'

import styles from './styles.scss'

type Props = {
  company: Company
  testId?: string | undefined
}

const abbreviateAboutSection = (about: string): string => {
  if (!about) {
    return ''
  }
  return about.length > CompanySearchDescriptionLimit - 1
    ? `${about.slice(0, CompanySearchDescriptionLimit)}...`
    : about
}

function CompanyCard({ company, testId }: Props): JSX.Element {
  return (
    <Link aria-label={company.slug} to={Routes.CompanyProfileSlug(company.slug)}>
      <div className={styles.card} data-testid={testId}>
        <div className={styles.row}>
          <Avatar
            className={styles.avatar}
            image={company.profilePhotoUnsignedUrl}
            alt={company.companyName}
          />
          <div className={styles.info}>
            <div className={styles.header}>
              <h2>{company.companyName}</h2>
            </div>
            <span className={styles.address}>{displayFlattenedAddress(company.address)}</span>
            <p>{abbreviateAboutSection(company.about)}</p>
          </div>
        </div>
      </div>
    </Link>
  )
}

CompanyCard.defaultProps = {
  testId: undefined,
}

export default CompanyCard
