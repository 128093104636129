import { joinStr } from '@clearsummit/carabiners'
import React from 'react'

import { ActionButton, AddImage } from '@/components'
import Strings, { Images, Metrics } from '@/constants'

import { BannerImage } from './styles'
import styles from './styles.scss'

interface Props {
  editing: boolean
  image: string | null
  addImage: (image: File) => void
  deleteImage: () => void
  pending: boolean
}

function Banner({ editing, image, addImage, pending, deleteImage }: Props): JSX.Element {
  if (editing && !image) {
    return (
      <div className={joinStr(styles.banner, styles.emptyBanner)}>
        <img src={Images.bannerPlaceholder} alt={Strings.profile.addImage} />
        <div className={styles.addBannerBtn}>
          <AddImage onClick={addImage} minSize={Metrics.minBannerSize} pending={pending}>
            <ActionButton
              className={styles.uploadPhoto}
              icon={Images.camera}
              onClick={() => {}}
              ariaLabel={Strings.general.camera}
              iconAlt={Strings.general.camera}
            />
          </AddImage>
        </div>
      </div>
    )
  }
  const bannerStyle = image ? styles.banner : joinStr(styles.banner, styles.emptyBanner)
  return (
    <div className={bannerStyle}>
      {image ? (
        <BannerImage className={styles.banner} image={image} />
      ) : (
        <img src={Images.bannerPlaceholder} alt={Strings.profile.addImage} />
      )}
      {editing && (
        <>
          <div className={styles.addBannerBtn}>
            <AddImage onClick={addImage} minSize={Metrics.minBannerSize} pending={pending}>
              <ActionButton
                className={styles.uploadPhoto}
                icon={Images.camera}
                onClick={() => {}}
                ariaLabel={Strings.general.camera}
                iconAlt={Strings.general.camera}
              />
            </AddImage>
          </div>
          <ActionButton
            className={joinStr(styles.trashcan)}
            icon={Images.trashcan}
            onClick={deleteImage}
            ariaLabel={Strings.profile.deleteBanner}
            iconAlt={Strings.profile.deleteBanner}
          />
        </>
      )}
    </div>
  )
}

export default Banner
