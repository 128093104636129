import { useMediaQuery, useTheme } from '@mui/material'
import { useEffect, useState } from 'react'

export type NonVisibleColumns = {
  tablet: Record<string, any>
  mobile: Record<string, any>
}

export const useNonVisibleColumns = (nonVisibleCols: NonVisibleColumns): Record<string, any> => {
  const theme = useTheme()
  const isTablet = useMediaQuery(theme.breakpoints.down('md'))
  const isPhone = useMediaQuery(theme.breakpoints.down('sm'))

  const [nonVisibleColumns, setNonVisibleColumns] = useState({})

  useEffect(() => {
    let hiddenColumns = {}
    if (isPhone) {
      hiddenColumns = { ...nonVisibleCols.tablet, ...nonVisibleCols.mobile }
    } else if (isTablet) {
      hiddenColumns = nonVisibleCols.tablet
    }
    setNonVisibleColumns(hiddenColumns)
  }, [isPhone, isTablet, nonVisibleCols])

  return nonVisibleColumns
}
