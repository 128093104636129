import { dispatchReducer, runReducers } from '@partnerslate/radio-dispatch'
import produce from 'immer'
import { findIndex } from 'lodash'
import { Company, FieldTypes, Image } from 'partnerslate-models'
import { AnyAction } from 'redux'

import type { CompanyFilter } from '@/components/company-search-filter'
import { ImageTypes } from '@/constants'
import {
  CompanyResponse,
  SearchCompaniesPayload,
  SearchCompaniesResponse,
  UserResponse,
} from '@/redux/api-payloads'
import { companiesNormalizer } from '@/redux/schema'
import { ACTIONS as USER_ACTIONS } from '@/redux/user'

import { noOp } from './shared-reducers'

export type StoredCompanyFieldOptions = Exclude<FieldTypes, FieldTypes.productCategories>
export interface CompanyStoreState {
  pending: boolean
  error: string | null
  companies: {
    [key: string]: Company
  }
  searchedCompanies: SearchedCompaniesStoreState
}

export interface SearchedCompaniesStoreState {
  loaded: boolean
  text: string
  filters: Record<string, CompanyFilter> | undefined
  total: number
  hasMore: boolean
  nextURL: string | undefined
  results: {
    [key: string]: Company
  }
}

// COMPANY Actions
export const ACTIONS = {
  START_UP: 'START_UP',
  FETCH_COMPANY_SUCCESS: 'COMPANY/FETCH_COMPANY_SUCCESS',
  UPDATE_COMPANY_SUCCESS: 'COMPANY/UPDATE_COMPANY_SUCCESS',
  ATTACH_COMPANY_IMAGE_SUCCESS: 'COMPANY/ATTACH_COMPANY_IMAGE_SUCCESS',
  DELETE_PHOTO_SUCCESS: 'COMPANY/DELETE_PHOTO_SUCCESS',
  OPEN_STRIPE_SESSION: 'COMPANY/OPEN_STRIPE_SESSION',
  CANCEL_SUBSCRIPTION_SUCCESS: 'COMPANY/CANCEL_SUBSCRIPTION_SUCCESS',
  SEARCH_COMPANIES_SUCCESS: 'COMPANY/SEARCH_COMPANIES_SUCCESS',
  SEARCH_FILTER_FLAGS_SUCCESS: 'COMPANY/SEARCH_FILTER_FLAGS_SUCCESS',
  DELETE_CARD_SUCCESS: 'COMPANY/DELETE_CARD_SUCCESS',
}

export const INITIAL_STATE: CompanyStoreState = {
  pending: false,
  error: null,
  companies: {},
  searchedCompanies: {
    loaded: false,
    text: '',
    filters: undefined,
    hasMore: true,
    total: 0,
    nextURL: undefined,
    results: {},
  },
}

const updateCompany = produce((draft: CompanyStoreState, payload: UserResponse) => {
  const state = draft
  const { companies } = payload.data
  const normalizedCompanies: { [key: string]: Company } =
    companiesNormalizer(companies).entities.companies || {}
  state.companies = { ...state.companies, ...normalizedCompanies }
})

const fetchCompanySuccess = produce((draft: CompanyStoreState, payload: CompanyResponse) => {
  const state = draft
  state.companies[payload.data.slug] = payload.data
})

const deleteCompanyPhotoSuccess = produce(
  (draft: CompanyStoreState, payload: { id: string; type: ImageTypes; slug: string }) => {
    const state = draft
    if (payload.type === ImageTypes.companyProfile) {
      state.companies[payload.slug].profilePhoto = new Image()
    } else if (payload.type === ImageTypes.banner) {
      state.companies[payload.slug].bannerPhoto = new Image()
    } else if (payload.type === ImageTypes.more) {
      const idx = findIndex(state.companies[payload.slug].photos, (i: Image) => i.id === payload.id)
      state.companies[payload.slug].photos.splice(idx, 1)
    }
  },
)

const cancelSubscriptionSuccess = produce(
  (draft: CompanyStoreState, payload: { meta: { companySlug: string } }) => {
    const state = draft
    const { companySlug } = payload.meta
    state.companies[companySlug].subscription.cancelled = true
  },
)

const deleteCardSuccess = produce(
  (draft: CompanyStoreState, payload: { meta: { companySlug: string } }) => {
    const state = draft
    const { companySlug } = payload.meta
    state.companies[companySlug].subscription.cancelled = true
    state.companies[companySlug].cards = []
  },
)

const searchCompaniesSuccess = produce(
  (
    draft: CompanyStoreState,
    payload: SearchCompaniesResponse & { meta: { searchData: SearchCompaniesPayload } },
  ) => {
    const state: CompanyStoreState = draft
    const { searchData } = payload.meta
    const newSearch =
      searchData.text !== state.searchedCompanies.text ||
      JSON.stringify(searchData.filters) !== JSON.stringify(state.searchedCompanies.filters)
    const companies = companiesNormalizer(payload.data.results).entities.companies || {}
    const results = newSearch ? companies : { ...state.searchedCompanies.results, ...companies }
    const total = payload.data.count
    const hasMore = Boolean(payload.data.next)
    const nextURL = payload.data.next

    state.searchedCompanies = {
      loaded: true,
      total,
      hasMore,
      nextURL,
      text: searchData.text,
      filters: searchData.filters,
      results,
    }
  },
)

export const reduxSet = {
  hydrateUserSuccess: dispatchReducer<CompanyStoreState, UserResponse>(
    USER_ACTIONS.HYDRATE_USER_SUCCESS,
    updateCompany,
  ),
  setUserSession: dispatchReducer<CompanyStoreState, UserResponse>(
    USER_ACTIONS.SET_USER_SESSION,
    updateCompany,
  ),
  fetchCompanySuccess: dispatchReducer<CompanyStoreState, CompanyResponse>(
    ACTIONS.FETCH_COMPANY_SUCCESS,
    fetchCompanySuccess,
  ),
  updateCompanySuccess: dispatchReducer<CompanyStoreState, CompanyResponse>(
    ACTIONS.UPDATE_COMPANY_SUCCESS,
    fetchCompanySuccess,
  ),
  attachCompanyImageSuccess: dispatchReducer<CompanyStoreState, CompanyResponse>(
    ACTIONS.ATTACH_COMPANY_IMAGE_SUCCESS,
    fetchCompanySuccess,
  ),
  deleteCompanyPhotoSuccess: dispatchReducer<
    CompanyStoreState,
    { id: string; type: ImageTypes; slug: string }
  >(ACTIONS.DELETE_PHOTO_SUCCESS, deleteCompanyPhotoSuccess),
  openStripeSession: dispatchReducer<CompanyStoreState>(ACTIONS.OPEN_STRIPE_SESSION, noOp),
  cancelSubscriptionSuccess: dispatchReducer<CompanyStoreState, { meta: { companySlug: string } }>(
    ACTIONS.CANCEL_SUBSCRIPTION_SUCCESS,
    cancelSubscriptionSuccess,
  ),
  searchCompaniesSuccess: dispatchReducer<
    CompanyStoreState,
    SearchCompaniesResponse & { meta: { searchData: SearchCompaniesPayload } }
  >(ACTIONS.SEARCH_COMPANIES_SUCCESS, searchCompaniesSuccess),
  deleteCardSuccess: dispatchReducer<CompanyStoreState, { meta: { companySlug: string } }>(
    ACTIONS.DELETE_CARD_SUCCESS,
    deleteCardSuccess,
  ),
}

export const reducer = (
  state: CompanyStoreState = { ...INITIAL_STATE },
  action: AnyAction,
  // @ts-ignore @Update radio dispatch to use AnyAction
): CompanyStoreState => runReducers(state, action, reduxSet)
