import React, { Component } from 'react'

import styles from './styles.scss'

interface CustomLabelProps extends Partial<React.PropsWithChildren<Component>> {
  label: string | undefined
  name: string | undefined
  required: boolean
}

function CustomLabel({ label, name, required }: CustomLabelProps): JSX.Element {
  return (
    <label id={label} className={styles.label} htmlFor={name}>
      {label}
      {required ? ' *' : ''}
    </label>
  )
}

export default CustomLabel
