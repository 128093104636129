/* eslint-disable @typescript-eslint/no-unused-vars */
/* eslint-disable no-nested-ternary */
/* eslint-disable max-lines-per-function */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import LockOutlinedIcon from '@mui/icons-material/LockOutlined'
import { Box, Grid, Typography } from '@mui/material'
import { Company, CompanyTypeEnum } from 'partnerslate-models'
import React from 'react'

import { TextArea, TextField } from '@/components/base-form/fields'
import Strings, { Images } from '@/constants'
import { displayFlattenedAddress } from '@/domain/address'

import { ComanBadges } from './ComanBadges'
import styles from './styles.scss'

const capabilitiesPlaceholderText =
  '- Products: cookies, enrobed cookies, vegan cookies, gluten free cookies, keto cookies, health cookies, breakfast cookies, stuffed breakfast bars, baked bars, raw bars.\n' +
  '- Equipment/Processing: dry blending, mixing, tunnel oven, Vemag extruder, enrobing, drizzling, metal detector, scale, VFFS, pouching machine\n' +
  '- Packaging: flow wrap, stand up gusset pouches'

const matchPreferencesPlaceholderText =
  '- Capacity: Open capacity on all lines and ability to add shifts, MOQ 10,000 units per SKU, pilot runs available.\n' +
  '- Ideal brand qualities: start-ups and emerging brands; offer additional services for R&D, marketing, and logistics. Brand must have formula, we can assist with scale up.\n'

enum FieldNames {
  website = 'website',
  about = 'about',
  servicesAndCapabilities = 'services_and_capabilities',
  matchPreferences = 'match_preferences',
}

type EditCompanyProps = {
  company: Company
}

type Props = {
  editing: boolean
  setEditing: (editing: boolean) => void
  isOwnEditableCompany: boolean
} & EditCompanyProps

function ShowOwnCompanyDetails({
  company,
  setEditing,
}: {
  company: Company
  setEditing: (editing: boolean) => void
}): JSX.Element {
  return (
    <section className={styles.detailsSection}>
      <h4>Website</h4>
      <div className={styles.companyWebsite}>
        {company.website ? (
          <>
            <img src={Images.globe} alt={company.website} />
            <a
              title={company.website}
              href={company.websiteUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {company.website}
            </a>
          </>
        ) : (
          <div style={{ cursor: 'pointer' }} onClick={() => setEditing(true)}>
            <div className={styles.visibleTextArea}>
              <TextField
                name={FieldNames.website}
                ariaLabel={Strings.profile.website}
                label={Strings.profile.website}
                placeholder={Strings.profile.websitePlaceholder}
              />
            </div>
          </div>
        )}
      </div>

      <div>
        <h4>{Strings.profile.about}</h4>
        <p className={styles.respectLineBreak}>
          {company.about ? (
            company.about
          ) : (
            <div style={{ cursor: 'pointer' }} onClick={() => setEditing(true)}>
              <div className={styles.visibleTextArea}>
                <TextArea
                  name={FieldNames.about}
                  ariaLabel={Strings.profile.about}
                  label={Strings.profile.about}
                  placeholder={Strings.profile.aboutPlaceholder}
                />
              </div>
            </div>
          )}
        </p>
      </div>

      <div>
        <h4>
          {company.companyType === CompanyTypeEnum.MANUFACTURER &&
            Strings.profile.yourServicesAndCapabilities}
        </h4>
        <p className={styles.respectLineBreak}>
          {company.companyType === CompanyTypeEnum.MANUFACTURER ? (
            company.servicesAndCapabilities ? (
              company.servicesAndCapabilities
            ) : (
              <div style={{ cursor: 'pointer' }} onClick={() => setEditing(true)}>
                <div className={styles.visibleTextArea}>
                  <TextArea
                    name="empty-sevices-capabilities"
                    ariaLabel={Strings.profile.yourServicesAndCapabilities}
                    placeholder={capabilitiesPlaceholderText}
                  />
                </div>
              </div>
            )
          ) : null}
        </p>
      </div>

      <div>
        <h4>
          {company.companyType === CompanyTypeEnum.MANUFACTURER &&
            'Tell us everything else (this will not be public to brands)'}
        </h4>
        <p className={styles.respectLineBreak}>
          {company.companyType === CompanyTypeEnum.MANUFACTURER ? (
            company.matchPreferences ? (
              company.matchPreferences
            ) : (
              <div style={{ cursor: 'pointer' }} onClick={() => setEditing(true)}>
                <div className={styles.visibleTextArea}>
                  <TextArea
                    name={FieldNames.matchPreferences}
                    ariaLabel="Your match preferences"
                    noMargin
                    placeholder={matchPreferencesPlaceholderText}
                  />
                  <Box display="flex" alignItems="center">
                    <LockOutlinedIcon sx={{ fontSize: '12px' }} color="inherit" />
                    <Typography variant="body2" color="inherit" sx={{ fontSize: '12px', mt: 0 }}>
                      Brands will not see this information.
                    </Typography>
                  </Box>
                </div>
              </div>
            )
          ) : null}
        </p>
      </div>
    </section>
  )
}

function EditOwnCompanyDetails({ company }: EditCompanyProps): JSX.Element {
  return (
    <section>
      <div className={styles.editableTextInput}>
        <Box component="h4" mb={0.5}>
          {Strings.profile.website}
        </Box>
        <TextField
          name={FieldNames.website}
          ariaLabel={Strings.profile.website}
          placeholder={Strings.profile.websitePlaceholder}
        />
      </div>
      <div className={styles.editableTextArea}>
        <Box component="h4" mb={0.5}>
          {Strings.profile.about}
        </Box>
        <Box maxWidth="100%" sx={{ mb: 1.5, mt: -1.25 }}>
          <Typography variant="body2" color="inherit" sx={{ fontSize: '12px', mt: 0 }}>
            Tell prospects what makes you unique, and what you specialize in.
          </Typography>
        </Box>
        <TextArea
          name={FieldNames.about}
          ariaLabel={Strings.profile.about}
          placeholder={Strings.profile.aboutPlaceholder}
          maxLength={5000}
          showCounter
        />
      </div>
      {company.companyType === CompanyTypeEnum.MANUFACTURER && (
        <div className={styles.editableTextArea}>
          <Box component="h4" mb={0.5}>
            {Strings.profile.yourServicesAndCapabilities}
          </Box>
          <Box maxWidth="100%" sx={{ mb: 1.5, mt: -1.25 }}>
            <Typography variant="body2" color="inherit" sx={{ fontSize: '12px', mt: 0 }}>
              List types of products, packaging and processing capabilities you support.
            </Typography>
          </Box>

          <TextArea
            name={FieldNames.servicesAndCapabilities}
            ariaLabel={Strings.profile.yourServicesAndCapabilities}
            placeholder={capabilitiesPlaceholderText}
            maxLength={5000}
            showCounter
          />
        </div>
      )}
      {company.companyType === CompanyTypeEnum.MANUFACTURER && (
        <Grid>
          <Box mb={2} display="flex" flexDirection="column">
            <Box display="flex" alignItems="center">
              <Box component="h4" mb={0.5}>
                Tell us everything else (this will not be public to brands)
              </Box>
              <LockOutlinedIcon sx={{ fontSize: '14px' }} color="inherit" />
            </Box>
            <Box maxWidth="100%" sx={{ mb: 1.5, mt: -1.25 }}>
              <Typography variant="body2" color="inherit" sx={{ fontSize: '12px', mt: 0 }}>
                This field is for you to put any other details that you want to share about your
                match preferences that you do not want brands to see. This information is private
                and is only read by PartnerSlate's AI algorithm to match you with projects
              </Typography>
            </Box>

            <div className={styles.editableTextArea}>
              <TextArea
                name={FieldNames.matchPreferences}
                ariaLabel="Your match preferences"
                noMargin
                placeholder={matchPreferencesPlaceholderText}
              />
            </div>
            <Box display="flex" alignItems="center">
              <LockOutlinedIcon sx={{ fontSize: '12px' }} color="inherit" />
              <Typography variant="body2" color="inherit" sx={{ fontSize: '12px', mt: 0 }}>
                Brands will not see this information.
              </Typography>
            </Box>
          </Box>
        </Grid>
      )}
    </section>
  )
}

function PublicCompanyDetails({ company }: { company: Company }) {
  return (
    <section className={styles.detailsSection}>
      <h4>Website</h4>
      <div className={styles.companyWebsite}>
        {company.website ? (
          <>
            <img src={Images.globe} alt={company.website} />
            <a
              title={company.website}
              href={company.websiteUrl}
              target="_blank"
              rel="noopener noreferrer"
            >
              {company.website}
            </a>
          </>
        ) : null}
      </div>

      <div>
        <h4>{Strings.profile.about}</h4>
        <p className={styles.respectLineBreak}>{company.about ? company.about : null}</p>
      </div>

      <div>
        <h4>
          {company.companyType === CompanyTypeEnum.MANUFACTURER && 'Services and Capabilities'}
        </h4>
        <p className={styles.respectLineBreak}>
          {company.companyType === CompanyTypeEnum.MANUFACTURER
            ? company.servicesAndCapabilities
              ? company.servicesAndCapabilities
              : null
            : null}
        </p>
      </div>
    </section>
  )
}

function EditableCompanyDetails(props: Props): JSX.Element {
  const { company, editing, setEditing, isOwnEditableCompany } = props
  const address = displayFlattenedAddress(company.address)

  return (
    <div className={styles.companyDetails}>
      <section className={styles.companyIntro}>
        <Box display="flex" alignItems="center">
          <Box display="flex" flex="none" mr={2}>
            <h2>{company.companyName}</h2>
          </Box>
          <ComanBadges badgeKeys={company.comanBadgeKeys} />
        </Box>
        <span>{address}</span>
      </section>

      {isOwnEditableCompany ? (
        editing ? (
          <EditOwnCompanyDetails company={company} />
        ) : (
          <ShowOwnCompanyDetails company={company} setEditing={setEditing} />
        )
      ) : (
        <PublicCompanyDetails company={company} />
      )}
    </div>
  )
}

export default EditableCompanyDetails
