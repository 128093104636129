import { Form } from 'formik'
import * as React from 'react'

import TestIds from '@/accessibility/test-ids'
import { EmailIcon, PasswordIcon } from '@/components'
import BaseForm from '@/components/base-form'
import { SubmitButton } from '@/components/base-form/buttons'
import { EmailField, PasswordField } from '@/components/base-form/fields'
import FormError from '@/components/base-form/form-error'
import { Size } from '@/components/button/types'
import Strings from '@/constants'
import { endpoints } from '@/helpers/services'
import { loginPayload } from '@/redux/api-payloads'

import { LoginSchema } from '../schemas'
import styles from './styles.scss'

enum FieldNames {
  username = 'username',
  password = 'password',
}

const initialValues = {
  [FieldNames.username]: '',
  [FieldNames.password]: '',
}

function LoginForm(): JSX.Element {
  return (
    <BaseForm
      initialValues={initialValues}
      validationSchema={LoginSchema}
      onSubmitPayload={loginPayload}
      formKey={endpoints.login}
    >
      <Form>
        <div className={styles.loginInputs}>
          <EmailField
            type="text"
            icon={<EmailIcon />}
            name={FieldNames.username}
            label={Strings.auth.login.email}
            placeholder={Strings.auth.login.enterEmail}
            testId={TestIds.Login.Username}
          />
          <PasswordField
            name={FieldNames.password}
            icon={<PasswordIcon />}
            label={Strings.auth.login.password}
            placeholder={Strings.auth.login.enterPassword}
            testId={TestIds.Login.Password}
          />
        </div>
        <FormError formKey={endpoints.login} />
        <div className={styles.buttons}>
          <SubmitButton
            size={Size.large}
            title={Strings.auth.login.signIn}
            ariaLabel={Strings.auth.login.signIn}
            testId={TestIds.Login.Submit}
          />
        </div>
      </Form>
    </BaseForm>
  )
}

export default LoginForm
