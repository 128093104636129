import { joinStr } from '@clearsummit/carabiners'
import styled from '@emotion/styled'
import React from 'react'
import {
  components as SelectComponents,
  IndicatorProps,
  OptionProps,
  OptionTypeBase,
  StylesConfig,
} from 'react-select'
import { Option } from 'react-select/src/filters'

import { Images } from '@/constants'

import styles from './styles.scss'

const customStyles: StylesConfig = {
  option: (provided) => ({
    ...provided,
    backgroundColor: styles.white,
    borderColor: styles.white,
    color: styles.black,
    minHeight: '46px',
    fontSize: '14px',
    lineHeight: '20px',
    fontWeight: 'lighter',
    margin: 'auto',
    paddingTop: '14px',
    paddingBottom: '14px',
    ':active': {
      color: styles.black,
    },
  }),
  container: (provided, props) => ({
    ...provided,
    borderColor: props.selectProps.error ? styles.error : styles.transparent,
    borderWidth: '1px',
  }),
  control: (provided) => ({
    ...provided,
    border: 0,
    backgroundColor: styles.lightGrey,
    boxShadow: undefined,
    paddingLeft: 10,
    paddingRight: 10,
    fontWeight: 300,
    fontSize: styles.standardFont,
    flex: 1,
    background: styles.lightGrey2,
  }),
  placeholder: (provided) => ({
    ...provided,
    color: styles.middleGrey,
    fontWeight: 400,
    fontSize: styles.standardFont,
  }),
  singleValue: (provided) => ({
    ...provided,
    color: styles.orange,
  }),
  menu: (provided) => ({
    ...provided,
    marginTop: 0,
    marginBottom: 0,
    paddingTop: 0,
    borderRadius: '6px',
    borderStyle: 'solid',
    borderWidth: 0,
    backgroundColor: styles.white,
    boxShadow: '1px 2px 10px 0 rgba(0, 0, 0, 0.15)',
    maxHeight: '184px',
    overflow: 'auto',
    zIndex: 2000,
  }),
  menuList: (provided) => ({
    ...provided,
    paddingTop: 0,
    maxHeight: '184px',
    paddingBottom: '20px',
    '::-webkit-scrollbar-track': {
      backgroundColor: 'transparent',
    },
    '::-webkit-scrollbar-thumb': {
      backgroundColor: styles.secondaryOpacity,
      WebkitBorderRadius: '1ex',
    },
    '::-webkit-scrollbar': {
      width: '10px',
      borderRadius: '2.5px',
      backgroundColor: 'transparent',
    },
  }),
  multiValue: (provided) => ({
    ...provided,
    backgroundColor: styles.secondaryLightestOpacity,
    borderRadius: '5px',
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    color: styles.secondary,
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    color: styles.secondary,
  }),
}

function DropdownIndicator(props: IndicatorProps<OptionTypeBase>): React.ReactElement {
  const {
    selectProps: { menuIsOpen },
  } = props
  return (
    SelectComponents.DropdownIndicator && (
      <SelectComponents.DropdownIndicator {...props}>
        <img
          src={Images.arrow}
          className={joinStr(styles.accessory, menuIsOpen ? styles.flip : undefined)}
          alt="arrow"
        />
      </SelectComponents.DropdownIndicator>
    )
  )
}

const CustomOption = styled.div`
  ${({ isSelected }: { isSelected: boolean }) =>
    isSelected
      ? `
      > div {
        background-color: ${styles.secondaryLightestOpacity};
        color: ${styles.secondary};
        font-weight: bold;
      }
    `
      : ''};
`

function Option(props: OptionProps<OptionTypeBase>): React.ReactElement {
  const { isSelected } = props
  return (
    <CustomOption className={styles.checkbox} isSelected={isSelected}>
      <SelectComponents.Option {...props} />
    </CustomOption>
  )
}

function MultiOption(props: OptionProps<OptionTypeBase>): React.ReactElement {
  const { isSelected, label, children } = props
  return (
    <div className={styles.checkbox}>
      <SelectComponents.Option {...props}>
        <input
          className={styles.customCheckbox}
          type="checkbox"
          checked={isSelected}
          readOnly
          id={label && label.trim()}
        />
        <label htmlFor={label && label.trim()}>{children}</label>
      </SelectComponents.Option>
    </div>
  )
}

export const customComponents = { DropdownIndicator, IndicatorSeparator: null, Option }
export const multiComponents = { DropdownIndicator, IndicatorSeparator: null, Option: MultiOption }

export default customStyles
