import { joinStr } from '@clearsummit/carabiners'
import { Card } from 'partnerslate-models'
import React, { useCallback } from 'react'
import { Redirect } from 'react-router-dom'

import { SecondaryButton, TransparentButton } from '@/components'
import modal from '@/components/modal/modal-events'
import Strings, { Routes } from '@/constants'
import { UpdateCardForm } from '@/forms'
import { FieldNames } from '@/forms/schemas'

import styles from './styles.scss'

interface Props {
  card: Card
  checkout: () => void
  deleteCard: () => void
}

function PaymentSettings({ card, checkout, deleteCard }: Props): JSX.Element {
  const confirmDeleteCard = useCallback(() => {
    modal.renderModal('deleteCard', { successCallback: deleteCard })
  }, [deleteCard])
  if (!card.valid) {
    return <Redirect to={Routes.ProfileSubscribe} />
  }
  return (
    <div className={joinStr(styles.row, styles.flex)}>
      <div className={styles.flexRow}>
        <div>
          <h1>{Strings.profile.paymentSettings}</h1>
          <p>{Strings.profile.thisCardWillBeUsed}</p>
        </div>
        <SecondaryButton
          ariaLabel={Strings.general.editInformation}
          title={Strings.general.editInformation}
          onClick={checkout}
        />
      </div>
      <UpdateCardForm
        initialValues={{
          [FieldNames.nameOnCard]: card.billingDetails.name,
          [FieldNames.cardNumber]: card.card.last4,
          [FieldNames.cardExpiration]: `${card.card.expMonth
            .toString()
            .padStart(2, '0')} ${card.card.expYear.toString().slice(2, 4)}`,
          [FieldNames.cvc]: '',
        }}
      />
      <TransparentButton
        className={styles.deleteAccount}
        title={Strings.profile.deleteCard}
        onClick={confirmDeleteCard}
        ariaLabel={Strings.profile.deleteCard}
      />
    </div>
  )
}

export default PaymentSettings
