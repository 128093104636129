import { Stripe } from '@stripe/stripe-js'
import { call } from 'redux-saga/effects'

import stripePromise from '@/helpers/services/stripe'

export function* openStripeSession({
  payload,
}: StandardAction<{ data: { data: { id: string } } }>): GeneratorType {
  const session = payload.data.data
  const stripe = yield stripePromise
  if (stripe) {
    yield call((stripe as Stripe).redirectToCheckout, {
      sessionId: session.id,
    })
  }
}

export default { openStripeSession }
