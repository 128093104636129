export const ModalActions = {
  RENDER_MODAL: 'RENDER_MODAL',
  DID_MOUNT: 'DID_MOUNT',
  WILL_UNMOUNT: 'WILL_UNMOUNT',
  REMOVE_MODAL: 'REMOVE_MODAL',
  SET_CONTENT: 'SET_CONTENT',
  SET_DETAIL_CONTENT: 'SET_DETAIL_CONTENT',
  ACTIVATE_APP_SCROLL: 'ACTIVATE_APP_SCROLL',
  DEACTIVATE_APP_SCROLL: 'DEACTIVATE_APP_SCROLL',
}
