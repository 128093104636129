/* eslint-disable react/no-unused-class-component-methods */
/* eslint-disable react/state-in-constructor */
import { FieldOption } from 'partnerslate-models'
import * as React from 'react'

import { SecondaryButton } from '@/components'

import { Picker } from '../dropdown-styles'
import StyleSelect from '../styled-select'
import dropdownStyles, { customComponents } from './picker-styles'
import jsStyles from './styles'
import styles from './styles.scss'

interface RequiredProps {
  onChange: (value: Array<string>) => void
  options: Array<{ value: string; label: string; description?: string }>
  ariaLabel: string
  placeholder: string
  name: string
  title: string
  onApply: (value: string) => void
}

interface DefaultProps {
  label: string | undefined
  error: string | null | undefined
  disabled: boolean
  value?: boolean | undefined
  blurInputOnSelect?: boolean | null
  controlShouldRenderValue?: boolean | null
  menuIsOpen?: boolean | null
  onBlur: ((e: React.ChangeEvent<HTMLInputElement>) => void) | undefined
  testId: string | undefined
}

type DropdownProps = Partial<DefaultProps> & RequiredProps
type State = { isOpen: boolean }

export class MultiPicker extends React.Component<DropdownProps, State> {
  state = { isOpen: false }

  onChange = (option: Array<FieldOption>): void => {
    const { onChange } = this.props
    onChange(option.map((item) => item.value))
  }

  toggleOpen = (): void => {
    this.setState({ isOpen: true })
  }

  toggleClose = (): void => {
    this.setState({ isOpen: false })
  }

  onSelectChange = (): void => {
    this.toggleOpen()
  }

  render(): JSX.Element {
    const { isOpen } = this.state
    const {
      ariaLabel,
      name,
      placeholder,
      options,
      error,
      disabled,
      onBlur,
      title,
      testId,
      ...rest
    } = this.props
    return (
      <div className={styles.flexColumn}>
        <Picker
          onClose={this.toggleOpen}
          target={
            <SecondaryButton
              onClick={this.toggleOpen}
              title={title}
              ariaLabel={`${ariaLabel} button`}
              className={styles.button}
              css={jsStyles.button(isOpen)}
            />
          }
        >
          <StyleSelect
            // @ts-ignore
            id={name}
            className={styles.dropdown}
            styles={dropdownStyles}
            aria-label={ariaLabel}
            name={name}
            placeholder={placeholder}
            options={options}
            components={customComponents}
            error={!!error}
            isDisabled={disabled}
            onBlur={onBlur}
            isMulti
            closeMenuOnSelect={false}
            menuIsOpen={isOpen}
            hideSelectedOptions={false}
            testId={testId}
            {...rest}
            onChange={this.onChange}
          />
        </Picker>
      </div>
    )
  }
}

export default MultiPicker
