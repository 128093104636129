/* eslint-disable max-lines-per-function */
/* eslint-disable no-console */
import * as Sentry from '@sentry/react'
import axios, { AxiosError, AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios'
import { modelMapper, ModelTypes } from 'partnerslate-models'

import { getAuth, setAuth } from '@/helpers/auth'

import Config from '../config'
import { LOGIN_USER_URL } from './services/api'

const REFRESH_TOKEN = '/api/v1/refresh/'

export interface APIRequestArgs {
  url: string
  payload?: unknown
  params?: unknown
  responseType?: keyof typeof ModelTypes
}

function errorIsFromLoginAttempt(error: AxiosError) {
  const requestUrl = error.request?.responseURL
  return requestUrl && requestUrl.endsWith(LOGIN_USER_URL)
}

class ApiService {
  axios: AxiosInstance

  authToken: JWTTokens | undefined | null

  refreshing: boolean

  constructor() {
    this.refreshing = false
    this.axios = axios.create()
    const token = getAuth()
    this.setupInstance(token)
  }

  setupInstance = (authToken: JWTTokens | null | undefined): void => {
    const { API_URL, showsAPIBodies, buildstamp } = Config
    const data: AxiosRequestConfig = {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        'PS-App': 'ps-frontend', // consistent with our Sentry naming
        'PS-Interop-Version': '1', // hardcoded for now
        'PS-Buildstamp': buildstamp,
      },
      baseURL: API_URL || '',
    }

    if (authToken && data.headers) {
      data.headers.Authorization = `Bearer ${authToken.access}`
    }
    this.authToken = authToken

    this.axios = axios.create(data)

    let start: number
    // @ts-ignore
    this.axios.interceptors.request.use((configuration) => {
      start = Date.now()
      if (Config.showsAPIBodies) {
        const method = configuration?.method || ''
        // eslint-disable-next-line no-console
        console.groupCollapsed(
          `%c API Request => ${method.toUpperCase()} ${configuration.url}`,
          `color: lightslategrey`,
        )
        console.log('timestamp', new Date(Date.now()).toString())
        console.log('configuration', configuration)
        console.groupEnd()
      }
      return configuration
    })

    this.axios.interceptors.response.use(
      (response: AxiosResponse) => {
        if (showsAPIBodies) {
          const method = response?.config?.method || ''
          console.groupCollapsed(
            `%c API Response => ${method.toUpperCase()} ${response?.config.url}`,
            `color: forestgreen`,
          )
          console.log('timestamp', new Date(Date.now()).toString())
          console.log('duration', `${Date.now() - start}ms`)
          console.log('response', response)
          console.groupEnd()
        }
        // @ts-ignore
        const { apiResponseType } = response.config
        if (apiResponseType) {
          response.data = modelMapper(apiResponseType, response.data.data || response.data)
        }
        return response
      },
      async (error: AxiosError) => {
        const method = error.response?.config?.method || ''
        if (
          !this.refreshing &&
          (error.response?.status === 401 || error.response?.status === 403) &&
          !errorIsFromLoginAttempt(error)
        ) {
          // Try to refresh the token and make the request again
          console.log(
            `%c API Refreshing => ${method.toUpperCase()} ${error.response?.config.url || ''}`,
            `color: gold`,
          )
          const refreshPayload = { refresh: this.authToken?.refresh }
          try {
            this.refreshing = true
            const axiosInstance = axios.create()
            const refreshResponse = await axiosInstance.post(REFRESH_TOKEN, refreshPayload, {
              headers: {
                Accept: 'application/json',
                'Content-Type': 'application/json',
              },
            })
            const tokens = refreshResponse.data
            this.setAuthHeader(tokens)
            const newConfig = {
              ...error.config,
              headers: { ...error.config.headers, Authorization: `Bearer ${tokens.access}` },
            }
            const response = await this.axios.request(newConfig)
            return Promise.resolve(response)
          } catch (e) {
            Sentry.captureException(e, {
              contexts: {
                refreshPayload,
              },
            })
            console.error({ e })
            window.location.replace('/')
          } finally {
            this.refreshing = false
          }
        }
        console.groupCollapsed(
          `%c API Error => ${method.toUpperCase()} ${error.response?.config.url || ''}`,
          `color: firebrick`,
        )
        console.log('timestamp', new Date(Date.now()).toString())
        console.log('duration', `${Date.now() - start}ms`)
        console.log('error', { error })
        console.groupEnd()
        return Promise.reject(error)
      },
    )
  }

  setAuthHeader = (tokens: JWTTokens) => {
    this.authToken = tokens
    setAuth(tokens)
    this.axios.defaults.headers.common.Authorization = `Bearer ${tokens.access}`
  }

  get = ({ url, payload, responseType }: APIRequestArgs): Promise<AxiosResponse> =>
    // @ts-ignore
    this.axios.get<unknown>(url, { apiResponseType: responseType, params: payload })

  post = ({ url, payload, responseType }: APIRequestArgs): Promise<AxiosResponse> =>
    // @ts-ignore
    this.axios.post<unknown>(url, payload, { apiResponseType: responseType })

  put = ({ url, payload, responseType }: APIRequestArgs): Promise<AxiosResponse> =>
    // @ts-ignore
    this.axios.put<unknown>(url, payload, { apiResponseType: responseType })

  patch = ({ url, payload, responseType }: APIRequestArgs): Promise<AxiosResponse> =>
    // @ts-ignore
    this.axios.patch<unknown>(url, payload, { apiResponseType: responseType })

  delete = ({ url, payload, responseType }: APIRequestArgs): Promise<AxiosResponse> =>
    // @ts-ignore
    this.axios.delete(url, { params: payload, apiResponseType: responseType })
}

export default new ApiService()
