import styled from '@emotion/styled'

type Props = {
  horizontal?: boolean
}

const RadioList = styled.ul<Props>`
  display: flex;
  flex-direction: ${(props) => (props.horizontal ? 'row' : 'column')};
  margin: ${(props) => (props.horizontal ? '10px 0' : '0 0 15px 0')};
  padding: 0;
  li {
    margin-right: ${(props) => (props.horizontal ? '15px' : '0')};
  }
`

export default RadioList
