import React from 'react'

import Strings from '@/constants'

import type { Props } from './default-modal'
import DefaultModal from './default-modal'

function DeleteCardModal({ successCallback, close }: Props): JSX.Element {
  return (
    <DefaultModal
      title={Strings.profile.areYouSureDeleteCard}
      paragraphs={[Strings.profile.deleteCardDetails]}
      successText={Strings.profile.deleteCard}
      successCallback={successCallback}
      close={close}
    />
  )
}

export default DeleteCardModal
