import { instrumentation as coreInstrumentation } from '@partnerslate/core'
import { getUnixTime } from 'date-fns'
import { CompanyTypeEnum } from 'partnerslate-models'
import { call, put } from 'redux-saga/effects'

import { Toast } from '@/components'
import { CropArea } from '@/components/image-cropper'
import config from '@/config'
import { ImageTypes, Routes } from '@/constants'
import ApiService from '@/helpers/ApiService'
import { deleteAuth, getAuth, setAuth } from '@/helpers/auth'
import history from '@/helpers/history'
import { getCroppedImg } from '@/helpers/image'
import mixpanel from '@/helpers/mixpanel'
import { ActionCreators } from '@/redux'
import { hydrateUserPayload, uploadFilePayload, UserResponse } from '@/redux/api-payloads'

export function* setUserSession({ payload }: StandardAction<UserResponse>): GeneratorType {
  const user = payload.data || {}
  const { token } = user
  if (token) {
    // link mixpanel Distinct IDs together when possible
    coreInstrumentation.identify({
      userId: user.id,
      emailAddress: user.email,
      companyId: user.companies[0].id,
    })
    mixpanel.openApp()
    if (config.isProduction) {
      const IntercomValues: Record<CompanyTypeEnum, string> = {
        brand: 'Food Brand',
        manufacturer: 'Manufacturer',
      }
      ;(window as any).Intercom('boot', {
        /* eslint-disable camelcase */
        app_id: 'drsa2xvc',
        name: user.name,
        email: user.email,
        user_id: user.id,
        created_at: getUnixTime(new Date(user.dateJoined)), // Signup date as a Unix timestamp
        // @ts-ignore not a string yet
        type: IntercomValues[user.company.companyType],
        vertical_padding: 60,
        /* eslint-enable camelcase */
      })
    }
    yield call(setAuth, token)
    yield call(ApiService.setupInstance, token)
  }
}

export function* verifyEmailSuccess({ payload }: StandardAction<UserResponse>): GeneratorType {
  const user = payload.data
  const { token } = user
  if (token) {
    yield call(ApiService.setupInstance, token)
    yield call(history.push, Routes.MyProfile)
  } else {
    yield call(history.push, Routes.Login)
  }
}

export function* logout(): GeneratorType {
  yield call(deleteAuth)
  yield call(ApiService.setupInstance, undefined)
  yield call(history.push, Routes.Login)
  if (config.isProduction) {
    ;(window as any).Intercom('shutdown')
  }
}

export function* resetPasswordSuccess(): GeneratorType {
  Toast.success('Success', 'Your password is set')
  yield call(history.push, Routes.Login)
}

export function* startup(): GeneratorType {
  const ps = (window as any).partnerSlate
  if (ps && ps.hijack) {
    const hijackToken = ps.hijack.token as JWTTokens
    yield call(setAuth, hijackToken)
  }

  const token = getAuth()
  if (token) {
    yield call(ApiService.setupInstance, token)
    yield put(ActionCreators.api.makeRequest.dispatch(hydrateUserPayload()))
  }
}

export function* uploadPhoto({
  payload,
}: StandardAction<{
  image: File
  crop?: CropArea
  type: ImageTypes
  companySlug?: string | undefined
}>): GeneratorType {
  const { image, crop, companySlug, type } = payload
  let uploadImage: File = image
  if (crop) {
    const base64Image = yield call(getCroppedImg, image, crop)
    uploadImage = (base64Image as { blob: File; filename: string }).blob
  }
  let model = 'UploadFile'
  if (companySlug && type !== ImageTypes.profile) {
    model = type === ImageTypes.more ? 'CompanyBasedMorePhotos' : 'CompanyBased'
  }
  yield put(
    ActionCreators.api.makeRequest.dispatch(
      uploadFilePayload(
        { name: image.name, type: image.type, model },
        { image: uploadImage, imageType: type, companySlug },
      ),
    ),
  )
}

export default { setUserSession }
