import React from 'react'

import { Button, Paper } from '@/components'
import config from '@/config'

import styles from './styles.scss'

export function DevTools(): JSX.Element {
  const handleBoom = () => {
    throw new Error(
      `Boom! - testing exceptions from within a React component @ ${new Date().toISOString()}`,
    )
  }

  return (
    <div className={styles.background}>
      <Paper className={styles.container}>
        <h1>Internal Dev Tools</h1>
        <div className={styles.row}>
          <Button ariaLabel="Boom" title="Boom" onClick={handleBoom} />
          <p>Triggers an exception (so we can test things like error reporting)</p>
        </div>
        <div className={styles.row}>
          <h3>
            Buildstamp: <em>{config.buildstamp}</em>
          </h3>
        </div>
      </Paper>
    </div>
  )
}

export default DevTools
