import { TextField, Typography } from '@mui/material'
import { ApiPayload } from '@partnerslate/radio-dispatch'
import { ErrorMessage, Form } from 'formik'
import React from 'react'

import TestIds from '@/accessibility/test-ids'
import BaseForm from '@/components/base-form'
import { SubmitButton } from '@/components/base-form/buttons'
import { BaseField, CheckboxField } from '@/components/base-form/fields'
import MuiFormError from '@/components/base-form/mui-form-error'
import { Size } from '@/components/button/types'
import Strings from '@/constants'
import TermsOfService from '@/forms/company-details/terms-of-service'
import services, { endpoints } from '@/helpers/services'
import type { ResetPasswordChangePayload } from '@/redux/api-payloads'

import { LeadJoinSchema } from '../schemas'
import styles from './styles.scss'

enum FieldNames {
  password = 'password',
  confirmPassword = 'confirmPassword',
  tos = 'tos',
}

const initialValues = {
  [FieldNames.password]: '',
  [FieldNames.confirmPassword]: '',
  [FieldNames.tos]: false,
  token: '',
}

type ResetPasswordChangeFormProps = {
  onSubmit: (
    values: ResetPasswordChangePayload,
  ) => ApiPayload<typeof services, ResetPasswordChangePayload>
}

const ResetPasswordChangeForm: React.FC<ResetPasswordChangeFormProps> = ({
  onSubmit,
}: ResetPasswordChangeFormProps) => (
  <BaseForm
    initialValues={initialValues}
    validationSchema={LeadJoinSchema}
    onSubmitPayload={onSubmit}
    formKey={endpoints.postResetPassword}
  >
    <Form>
      <BaseField
        name={FieldNames.password}
        type="password"
        label="Create Password"
        component={TextField}
        fullWidth
        sx={{ mb: 3, boxSizing: 'content-box' }}
      />
      <ErrorMessage name={FieldNames.password}>
        {(msg) => {
          return (
            <Typography variant="body2" color="error" sx={{ mb: 2 }}>
              {msg}
            </Typography>
          )
        }}
      </ErrorMessage>
      <BaseField
        name={FieldNames.confirmPassword}
        type="password"
        label="Confirm Password"
        component={TextField}
        fullWidth
        sx={{ mb: 3, boxSizing: 'content-box' }}
      />
      <ErrorMessage name={FieldNames.confirmPassword}>
        {(msg) => {
          return (
            <Typography variant="body2" color="error" sx={{ mb: 2 }}>
              {msg}
            </Typography>
          )
        }}
      </ErrorMessage>

      <div className={styles.tosRow}>
        <CheckboxField
          name={FieldNames.tos}
          label={<TermsOfService />}
          ariaLabel={Strings.general.tos}
          testId={TestIds.SignUp.TOS}
        />
      </div>
      <MuiFormError formKey={endpoints.postResetPassword} isSupportLinkVisible />
      <div className={styles.buttons}>
        <SubmitButton
          size={Size.large}
          title="Create your account"
          ariaLabel="Create your account"
          className={styles.submitButton}
        />
      </div>
    </Form>
  </BaseForm>
)

export default ResetPasswordChangeForm
