/** @jsx jsx */
import { joinStr } from '@clearsummit/carabiners'
import { jsx } from '@emotion/core'

import jsStyles from './styles'
import styles from './styles.scss'
import PaperProps from './types'

export function Paper({ className, children, testId, ...props }: PaperProps): JSX.Element {
  return (
    <div
      className={joinStr(styles.paper, className)}
      data-testid={testId}
      {...props}
      css={jsStyles.paper(props)}
    >
      {children}
    </div>
  )
}

Paper.defaultProps = {
  variant: 'full-shadow',
  className: undefined,
  onClick: undefined,
  onKeyPress: undefined,
  role: undefined,
  tabIndex: undefined,
  testId: undefined,
}

export default Paper
