/* eslint-disable camelcase */
/* eslint-disable max-lines-per-function */
import { Mixpanel as MixpanelType } from '@partnerslate/core'
import { Company, FieldTypes } from 'partnerslate-models'

const mixpanel = () => {
  const EVENT_NAMES = {
    openApp: 'Open App',
    editProfile: 'Edit Profile',
    viewProfile: 'View Profile',
    viewMorePhotos: 'View More Photos',
    viewOwnProfile: 'View Own Profile',
    viewSearchPage: 'View Search Page',
    search: 'Search',
    useFacilityLocationFilter: 'Use "Facility Location" Filter',
    useManufacturerTypeFilter: 'Use "Manufacturer Type" Filter',
    useProductCategoryFilter: 'Use "Product Category" Filter',
    useProcessingCapabilitiesFilter: 'Use "Processing Capabilities" Filter',
    useCertificationsFilter: 'Use "Certifications" Filter',
    usePackingFormatFilter: 'Use "Packing Format" Filter',
    useProductionRunSizeFilter: 'Use "Product Run Size" Filter',
    useAdditionalServicesFilter: 'Use "Additional Services" Filter',
    viewProjects: 'View Projects',
    viewPremiumSubscriptionPage: 'View Premium Subscription Page',
  }

  const PROPERTIES = {
    profileId: 'Profile ID',
    projectId: 'Project ID',
    comanId: 'Coman Company ID',
    companyName: 'Company Name',
  }

  const GROUP_KEYS = {
    companyId: 'Company ID',
  }

  let mixpanelSharedInstance: MixpanelType

  const initialize = (mp: MixpanelType) => {
    mixpanelSharedInstance = mp
  }

  const call =
    (func: (...args: any) => void) =>
    (...args: unknown[]) => {
      if (STORYBOOK) {
        return
      }

      if (mixpanelSharedInstance) {
        func(...args)
      }
    }

  const openApp = () => {
    mixpanelSharedInstance.track(EVENT_NAMES.openApp)
    mixpanelSharedInstance.people.increment(EVENT_NAMES.openApp)
  }

  const editProfile = () => {
    mixpanelSharedInstance.track(EVENT_NAMES.editProfile)
  }

  const viewProfile = (company: Company) => {
    mixpanelSharedInstance.track(EVENT_NAMES.viewProfile, {
      [PROPERTIES.profileId]: company.id,
      [GROUP_KEYS.companyId]: company.id,
      [PROPERTIES.companyName]: company.companyName,
    })
  }

  const viewMorePhotos = () => {
    mixpanelSharedInstance.track(EVENT_NAMES.viewMorePhotos)
  }

  const viewOwnProfile = () => {
    mixpanelSharedInstance.track(EVENT_NAMES.viewOwnProfile)
  }

  const viewSearchPage = () => {
    mixpanelSharedInstance.track(EVENT_NAMES.viewSearchPage)
  }

  const search = () => {
    mixpanelSharedInstance.track(EVENT_NAMES.search)
    mixpanelSharedInstance.people.increment(EVENT_NAMES.search)
  }

  const searchFilter = (filter: string) => {
    switch (filter) {
      case FieldTypes.manufacturerType:
        mixpanelSharedInstance.track(EVENT_NAMES.useManufacturerTypeFilter)
        break
      case FieldTypes.facilityLocation:
        mixpanelSharedInstance.track(EVENT_NAMES.useFacilityLocationFilter)
        break
      case FieldTypes.productCategories:
        mixpanelSharedInstance.track(EVENT_NAMES.useProductCategoryFilter)
        break
      case FieldTypes.packagingFormats:
        mixpanelSharedInstance.track(EVENT_NAMES.usePackingFormatFilter)
        break
      case FieldTypes.processingCapabilities:
        mixpanelSharedInstance.track(EVENT_NAMES.useProcessingCapabilitiesFilter)
        break
      case FieldTypes.certifications:
        mixpanelSharedInstance.track(EVENT_NAMES.useCertificationsFilter)
        break
      case FieldTypes.productionRunSize:
        mixpanelSharedInstance.track(EVENT_NAMES.useProductionRunSizeFilter)
        break
      case FieldTypes.additionalServices:
        mixpanelSharedInstance.track(EVENT_NAMES.useAdditionalServicesFilter)
        break
      default:
        break
    }
  }

  const viewProjects = () => {
    mixpanelSharedInstance.track(EVENT_NAMES.viewProjects)
  }

  const viewPremiumSubscriptionPage = () => {
    mixpanelSharedInstance.track(EVENT_NAMES.viewPremiumSubscriptionPage)
  }

  return {
    initialize,
    openApp: call(openApp),
    editProfile: call(editProfile),
    viewProfile: call(viewProfile),
    viewMorePhotos: call(viewMorePhotos),
    viewOwnProfile: call(viewOwnProfile),
    viewSearchPage: call(viewSearchPage),
    search: call(search),
    searchFilter: call(searchFilter),
    viewProjects: call(viewProjects),
    viewPremiumSubscriptionPage: call(viewPremiumSubscriptionPage),
  }
}

export default mixpanel()
