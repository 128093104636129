import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box } from '@mui/material'
import React from 'react'

import { Engagement, TodoKeysType } from '@/domain/engagements'

import {
  BenchtopSamples,
  Contract,
  IngredientProcurement,
  ProductionRun,
  ReceiveQuote,
  ScheduleIntroCall,
  SendSamples,
  ShareSupportingDocuments,
  SiteVisit,
  TrialRun,
} from './brand'
import {
  ReviewAdditionalShareDocuments,
  ScheduleIntroCall as ComanScheduleIntroCall,
} from './coman'

export type TodoContent = {
  key: TodoKeysType
  title: string
  summary?: string
  expandedContent: JSX.Element
}

export const BRAND_TODOS: TodoContent[] = [
  {
    key: 'schedule-intro-call',
    title: 'Schedule on-boarding call',
    expandedContent: <ScheduleIntroCall />,
  },
  {
    key: 'share-supporting-documents',
    title: 'Share your documents',
    expandedContent: <ShareSupportingDocuments />,
  },
  {
    key: 'send-samples',
    title: 'Send Samples',
    expandedContent: <SendSamples />,
  },
  {
    key: 'site-visit',
    title: 'Site Visit',
    expandedContent: <SiteVisit />,
  },
  {
    key: 'benchtop-samples',
    title: 'Benchtop Samples',
    expandedContent: <BenchtopSamples />,
  },
  {
    key: 'trial-run',
    title: 'Trial Run',
    expandedContent: <TrialRun />,
  },
  {
    key: 'receive-quote',
    title: 'Receive Quote',
    expandedContent: <ReceiveQuote />,
  },
  {
    key: 'ingredient-rocurement',
    title: 'Ingredient Procurement',
    expandedContent: <IngredientProcurement />,
  },
  {
    key: 'contract',
    title: 'Contract',
    expandedContent: <Contract />,
  },
  {
    key: 'production-run',
    title: 'Production Run',
    expandedContent: <ProductionRun />,
  },
]

export const COMAN_TODOS: TodoContent[] = [
  {
    key: 'review-additional-shared-documents',
    title: 'Review Additional Shared Documents',
    summary:
      'We suggest you review any documents they brand supplied (product formula, photos, etc.) in preparation of your intro call.',
    expandedContent: <ReviewAdditionalShareDocuments />,
  },
  {
    key: 'schedule-intro-call',
    title: 'Schedule an Intro Call',
    summary:
      "Once you've reviewed the shared documents, it's time to schedule an introductory call with the brand.",
    expandedContent: <ComanScheduleIntroCall />,
  },
]

export const summaryContentFor = (todo: TodoContent): React.ReactNode => {
  return todo.summary || todo.expandedContent
}

export const allTodosFor = (engagement: Engagement): TodoContent[] =>
  engagement.perspective === 'brand' ? BRAND_TODOS : COMAN_TODOS

type ItemWithIcon = {
  text: string
}

export function ItemWithIcon({ text }: ItemWithIcon): JSX.Element {
  return (
    <Box display="flex" alignItems="center">
      {text} <InfoOutlinedIcon fontSize="small" color="primary" sx={{ pl: 0.5, display: 'none' }} />
    </Box>
  )
}
