import { joinStr } from '@clearsummit/carabiners'
import { ApiPayload } from '@partnerslate/radio-dispatch'
import type { CompanyPayload } from 'partnerslate-models'
import { Company } from 'partnerslate-models'
import React from 'react'

import Strings from '@/constants'
import {
  clearStateIfAppropriate,
  defaultCountryToUsaIfUnspecified,
  domainAddressFromModelAddress,
  domainAddressToModelAddress,
} from '@/domain/address'
import { UpdateCompanyForm } from '@/forms'
import { FieldNames } from '@/forms/schemas'
import services from '@/helpers/services'
import { CompanyResponse } from '@/redux/api-payloads'

import styles from './styles.scss'

interface CompanyInfoProps {
  submit: (
    data: Company,
  ) => ApiPayload<typeof services, CompanyPayload, CompanyResponse | undefined>
  company: Company
}

export function CompanyDetails({ submit, company }: CompanyInfoProps): JSX.Element {
  const initialAddress = defaultCountryToUsaIfUnspecified(
    domainAddressFromModelAddress(company.address),
  )
  return (
    <div className={joinStr(styles.row, styles.flex)}>
      <h1>{Strings.profile.companyDetails}</h1>
      <h6 className={styles.companyType}>{Strings.profile.companyType}</h6>
      <span className={styles.companyTypeValue}>{Strings.profile[company.companyType]}</span>
      <UpdateCompanyForm
        handleSubmit={(data) => {
          const cleanedAddress = domainAddressToModelAddress(clearStateIfAppropriate(data.address))

          const newCompany = new Company({ ...company })
          newCompany.companyName = data.companyName
          newCompany.address = cleanedAddress
          newCompany.companyPhone = data.companyPhone
          newCompany.isPublic = data.isPublic
          return submit(newCompany)
        }}
        initialValues={{
          [FieldNames.companyName]: company.companyName,
          [FieldNames.companyPhone]: company.companyPhone,
          isPublic: company.isPublic,
          address: initialAddress,
        }}
      />
    </div>
  )
}

export default CompanyDetails
