import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Box,
  Checkbox,
  Typography,
} from '@mui/material'
import { User } from 'partnerslate-models'
import React, { useEffect, useState } from 'react'

import { Engagement, isTodoCompleted } from '@/domain/engagements'
import { instrumentation } from '@/helpers'
import { useUpdateTodoState } from '@/helpers/services/queries'

import { TodoContent } from './todo-content'

type Props = {
  engagement: Engagement
  todo: TodoContent
  children: React.ReactNode
  isExpanded?: boolean
  user?: User
}
export default function Todo({ todo, children, engagement, isExpanded, user }: Props): JSX.Element {
  const [completed, setCompleted] = useState(false)
  const updateTodoState = useUpdateTodoState()

  const handleClick = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.preventDefault()
    // stop the event so that the open/close state of the accordeaon doesn't change when clicking the checkbox
    e.stopPropagation()

    const newValue = !completed
    setCompleted(newValue)
    instrumentation.checkedToDo(engagement, todo.key, newValue, user)
    updateTodoState.mutate({
      engagementId: engagement.id,
      todoKey: todo.key,
      completed: newValue,
    })
  }

  useEffect(() => {
    setCompleted(isTodoCompleted(engagement, todo.key))
  }, [engagement, engagement.completedTodos, todo.key])

  const textDecoration = completed ? 'line-through' : 'normal'

  return (
    <Accordion>
      <AccordionSummary expandIcon={<ExpandMoreIcon />} id={todo.key}>
        <Box sx={{ display: 'flex', alignItems: 'center' }}>
          <Checkbox edge="start" disableRipple checked={completed} onClick={handleClick} />
          <Typography
            variant="subtitle1"
            color="text.primary"
            {...(isExpanded ? { fontWeight: '700' } : {})}
            sx={{ textDecoration }}
          >
            {todo.title}
          </Typography>
        </Box>
      </AccordionSummary>
      <AccordionDetails>{children}</AccordionDetails>
    </Accordion>
  )
}
