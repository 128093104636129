import { AsYouType } from 'libphonenumber-js'
import React, { useEffect, useState } from 'react'

import { Input } from '@/components'
import Strings from '@/constants'

import { InputProps } from '.'

function PhoneField({ name, onChange, value, ...rest }: InputProps): JSX.Element {
  const getMasked = (eventValue: string) => {
    const asYouType = new AsYouType()
    const masked = asYouType.input(eventValue)
    // @ts-ignore
    return masked
  }
  const [phoneNumber, setPhoneNumber] = useState(value || '')
  useEffect(() => {
    if (value) {
      setPhoneNumber(getMasked(value))
    }
  }, [value])
  const _setPhoneNumber = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value: eventValue } = e.target
    const masked = getMasked(eventValue)
    // @ts-ignore
    e.target.value = masked.replaceAll(' ', '')
    setPhoneNumber(masked)
    if (onChange) {
      onChange(e)
    }
  }
  return (
    <Input
      type="text"
      {...rest}
      value={phoneNumber}
      placeholder={Strings.general.phoneFormat}
      name={name}
      onChange={_setPhoneNumber}
    />
  )
}

PhoneField.defaultProps = Input.defaultProps

export default PhoneField
