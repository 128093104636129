import './stripe.css'

import { CardCvcElement, CardExpiryElement, CardNumberElement } from '@stripe/react-stripe-js'
import {
  StripeCardCvcElementChangeEvent,
  StripeCardExpiryElementChangeEvent,
  StripeCardNumberElementChangeEvent,
} from '@stripe/stripe-js'
import React, { useState } from 'react'

import styleVariables from '../../../styles/variables.scss'
import InputError from './input-error'
import InputLabel from './input-label'
import styles from './styles.scss'

const { middleGrey, standardFont } = styleVariables

const options = {
  style: {
    base: {
      fontSize: standardFont,
      '::placeholder': {
        color: middleGrey,
        fontSize: standardFont,
      },
    },
  },
}

interface Props {
  label: string
  name: string
  id: string
  onChange: (complete: boolean) => void
}

interface PartialProps {
  required: boolean
}

export function StripeCardNumberElement({
  label,
  name,
  id,
  onChange,
  required = false,
}: Props & Partial<PartialProps>): JSX.Element {
  const [error, setError] = useState<string | undefined>(undefined)
  return (
    <div className={styles.inputContainer}>
      <InputLabel label={label} inputName={name} required={required} />
      <CardNumberElement
        className={styles.baseInput}
        options={options}
        id={id}
        onChange={(e: StripeCardNumberElementChangeEvent) => {
          onChange(e.complete)
          setError(e.error?.message)
        }}
      />
      <InputError error={error} />
    </div>
  )
}

export function StripeCardExpiryElement({
  label,
  name,
  onChange,
  id,
  required = false,
}: Props & Partial<PartialProps>): JSX.Element {
  const [error, setError] = useState<string | undefined>(undefined)
  return (
    <div className={styles.inputContainer}>
      <InputLabel label={label} inputName={name} required={required} />
      <CardExpiryElement
        id={id}
        options={options}
        className={styles.baseInput}
        onChange={(e: StripeCardExpiryElementChangeEvent) => {
          onChange(e.complete)
          setError(e.error?.message)
        }}
      />
      <InputError error={error} />
    </div>
  )
}
export function StripeCardCvcElement({
  label,
  name,
  onChange,
  id,
  required = false,
}: Props & Partial<PartialProps>): JSX.Element {
  const [error, setError] = useState<string | undefined>(undefined)
  return (
    <div className={styles.inputContainer}>
      <InputLabel label={label} inputName={name} required={required} />
      <CardCvcElement
        id={id}
        options={options}
        className={styles.baseInput}
        onChange={(e: StripeCardCvcElementChangeEvent) => {
          onChange(e.complete)
          setError(e.error?.message)
        }}
      />
      <InputError error={error} />
    </div>
  )
}
