import { joinStr } from '@clearsummit/carabiners'
import { Image } from 'partnerslate-models'
import React, { useState } from 'react'

import { ActionButton, AddImage, Loading, PhotoLightbox } from '@/components'
import Strings, { Images, Metrics } from '@/constants'
import mixpanel from '@/helpers/mixpanel'

import styles from './styles.scss'

const MAX_PHOTOS = 5

type deleteMetaType = { id: string | undefined; pending: boolean }

interface PhotosProps {
  deleteMeta: deleteMetaType
  deleteImage: (id: string) => void
  editing: boolean
  onClick: (image: File) => void
  photos: Array<Image>
  pending: boolean
}

function Photos({
  editing,
  onClick,
  photos,
  pending,
  deleteImage,
  deleteMeta,
}: PhotosProps): JSX.Element {
  const [lightboxOpen, setLightboxOpen] = useState(false)
  const [photoIndex, setPhotoIndex] = useState(0)

  const handleClose = () => {
    setLightboxOpen(false)
  }

  const handleOpenPhoto = (index: number) => {
    setPhotoIndex(index)
    setLightboxOpen(true)
    mixpanel.viewMorePhotos()
  }

  const isDeletingPhoto = (p: Image, meta: deleteMetaType) => meta.pending && p.id === meta.id

  return (
    <>
      <PhotoLightbox
        photos={photos}
        initialPhoto={photoIndex}
        close={handleClose}
        visible={lightboxOpen && !editing}
      />
      {photos.length > 0 || editing ? <h3>{Strings.profile.morePhotos}</h3> : null}
      <div className={styles.photos}>
        {photos.map((p: Image, idx: number) =>
          isDeletingPhoto(p, deleteMeta) ? (
            <div key={p.id} className={joinStr(styles.morePhotos, styles.deleting)}>
              <Loading />
            </div>
          ) : (
            <div key={p.id} className={styles.morePhotos}>
              {editing && (
                <ActionButton
                  className={styles.trashcan}
                  icon={Images.trashcan}
                  onClick={() => deleteImage(p.id)}
                  ariaLabel={Strings.profile.deleteBanner}
                  iconAlt={Strings.profile.deleteBanner}
                />
              )}
              <button type="button" onClick={() => handleOpenPhoto(idx)}>
                <img
                  className={joinStr(styles.profileImage, styles.morePhoto)}
                  src={p.url}
                  alt={p.name}
                />
              </button>
            </div>
          ),
        )}
        {editing && photos.length < MAX_PHOTOS && (
          <div
            className={joinStr(styles.profileImage, styles.addProfileImageBtn, styles.morePhoto)}
          >
            <AddImage onClick={onClick} minSize={Metrics.minMoreSize} pending={pending}>
              <img src={Images.plusCircle} alt={Strings.profile.addImage} />
            </AddImage>
          </div>
        )}
      </div>
    </>
  )
}

export default Photos
