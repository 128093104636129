import React from 'react'

import Strings from '@/constants'

import type { Props } from './default-modal'
import DefaultModal from './default-modal'

function UnsavedChangesModal({ successCallback, failCallback, close }: Props): JSX.Element {
  return (
    <DefaultModal
      title={Strings.forms.changesNotSaved.title}
      paragraphs={[Strings.forms.changesNotSaved.subtitle]}
      successText={Strings.forms.changesNotSaved.saveAndContinueButton}
      successCallback={successCallback}
      failText={Strings.forms.changesNotSaved.continueWithoutSavingButton}
      failCallback={failCallback}
      close={close}
    />
  )
}

export default UnsavedChangesModal
