export default {
  Login: {
    Username: 'Login/Username',
    Password: 'Login/Password',
    Submit: 'Login/Submit',
  },
  SignUp: {
    Submit: 'SignUp/Submit',
    FirstName: 'Signup/FirstName',
    LastName: 'Signup/LastName',
    Email: 'Signup/Email',
    Password: 'Signup/Password',
    ConfirmPassword: 'Signup/ConfirmPassword',
    JobTitle: 'Signup/JobTitle',
    CompanyName: 'Signup/CompanyName',
    CompanyPhone: 'Signup/CompanyPhone',
    TOS: 'Signup/TOS',
  },
  VerifyEmail: {
    Submit: 'VerifyEmail/Submit',
  },
  Profile: {
    YourInfo: 'Profile/YourInfo',
    ChangePassword: 'Profile/ChangePassword',
    CompanyDetails: 'Profile/CompanyDetails',
    Subscriptions: 'Profile/Subscriptions',
    PaymentSettings: 'Profile/PaymentSettings',
    NameOnCard: 'Profile/NameOnCard',
    CardNumber: 'Profile/CardNumber',
    CardExpiration: 'Profile/CardExpiration',
    CVC: 'Profile/CVC',
  },
  User: {
    Password: 'User/Password',
    CurrentPassword: 'User/CurrentPassword',
    ConfirmPassword: 'User/ConfirmPassword',
    Submit: 'User/Submit',
  },
  Projects: {
    CreateNewProject: 'Projects/CreateNewProject',
    Submit: 'Projects/Submit',
    Inputs: {
      PackgingFormat: 'Projects/Inputs/PackagingFormat',
      SpecialEquipmentNeeded: 'Projects/Inputs/SpecialEquipmentNeeded',
      RAndDNeeded: 'Projects/Inputs/RAndDNeeded',
      Allergens: 'Projects/Inputs/Allergens',
      AdditionalNotes: 'Projects/Inputs/AdditionalNotes',
      ProjectName: 'Projects/Inputs/ProjectName',
      ProjectCategory: 'Projects/Inputs/ProjectCategory',
      Skus: 'Projects/Inputs/Skus',
      CertificationRequirements: 'Projects/Inputs/CertificationRequirements',
      ProductStatus: 'Projects/Inputs/ProductStatus',
      ProjectDescription: 'Projects/Inputs/ProjectDescription',
      AnnualVolume: 'Projects/Inputs/AnnualVolume',
      RunSize: 'Projects/Inputs/RunSize',
      MSRP: 'Projects/Inputs/MSRP',
      Summary: 'Projects/Inputs/Summary',
    },
  },
  Company: {
    CompanyCard: 'Company/Card',
    IsProfilePublic: 'Company/IsProfilePublic',
  },
}
