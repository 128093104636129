import { joinStr } from '@clearsummit/carabiners'
import React from 'react'

import Strings from '@/constants'
import { ChangePasswordForm } from '@/forms'
import { ChangePasswordFormValues } from '@/forms/change-password'

import styles from './styles.scss'

interface ChangePasswordProps {
  submit: (data: ChangePasswordFormValues) => void
}

export function ChangePassword({ submit }: ChangePasswordProps): JSX.Element {
  return (
    <div className={joinStr(styles.row, styles.flex)}>
      <h1>{Strings.profile.changePassword}</h1>
      <ChangePasswordForm handleSubmit={submit} />
    </div>
  )
}

export default ChangePassword
