/* eslint-disable no-shadow */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable max-lines-per-function */
import { Formik, FormikProps } from 'formik'
import * as React from 'react'

import TestIds from '@/accessibility/test-ids'
import { SubmitButton } from '@/components/base-form/buttons'
import { PasswordField, TextField } from '@/components/base-form/fields'
import FormError from '@/components/base-form/form-error'
import Strings from '@/constants'

import { SignUpPersonalInformationSchema } from '../schemas'
import styles from './styles.scss'

enum FieldNames {
  email = 'email',
  password = 'password',
  confirmPassword = 'confirmPassword',
  firstName = 'firstName',
  lastName = 'lastName',
  jobTitle = 'jobTitle',
}

export interface SignUpFormValues {
  [FieldNames.email]: string
  [FieldNames.password]: string
  [FieldNames.confirmPassword]: string
  [FieldNames.firstName]: string
  [FieldNames.lastName]: string
  [FieldNames.jobTitle]: string
}

interface SignUpFormProps {
  onSubmit: (data: SignUpFormValues) => void
  initialValues: SignUpFormValues
  error: string | null
}

const SignUpTeamInviteForm: React.FC<SignUpFormProps> = (formProps) => {
  const { onSubmit, initialValues, error } = formProps

  const formRef = React.useRef<FormikProps<SignUpFormValues>>(null)
  React.useEffect(() => {
    formRef?.current?.setValues(initialValues, true)
  }, [initialValues])

  return (
    <Formik
      initialValues={{} as SignUpFormValues}
      innerRef={formRef}
      onSubmit={onSubmit}
      validationSchema={SignUpPersonalInformationSchema}
      validateOnChange
      validateOnMount
    >
      <>
        <div className={styles.signUpRow}>
          <div className={styles.smallInput}>
            <TextField
              name={FieldNames.email}
              label={Strings.auth.signup.inputEmail}
              placeholder={Strings.auth.signup.email}
              testId={TestIds.SignUp.Email}
              disabled
            />
          </div>
          <div className={styles.smallInput}>
            <TextField
              name={FieldNames.firstName}
              label={Strings.auth.signup.firstName}
              placeholder={Strings.auth.signup.enterFirstName}
              testId={TestIds.SignUp.FirstName}
              required
            />
          </div>
          <div className={styles.smallInput}>
            <TextField
              name={FieldNames.lastName}
              label={Strings.auth.signup.lastName}
              placeholder={Strings.auth.signup.enterLastName}
              testId={TestIds.SignUp.LastName}
              required
            />
          </div>
        </div>
        <div className={styles.signUpRow}>
          <div className={styles.smallInput}>
            <TextField
              name={FieldNames.jobTitle}
              label={Strings.auth.signup.jobTitle}
              placeholder={Strings.auth.signup.enterJobTitle}
              testId={TestIds.SignUp.JobTitle}
            />
          </div>
          <div className={styles.smallInput}>
            <PasswordField
              name={FieldNames.password}
              label={Strings.auth.signup.inputPassword}
              placeholder={Strings.auth.signup.enterPassword}
              testId={TestIds.SignUp.Password}
              required
            />
          </div>
          <div className={styles.smallInput}>
            <PasswordField
              name={FieldNames.confirmPassword}
              label={Strings.auth.signup.inputPasswordAgain}
              placeholder={Strings.auth.signup.enterPasswordAgain}
              testId={TestIds.SignUp.ConfirmPassword}
              required
            />
          </div>
        </div>
        <FormError className={styles.error} error={error} formKey="IGNORED" />
        <SubmitButton title="Get Started" ariaLabel="Get Started" testId={TestIds.SignUp.Submit} />
      </>
    </Formik>
  )
}

export default SignUpTeamInviteForm
