import { css, SerializedStyles } from '@emotion/core'

import styles from '../../../styles/variables.scss'

const { buttonHeight } = styles

const Styles = {
  submit: (dirty: boolean): SerializedStyles =>
    css`
      ${dirty
        ? `
    transform: translateY(-calc(${buttonHeight} * 2));
    `
        : `
    transform: translateY(calc(${buttonHeight} * 2));
  `}
    `,
}

export default Styles
