import React from 'react'

import Strings from '@/constants'

import styles from './styles.scss'

function DefaultEmptyComponent(): JSX.Element {
  return (
    <div className={styles.empty}>
      <h1>{Strings.search.sorry}</h1>
      <span>{Strings.search.cantFindPartners}</span>
      <span>{Strings.search.tryAgain}</span>
    </div>
  )
}

export default DefaultEmptyComponent
