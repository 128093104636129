import React from 'react'

import { Input } from '@/components'

import { InputProps } from '.'

function PasswordInput({ name, placeholder, onChange, ...rest }: InputProps): JSX.Element {
  return (
    <Input {...rest} type="password" placeholder={placeholder} name={name} onChange={onChange} />
  )
}

PasswordInput.defaultProps = Input.defaultProps

export default PasswordInput
