class S3Service {
  uploadFile = ({
    presignedUrl,
    file,
  }: {
    presignedUrl: string
    file: File
  }): Promise<{ status: number; data: Record<string, unknown> }> =>
    new Promise((resolve, reject) => {
      // eslint-disable-next-line no-undef
      const xhr = new XMLHttpRequest()
      xhr.onreadystatechange = function onReadyStateChange() {
        if (xhr.readyState === 4) {
          if (xhr.status === 200) {
            resolve({ status: xhr.status, data: xhr.response.data })
          } else {
            // eslint-disable-next-line no-console
            console.error(xhr)
            reject(new Error('Failed to upload presignedurl'))
          }
        }
      }
      xhr.open('PUT', presignedUrl)
      // for text file: text/plain, for binary file: application/octet-stream
      xhr.setRequestHeader('Content-Type', file.type)
      xhr.send(file)
    })
}

export default new S3Service()
