import { ACTIONS as RDActions, createNetworkSagas } from '@partnerslate/radio-dispatch'
import { all, spawn, takeEvery, takeLatest } from 'redux-saga/effects'

import services from '@/helpers/services'
import { ACTIONS as COMPANY_ACTIONS } from '@/redux/company'
import { ACTIONS as USER_ACTIONS } from '@/redux/user'

import * as api from './api'
import * as company from './company'
import * as user from './user'

export { user }

export default function* root(): GeneratorType {
  yield spawn(function* run() {
    yield all(createNetworkSagas(services))
  })

  yield spawn(function* run() {
    yield all([
      // Api sagas
      takeEvery(RDActions.END_REQUEST, api.endRequest),
      takeLatest(USER_ACTIONS.SET_USER_SESSION, user.setUserSession),
      takeLatest(USER_ACTIONS.RESET_PASSWORD_CHANGE_SUCCESS, user.resetPasswordSuccess),
      takeLatest(USER_ACTIONS.HYDRATE_USER_SUCCESS, user.setUserSession),
      takeLatest(USER_ACTIONS.HYDRATE_USER_FAILURE, user.logout),
      takeLatest(USER_ACTIONS.LOGOUT, user.logout),
      takeLatest(USER_ACTIONS.DELETE_ME_SUCCESS, user.logout),
      takeLatest(USER_ACTIONS.VERIFY_EMAIL_SUCCESS, user.verifyEmailSuccess),
      takeLatest(USER_ACTIONS.UPLOAD_PHOTO, user.uploadPhoto),
      takeLatest(COMPANY_ACTIONS.OPEN_STRIPE_SESSION, company.openStripeSession),
    ])
  })

  yield spawn(user.startup)
}
