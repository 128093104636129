import { FieldOption } from 'partnerslate-models'
import React, { Fragment, useState } from 'react'

import { Checkbox, SearchInput } from '@/components'
import Strings from '@/constants'

import styles from './styles.scss'

type Props = {
  options: Array<FieldOption>
  name: string
  ariaLabel: string
  onChange: (
    e:
      | React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>
      | boolean
      | FieldOption
      | Array<FieldOption>,
  ) => void
  value: Array<FieldOption>
}

function CheckboxArray(props: Props): JSX.Element {
  const { name, options, ariaLabel, onChange, value } = props
  const [searchValue, setSearchValue] = useState('')

  const filteredOptions = searchValue
    ? options.filter((opt) => opt.label.toLowerCase().includes(searchValue.toLowerCase()))
    : options
  const groups = new Set<string>()
  filteredOptions.forEach((opt) => opt.group && groups.add(opt.group.name))

  const onChangeField = (val: FieldOption, checkboxValue: boolean): void => {
    if (Array.isArray(value)) {
      if (checkboxValue) {
        const newValues = [...value, val]
        onChange(newValues)
      } else {
        onChange(value.filter((v) => v.value !== val.value))
      }
    }
  }

  const renderGroups = () => (
    <div className={styles.checkboxesArea}>
      {Array.from(groups).map((groupName) => (
        <Fragment key={groupName}>
          <div className={styles.group}>
            <span>{groupName}</span>
          </div>
          {filteredOptions.map((opt) => {
            const checkboxName = `${name}-${opt.id}`
            if (opt.group && opt.group.name === groupName) {
              return (
                <Checkbox
                  key={checkboxName}
                  label={opt.label}
                  name={checkboxName}
                  ariaLabel={ariaLabel}
                  value={value.some((v) => v.id === opt.id)}
                  onChange={(v: boolean) => onChangeField(opt, v)}
                />
              )
            }
            return null
          })}
        </Fragment>
      ))}
    </div>
  )
  const renderCheckboxes = () => (
    <div className={styles.checkboxesArea}>
      {filteredOptions.map((opt) => {
        const checkboxName = `${name}-${opt.id}`
        return (
          <Checkbox
            key={checkboxName}
            label={opt.label}
            name={checkboxName}
            ariaLabel="ariaLabel"
            value={value.some((v) => v.id === opt.id)}
            onChange={(v: boolean) => onChangeField(opt, v)}
          />
        )
      })}
    </div>
  )

  return (
    <>
      <div className={styles.search}>
        <SearchInput
          placeholder={Strings.general.search}
          name={name}
          onChange={(e) => setSearchValue(e.target.value)}
        />
      </div>
      {groups ? renderGroups() : renderCheckboxes()}
    </>
  )
}

export default CheckboxArray
