import React from 'react'

import styles from './styles.scss'

type Props = {
  text: string
}

function BlueLabel(props: Props): JSX.Element {
  const { text } = props
  return <span className={styles.label}>{text}</span>
}

export default BlueLabel
