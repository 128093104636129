import { FieldOption, FieldTypes } from 'partnerslate-models'
import React from 'react'

import { CheckboxArrayField, NumberField, RadioInputField } from '@/components/base-form/fields'
import { getFieldOptions } from '@/helpers/referenceData'

import styles from './styles.scss'

interface FieldEditorProps {
  field: FieldTypes
}

const SINGLE_OPTION_FIELDS = [FieldTypes.brandStage]

function CheckboxesWrapper({
  field,
  fieldOptions,
}: {
  field: FieldTypes
  fieldOptions: FieldOption[]
}) {
  return (
    <div className={styles.checkboxes}>
      <CheckboxArrayField name={field} ariaLabel="label" options={fieldOptions} />
    </div>
  )
}

function FieldEditor({ field }: FieldEditorProps): JSX.Element {
  if (field === FieldTypes.numberOfFacilityLocations) {
    return (
      <div className={styles.editor}>
        <NumberField
          label="Number of facility locations"
          name="number_of_facility_locations"
          ariaLabel="number of facility locations"
          placeholder="number of facility location"
        />
      </div>
    )
  }

  const fieldOptions = getFieldOptions(field)

  return (
    <div className={styles.editor}>
      {SINGLE_OPTION_FIELDS.includes(field) ? (
        <RadioInputField name={field} options={fieldOptions} />
      ) : (
        <CheckboxesWrapper field={field} fieldOptions={fieldOptions} />
      )}
    </div>
  )
}

export default FieldEditor
