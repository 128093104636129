import { selectors as apiSelectors } from '@partnerslate/radio-dispatch'

import { EndpointsWithIgnored } from '@/helpers/services'
import { StoreState } from '@/redux'
// Imported Api selectors
// getError: Get error string by key
// getPending: Get pending boolean by key
// getMeta: Get set request meta object by key
const selectors = {
  getError: (store: StoreState, serviceKey: EndpointsWithIgnored): ErrorDetailsType | null => {
    const error = apiSelectors.getError(store, serviceKey)
    if (error) {
      return error.details
    }
    return null
  },
  getPending: apiSelectors.getPending,
  getMeta: apiSelectors.getMeta,
}

export default selectors
