import { Link as MuiLink, Typography } from '@mui/material'
import { DataGrid as MuiDataGrid, GridColDef, GridEventListener } from '@mui/x-data-grid'
import React from 'react'
import { Link } from 'react-router-dom'

import type { NonVisibleColumns } from './useNonVisibleColumns'
import { useNonVisibleColumns } from './useNonVisibleColumns'

type DataGridProps<T> = {
  columns: GridColDef<any>[]
  nonVisibleColumns: NonVisibleColumns
  rows: T[]
  onRowClick?: GridEventListener<'rowClick'> | undefined
}
export function DataGrid<T>({
  columns,
  nonVisibleColumns,
  rows,
  onRowClick,
}: DataGridProps<T>): JSX.Element {
  const nonVisibleCols = useNonVisibleColumns(nonVisibleColumns)

  return (
    <MuiDataGrid
      autoHeight
      columnVisibilityModel={nonVisibleCols}
      columns={columns}
      hideFooter
      onRowClick={onRowClick}
      rowHeight={84}
      rows={rows}
      sx={(theme) => ({
        backgroundColor: theme.palette.background.paper,
        my: 2,
        '.MuiChip-label': {
          color: 'inherit',
        },
        '.MuiDataGrid-cell, .MuiDataGrid-columnHeader': {
          pl: 2,
        },
        '.MuiDataGrid-cell:focus, .MuiDataGrid-columnHeader:focus': {
          outline: 'none',
        },
        '.MuiDataGrid-cell:first-child': {
          pl: { xs: 2, lg: 6 },
        },
        '.MuiDataGrid-columnHeader:first-child, .MuiDataGrid-cell:first-child': {
          pl: { xs: 2, md: 6 },
        },
        '.MuiDataGrid-columnHeaderTitle': {
          fontWeight: 'bold',
        },
        '.MuiDataGrid-columnSeparator': {
          display: 'none',
        },
        '.MuiDataGrid-row:hover': {
          backgroundColor: 'primary.mainlightestOpacity',
          cursor: onRowClick ? 'pointer' : 'auto',
        },
      })}
    />
  )
}

interface ColumnCellProps {
  color?: string | undefined
  link?: string | undefined
  value: string
}
export function ColumnCell({ color, link, value }: ColumnCellProps): JSX.Element {
  return (
    <Typography
      color={color}
      variant="body2"
      sx={{ whiteSpace: 'normal', wordWrap: 'break-word', inlineSize: 250 }}
    >
      {link ? (
        <MuiLink component={Link} to={link}>
          {value}
        </MuiLink>
      ) : (
        value
      )}
    </Typography>
  )
}
