import { CropArea } from '@/components/image-cropper'

export const fileToBase64 = (file: File): Promise<string | ArrayBuffer | null | undefined> =>
  new Promise((resolve) => {
    const reader = new FileReader()
    reader.onload = (event) => {
      resolve(event.target?.result)
    }
    reader.readAsDataURL(file)
  })

export const createImage = async (file: File): Promise<HTMLImageElement | undefined> => {
  const base64 = await fileToBase64(file)
  if (typeof base64 === 'string') {
    return new Promise((resolve, reject) => {
      const image = new Image()
      image.addEventListener('load', () => resolve(image))
      image.addEventListener('error', (error) => reject(error))
      image.setAttribute('crossOrigin', 'anonymous') // needed to avoid cross-origin issues on CodeSandbox
      image.src = base64
    })
  }
  return undefined
}

export const getCroppedImg = async (
  imageFile: File,
  crop: CropArea,
): Promise<{ blob: Blob; filename: string } | undefined> => {
  const image = await createImage(imageFile)
  if (image) {
    const canvas = document.createElement('canvas')
    const scaleX = image.naturalWidth / image.width
    const scaleY = image.naturalHeight / image.height
    canvas.width = crop.width
    canvas.height = crop.height
    const ctx = canvas.getContext('2d')
    if (ctx) {
      ctx.fillStyle = 'white'
      ctx.fillRect(0, 0, canvas.width, canvas.height)
      ctx.drawImage(
        image,
        crop.x * scaleX,
        crop.y * scaleY,
        crop.width * scaleX,
        crop.height * scaleY,
        0,
        0,
        crop.width,
        crop.height,
      )
    }

    // As Base64 string
    // const base64Image = canvas.toDataURL('image/jpeg');

    // As a blob
    return new Promise((resolve) => {
      canvas.toBlob(
        (blob) => {
          if (blob) {
            resolve({ blob, filename: imageFile.name })
          }
        },
        'image/jpeg',
        1,
      )
    })
  }
  return undefined
}
