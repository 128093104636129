import { instrumentation } from '@partnerslate/core'
import { User } from 'partnerslate-models'

import {
  displayInactiveState,
  Engagement,
  EngagementInactiveStateKey,
  EngagementStatusDisplay,
  TodoKeysType,
} from '@/domain/engagements'

const { mixpanelTrack } = instrumentation

declare global {
  interface Window {
    Intercom: any
  }
}

const sendIntercomEvent = (eventName: string, metadata: Record<string, string | number> = {}) => {
  if (!window.Intercom) {
    console.warn('no Intercom available')
    return
  }

  window.Intercom('trackEvent', eventName, metadata)
}

export const viewedPage = (pageUrl: string): void => {
  mixpanelTrack('View Site Page', { 'Page Url': pageUrl })
}

export const logout = (): void => {
  mixpanelTrack('Logout')
}

export const clickedGetMatchedCTA = (projectId: string): void => {
  mixpanelTrack('Click Get Matched CTA', { 'Project ID': projectId })
}

export const viewedEngagementsForProject = (projectId: string): void => {
  mixpanelTrack('View Project Engagements', { 'Project ID': projectId })
}

export const viewedEngagementsForCompany = (): void => {
  mixpanelTrack('View All Engagements')
}

export const viewedEngagementDetails = (engagementId: string): void => {
  mixpanelTrack('View Engagement Details', { 'Engagement ID': engagementId })
}

export const viewedProjectDetailsModal = (projectId: string): void => {
  mixpanelTrack('View Project Details Modal', { 'Project ID': projectId })
}

export const viewedNDA = (engagementId: string): void => {
  mixpanelTrack('View NDA', { 'Engagement ID': engagementId })
}

export const signedNDA = (engagementId: string): void => {
  mixpanelTrack('Sign NDA', { 'Engagement ID': engagementId })
}

export const downloadedNDA = (engagementId: string): void => {
  mixpanelTrack('Download NDA', { 'Engagement ID': engagementId })
}

export const addedFilesToBox = (engagementId: string, fileIds: Array<string>): void => {
  fileIds.forEach((fileId: string) => {
    mixpanelTrack('Add File to Shared Docs', { 'Engagement ID': engagementId, 'File ID': fileId })
  })
}

export const viewedFileInBox = (engagementId: string, fileId: string): void => {
  mixpanelTrack('Viewed File in Shared Docs', { 'Engagement ID': engagementId, 'File ID': fileId })
}

export const downloadedFilesInBox = (engagementId: string, fileIds: Array<string>): void => {
  fileIds.forEach((fileId: string) => {
    mixpanelTrack('Downloaded File in Shared Docs', {
      'Engagement ID': engagementId,
      'File ID': fileId,
    })
  })
}

export const deletedFilesInBox = (engagementId: string, fileIds: Array<string>): void => {
  fileIds.forEach((fileId: string) => {
    mixpanelTrack('Deleted File in Shared Docs', {
      'Engagement ID': engagementId,
      'File ID': fileId,
    })
  })
}

export const acceptingTeamInvite = (companyId: string): void => {
  mixpanelTrack('Team Invite Accepting', { 'Company ID': companyId })
}

export const acceptedTeamInvite = (companyId: string): void => {
  mixpanelTrack('Team Invite Accepted', { 'Company ID': companyId })
}

export const sentInviteSuccessfully = (companyId: string, email: string): void => {
  mixpanelTrack('Team Invite Issued', { 'Company ID': companyId, Email: email })
}

export const creatingProject = (): void => {
  mixpanelTrack('Start Creating Project')
  sendIntercomEvent('Creating Project')
}

export const publishProject = (projectId: string): void => {
  mixpanelTrack('Publish Project', {
    'Project ID': projectId,
  })
}

export const downloadedHowToPublishGuide = (source: string): void => {
  mixpanelTrack('Project Help Guide Viewed', { Page: source })
}

export const abandonProjectPayment = (projectId: string): void => {
  mixpanelTrack('Abandon Project Payment', {
    'Project ID': projectId,
  })
}

export const viewedTeamNotes = (channelId: string): void => {
  mixpanelTrack('View Team Notes', { 'Channel ID': channelId })
}

export const sentNoteMessage = (channelId: string): void => {
  mixpanelTrack('Team Notes Message Sent', { 'Channel ID': channelId })
}

export const focusedTeamNotesTextField = (channelId: string): void => {
  mixpanelTrack('Team Notes Text Field Focused', { 'Channel ID': channelId })
}

export const startedMentioningSomeoneInNotes = (channelId: string): void => {
  mixpanelTrack('Started Mentioning Someone In Notes Compose', { 'Channel ID': channelId })
}

export const sentMessageWithMention = (channelId: string): void => {
  mixpanelTrack('Engagement Message Sent', { 'Channel ID': channelId })
}

export const clickedCloseExpandedView = (): void => {
  mixpanelTrack('Expanded engagement module closed')
}

export const viewedToDos = (engagement: Engagement, user?: User): void => {
  const companyType = engagement.perspective
  const company = companyType === 'brand' ? engagement.brand : engagement.coman
  const engagementId = engagement.id
  const userId = user ? user.id : ''

  mixpanelTrack("To Do's viewed", {
    'Company type': companyType,
    'Company ID': company.id,
    'User ID': userId,
    'Engagement ID': engagementId,
  })
}

export const checkedToDo = (
  engagement: Engagement,
  todoKey: TodoKeysType,
  checked: boolean,
  user?: User,
): void => {
  const companyType = engagement.perspective
  const company = companyType === 'brand' ? engagement.brand : engagement.coman
  const engagementId = engagement.id
  const userId = user ? user.id : ''

  mixpanelTrack('To Do checked', {
    'Company type': companyType,
    'Company ID': company.id,
    'User ID': userId,
    'Engagement ID': engagementId,
    'Todo key': todoKey,
  })
}

type ChangedEngagementStatusProps = {
  engagement: Engagement
  oldStatus: EngagementStatusDisplay
  newStatus: EngagementStatusDisplay
  inactiveState: EngagementInactiveStateKey
}
export const changedEngagementStatus = ({
  engagement,
  oldStatus,
  newStatus,
  inactiveState,
}: ChangedEngagementStatusProps): void => {
  const { perspective } = engagement
  const userType = perspective === 'brand' ? 'brand' : 'coman'

  mixpanelTrack('Engagement Status Updated', {
    'Engagement ID': engagement.id,
    'User type': userType,
    'Old status': oldStatus,
    'New status': newStatus,
    'Inactive state': displayInactiveState(inactiveState),
  })
}

export const scheduleCallViaCalendly = (engagementId: string): void => {
  mixpanelTrack('Schedule call via Calendly', {
    'Engagement ID': engagementId,
  })
}

const getUserType = (engagement: Engagement): 'brand' | 'coman' => {
  const { perspective } = engagement
  return perspective === 'brand' ? 'brand' : 'coman'
}

type UpdatedEngagementStatusProps = {
  engagement: Engagement
  oldStatus: EngagementStatusDisplay
  newStatus: EngagementStatusDisplay
}
export const updatedEngagementStatus = ({
  engagement,
  oldStatus,
  newStatus,
}: UpdatedEngagementStatusProps): void => {
  mixpanelTrack('Engagement Status Updated', {
    'Engagement ID': engagement.id,
    'User type': getUserType(engagement),
    'Old status': oldStatus,
    'New status': newStatus,
  })
}

export const productionConfirmationModalOpen = (engagement: Engagement): void => {
  mixpanelTrack('Production Confirmation Modal Open', {
    'Engagement ID': engagement.id,
    'User type': getUserType(engagement),
  })
}
export const productionConfirmationModalAbandoned = (engagement: Engagement): void => {
  mixpanelTrack('Production Confirmation Modal Abandoned', {
    'Engagement ID': engagement.id,
    'User type': getUserType(engagement),
  })
}
export const productionConfirmationConfirmed = (engagement: Engagement): void => {
  mixpanelTrack('Production Confirmation Confirmed', {
    'Engagement ID': engagement.id,
    'User type': getUserType(engagement),
  })
}

export const hoverOverBadge = (badgeName: string): void => {
  mixpanelTrack('Hover over badge', {
    Badge: badgeName,
  })
}

export const viewProjectDetailModal = (projectId: string): void => {
  mixpanelTrack('View Project Details Modal', { 'Project ID': projectId })
}
