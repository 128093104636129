import React from 'react'

import { Images } from '@/constants'

import styles from './styles.scss'
import { ToastProps } from './types'

const Toast: React.FC<ToastProps> = ({ title, text, icon }: ToastProps) => (
  <>
    <div className={styles.iconContainer}>
      <img className={styles.icon} src={icon} alt="check icon" />
    </div>
    <div className={styles.textContainer}>
      <div className={styles.title}>{title}</div>
      <span className={styles.subtitle}>{text}</span>
    </div>
  </>
)

Toast.defaultProps = {
  icon: undefined,
}

export function ToastCloseButton(): React.ReactElement {
  return (
    <div className={styles.closeContainer}>
      <img
        className={styles.closeIcon}
        src={Images.toastClose}
        alt="close icon"
        aria-label="Close"
      />
    </div>
  )
}

export default Toast
