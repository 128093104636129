import styled from '@emotion/styled'

import styles from '../../../styles/variables.scss'

const { buttonHeight } = styles

type Props = {
  editing: boolean
}

export const BannerImage = styled.div`
  ${({ image }: { image: string | null }) => `
  background: url(${image || ''})
`}
`

export const ProfileArea = styled.div<Props>`
  margin-bottom: ${(props) => (props.editing ? `calc(${buttonHeight} * 2)` : `0`)};
`
