import maxBy from 'lodash/maxBy'
import { Company, CompanyTypeEnum, User } from 'partnerslate-models'
import { ProjectSummary } from 'partnerslate-models/lib/project'

import { Routes } from '@/constants'

interface PostLoginRouterProps {
  user: User
  company: Company
}

interface PostLoginRouteResult {
  nextUrl: string
  useReactRouter: boolean
}

function buildResult(url: string, useReactRouter = true): PostLoginRouteResult {
  return {
    nextUrl: url,
    useReactRouter,
  }
}

function postLoginRoute({ user, company }: PostLoginRouterProps): PostLoginRouteResult {
  const { engagement_info: engagementInfo } = user

  const { engagements_count: engagementsCount, first_engagement_id: firstEngagementId } =
    engagementInfo

  const isBrand = company.company_type === CompanyTypeEnum.BRAND
  const isComan = company.company_type === CompanyTypeEnum.MANUFACTURER

  function _calcNextUrlForBrand(): PostLoginRouteResult {
    function getMostRecentProject(projects: ProjectSummary[]) {
      return maxBy(projects, (p) => new Date(p.created_at)) as ProjectSummary
    }

    // if there are no projects, go to new project intake form
    if (user.projects.length === 0) {
      return buildResult(Routes.ProjectIntakeNewProject, false)
    }

    // if all projects are unpaid, go to the most recent project's intake landing
    const allProjectsAreUnpaid = user.projects.every((p) => !p.is_paid)
    if (allProjectsAreUnpaid) {
      // we know user.projects is non-empty so we can guarentee the result won't be undefined
      const mostRecentProject = getMostRecentProject(user.projects)
      return buildResult(Routes.ProjectIntakeLanding(mostRecentProject.id), false)
    }

    // if there's just a single project and engagement, go directly to engagement
    if (engagementsCount === 1 && user.projects.length === 1) {
      return buildResult(Routes.EngagementDetailSlug(firstEngagementId))
    }

    const matchedProjects = user.projects.filter((p) => p.is_matched)
    const unMatchedProjects = user.projects.filter((p) => !p.is_matched)

    // if there's just a single matched project go directly to the matches list for that project. Only a brand can have projects
    if (matchedProjects.length === 1) {
      return buildResult(Routes.BrandMatchesListingSlug(matchedProjects[0].id), false)
    }

    if (engagementsCount === 0) {
      // If they don't have engagements, but do have multiple projects that have not been matched, land on the most recent project
      if (matchedProjects.length > 1) {
        const mostRecentProject = getMostRecentProject(user.projects)
        return buildResult(Routes.ProjectIntakeLanding(mostRecentProject.id), false)
      }

      // If they don't have engagements, but have a single unmatched project that have not been matched, land on that project intake (if unpaid)
      // or the project matches (if paid)

      if (unMatchedProjects.length >= 1) {
        const firstProject = unMatchedProjects[0]
        if (firstProject.is_paid) {
          // land in project matches (if paid)
          return buildResult(Routes.BrandMatchesListingSlug(firstProject.id), false)
        }
        // land in project intake (if unpaid)
        return buildResult(Routes.ProjectIntakeLanding(firstProject.id), false)
      }
    }

    // Regardless of number of engagements, if they have more than one project, land on the most recent project intake
    if (user.projects.length > 1) {
      const mostRecentProject = getMostRecentProject(user.projects)
      return buildResult(Routes.ProjectIntakeLanding(mostRecentProject.id), false)
    }

    // Fallback to the profile page
    return buildResult(Routes.MyProfile)
  }

  function _calcNextUrlForComan(): PostLoginRouteResult {
    // if there are any engagements go to engagements list page. Only a coman can have a partnerEngagement
    if (engagementsCount > 0) {
      return buildResult(Routes.Engagements, false)
    }

    // proceed to marketplace for any other scenario
    return buildResult(Routes.Marketplace, false)
  }

  if (isBrand) {
    return _calcNextUrlForBrand()
  }
  if (isComan) {
    return _calcNextUrlForComan()
  }
  // fallback to the profile page in case of tenants that are not Brand nor
  // Manufacturer: i.e. Supplier as of this writing
  return buildResult(Routes.MyProfile)
}

export default postLoginRoute
