import { Typography } from '@mui/material'
import React from 'react'

export function SendSamples(): JSX.Element {
  return (
    <>
      If you have a finished product, co-mans will normally request a samples to understand taste
      and consistency as well as the final packaging.
    </>
  )
}

export function ShareSupportingDocuments(): JSX.Element {
  return (
    <>
      A co-man will usually need to see the following documents after your introductory call.
      <ul>
        <li>Product Formula</li>
        <li>Images of your product & packaging Design documents</li>
        <li>Packaging Specs</li>
        <li>Process authority letter</li>
      </ul>
    </>
  )
}

export function ScheduleIntroCall(): JSX.Element {
  return (
    <>
      If the co-man has not reached out yet, please message them with 3-4 available time slots that
      you can meet and the best way to reach you. Be prepared to discuss:
      <ul>
        <li>Your process and how you source your ingredients</li>
        <li>Any R&D / formulation needs</li>
        <li>Any equipment/processing needs</li>
        <li>Equipment/processing needs</li>
        <li>Turnkey service needs</li>
      </ul>
    </>
  )
}

export function SiteVisit(): JSX.Element {
  return (
    <Typography>
      Once you have had several conversations and feel like there is high potential for a production
      partnership, you might want to visit the facility to make sure the facility and manufacture
      meet your expectations. This is not required. Please know any rules to visit the facility.
      There are usually footwear and clothing requirements.
    </Typography>
  )
}

export function BenchtopSamples(): JSX.Element {
  return (
    <Typography>
      Depending on the complexity of your product, you might want to run a small batch in the
      manufacturer’s test kitchen, if available. This is something you should discuss with your
      manufacturer ahead of time. You might be able to do this during the site visit.
    </Typography>
  )
}

export function TrialRun(): JSX.Element {
  return (
    <Typography>
      This is usually a smaller scale run of your normal production. Many times brands are present
      for the trial, but ask the manufacturer before planning any travel. Please know any rules to
      visit the facility. There are usually footwear and clothing requirements. Have a plan for the
      product if the trial goes well or doesn’t go well. Some products will have to go through a
      couple trial runs to perfect the process.
    </Typography>
  )
}

export function ReceiveQuote(): JSX.Element {
  return (
    <Typography>
      Timing varies on when you might receive a quote. The manufacturer also might start with an
      estimate and update the quote after benchtop samples or a trial run.
    </Typography>
  )
}

export function IngredientProcurement(): JSX.Element {
  return (
    <Typography>
      Depending on your agreement, you may need to source some or all ingredients through the
      co-packer. You will want enough time to find alternatives if the ingredients don’t work for
      your product.
    </Typography>
  )
}

export function Contract(): JSX.Element {
  return (
    <Typography>
      This is a very important step where you will need to negotiate with the manufacturer for
      important key terms such as pricing and what is included, MOQs, who is responsible for bad
      batches, what happens in the case of a recall, etc. We highly recommend hiring a lawyer or
      someone experienced in contract negotiations if you do not have previous experience.
    </Typography>
  )
}

export function ProductionRun(): JSX.Element {
  return (
    <Typography>
      Please let us know if you make it to production! We would love to celebrate your success.
    </Typography>
  )
}
