import stylesVariables from '../../../styles/variables.scss'

const styles = {
  handleStyle: {
    borderColor: stylesVariables.primary,
  },
  trackStyle: {
    backgroundColor: stylesVariables.grey,
  },
  railsStyle: {
    backgroundColor: stylesVariables.primary,
  },
}

export default styles
