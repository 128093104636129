interface RequiredProps {
  ariaLabel: string
}

export enum Size {
  regular = 'regular',
  large = 'large',
  small = 'small',
}

export interface DefaultProps {
  title: string
  variant: string
  onClick: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
  icon?: string | undefined
  iconAlt?: string | undefined
  className?: string
  type: 'button' | 'reset' | 'submit'
  disabled: boolean
  children: React.ReactNode | null
  size?: Size
  testId?: string | undefined
}

type ButtonProps = Partial<DefaultProps> & RequiredProps

export default ButtonProps
