import { joinStr } from '@clearsummit/carabiners'
import React from 'react'

import { ActionButton, AddImage, ProfileImage } from '@/components'
import Strings, { Images, Metrics } from '@/constants'

import EditProfileImage from '../profile/edit-profile'
import styles from './styles.scss'

type Props = {
  image: string
  onClick: (image: File) => void
  editing: boolean
  pending: boolean
  deleteImage: () => void
}

function EditableAvatar({ image, onClick, editing, pending, deleteImage }: Props): JSX.Element {
  if (editing) {
    return (
      <div className={joinStr(styles.profileImage, styles.addProfileImage, styles.avatar)}>
        <AddImage onClick={onClick} minSize={Metrics.minProfileSize} pending={pending}>
          <EditProfileImage image={image} />
        </AddImage>
        {image && (
          <ActionButton
            className={styles.trashcan}
            icon={Images.trashcan}
            onClick={deleteImage}
            ariaLabel={Strings.profile.deleteProfilePhoto}
            iconAlt={Strings.profile.deleteProfilePhoto}
          />
        )}
      </div>
    )
  }
  return (
    <div className={joinStr(styles.profileImage, styles.addProfileImage, styles.avatar)}>
      <ProfileImage image={image} />
    </div>
  )
}

export default EditableAvatar
