import { useFormikContext } from 'formik'
import { Address } from 'partnerslate-models/lib/company'
import FieldOption from 'partnerslate-models/lib/field-option'
import React from 'react'

import { Dropdown } from '@/components/base-form/fields'
import { canadaOrUsa, STATES_AND_PROVINCES } from '@/domain/address'

const FIELD_OPTIONS: Array<FieldOption> = STATES_AND_PROVINCES.map(({ id, label }) => {
  return new FieldOption({ id, label })
})

function StateProvinceDropdown(): JSX.Element | null {
  const { values } = useFormikContext<{ address: Address }>()

  const showDropdown = canadaOrUsa(values.address)

  if (!showDropdown) {
    return null
  }

  return (
    <Dropdown
      name="address.state"
      label="State/province *"
      placeholder="select a value"
      ariaLabel="State/province (required)"
      options={FIELD_OPTIONS}
      testId="Address/state"
    />
  )
}

export default StateProvinceDropdown
