/** @jsx jsx */
import { joinStr } from '@clearsummit/carabiners'
import { jsx, SerializedStyles } from '@emotion/core'
import React, { ReactNode } from 'react'
import { Link } from 'react-router-dom'

import buttonJsStyles from '@/components/button/styles'
import buttonStyles from '@/components/button/styles.scss'
import { Size } from '@/components/button/types'
import { Assets } from '@/constants'

import jsStyles from './styles'
import styles from './styles.scss'

type BaseAnchorProps = {
  ariaLabel: string
  label: string
  path: string
  className?: string
  onClick?: ((event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => void) | undefined
  testId?: string | undefined
}

type AnchorProps = BaseAnchorProps & {
  variant?: string
  css?: undefined | null | SerializedStyles | SerializedStyles[]
}

const Anchor: React.FC<AnchorProps> = (props: AnchorProps) => {
  const { ariaLabel, path, css, variant, className, label, onClick, testId } = props
  return (
    <Link
      aria-label={ariaLabel}
      className={joinStr(styles.anchor, className)}
      css={css || jsStyles.anchorVariant(variant)}
      to={path}
      onClick={onClick}
    >
      <span data-testid={testId}>{label}</span>
    </Link>
  )
}

Anchor.defaultProps = {
  className: undefined,
  variant: 'primary',
  css: null,
  onClick: undefined,
  testId: undefined,
}

type PseudoAnchorProps = {
  label: string
  onClick: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void
  className?: string | undefined
  testId?: string | undefined
}
export const PseudoAnchor: React.FC<PseudoAnchorProps> = (props: PseudoAnchorProps) => {
  const { className, label, onClick, testId } = props
  return (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events
    <div
      role="button"
      tabIndex={0}
      className={joinStr(styles.anchor, className)}
      onClick={onClick}
      data-testid={testId}
    >
      <span>{label}</span>
    </div>
  )
}

export const SecondaryAnchor: React.FC<AnchorProps> = (props: AnchorProps) => (
  <Anchor {...props} variant="secondary" />
)

SecondaryAnchor.defaultProps = Anchor.defaultProps

type ExternalAnchorProps = {
  newWindow?: boolean
}

export const ExternalAnchor: React.FC<AnchorProps & ExternalAnchorProps> = (
  props: AnchorProps & ExternalAnchorProps,
) => {
  const { ariaLabel, path, css, variant, className, label, onClick, newWindow, testId } = props
  return (
    <a
      aria-label={ariaLabel}
      className={joinStr(styles.anchor, className)}
      css={css || jsStyles.anchorVariant(variant)}
      href={path}
      onClick={onClick}
      target={newWindow ? '_blank' : '_self'}
      rel="noreferrer"
    >
      <span data-testid={testId}>{label}</span>
    </a>
  )
}

ExternalAnchor.defaultProps = { ...Anchor.defaultProps, newWindow: false }

export const SecondaryExternalAnchor: React.FC<AnchorProps & ExternalAnchorProps> = (
  props: AnchorProps & ExternalAnchorProps,
) => <ExternalAnchor {...props} variant="secondary" />

SecondaryExternalAnchor.defaultProps = { ...Anchor.defaultProps, newWindow: false }

type ButtonAnchorProps = AnchorProps & {
  size?: Size
  disabled?: boolean
}

const ButtonAnchorDefaultProps = {
  ...Anchor.defaultProps,
  size: Size.regular,
  disabled: false,
}

export const PrimaryButtonAnchor: React.FC<ButtonAnchorProps> = (props: ButtonAnchorProps) => {
  const { className, size, disabled } = props
  return (
    <Anchor
      {...props}
      className={joinStr(buttonStyles.button, styles.anchorButton, className)}
      css={[buttonJsStyles.button(size, 'primary'), jsStyles.disabled(disabled)]}
    />
  )
}

PrimaryButtonAnchor.defaultProps = ButtonAnchorDefaultProps

type ExternalPrimaryButtonAnchorProps = BaseAnchorProps & ExternalAnchorProps & { size?: Size }

export const ExternalPrimaryButtonAnchor: React.FC<ExternalPrimaryButtonAnchorProps> = (
  props: ExternalPrimaryButtonAnchorProps,
) => {
  const { ariaLabel, size, path, className, label, onClick, newWindow, testId } = props
  return (
    <a
      aria-label={ariaLabel}
      className={joinStr(buttonStyles.button, styles.anchorButton, className)}
      css={[buttonJsStyles.button(size ?? Size.large, 'primary'), jsStyles.disabled(false)]}
      href={path}
      onClick={onClick}
      target={newWindow ? '_blank' : '_self'}
      rel="noreferrer"
    >
      <span data-testid={testId}>{label}</span>
    </a>
  )
}

export const SecondaryButtonAnchor: React.FC<ButtonAnchorProps> = (props: ButtonAnchorProps) => {
  const { className, size, disabled } = props
  return (
    <Anchor
      {...props}
      className={joinStr(buttonStyles.button, styles.anchorButton, className)}
      css={[buttonJsStyles.button(size, 'secondary'), jsStyles.disabled(disabled)]}
    />
  )
}

SecondaryButtonAnchor.defaultProps = ButtonAnchorDefaultProps

interface StatefulIconAnchorProps extends AnchorProps {
  selected: boolean
  icon: string
}

export const StatefulIconAnchor: React.FC<StatefulIconAnchorProps> = (
  props: StatefulIconAnchorProps,
) => {
  const { ariaLabel, path, className, label, onClick, icon, selected, css, testId, ...rest } = props

  return (
    <Link
      aria-label={ariaLabel}
      className={joinStr(styles.statefulAnchor, className)}
      css={css || jsStyles.statefulAnchor(selected)}
      to={path}
      onClick={onClick}
      {...rest}
    >
      <img className="mr-4" src={icon} width="24" height="24" alt="" />
      <span data-testid={testId}>{label}</span>
    </Link>
  )
}

StatefulIconAnchor.defaultProps = { ...Anchor.defaultProps, selected: false }

type SupportEmailAnchorProps = {
  text?: string
}
export function SupportEmailAnchor({
  text = 'support@partnerslate.com',
}: SupportEmailAnchorProps): JSX.Element {
  return (
    <a href="mailto:support@partnerslate.com" className={styles.genericLink}>
      {text}
    </a>
  )
}

type DomAnchorProps = {
  ariaLabel: string
  label: string
  path: string
}
export function DomAnchor(props: DomAnchorProps): JSX.Element {
  const { ariaLabel, path, label } = props
  return (
    <a aria-label={ariaLabel} className={joinStr(styles.anchor)} href={path}>
      <span>{label}</span>
    </a>
  )
}

type TechnicalReviewGuidePdfAnchorProps = {
  children: ReactNode
}
export function TechnicalReviewGuidePdfAnchor({
  children,
}: TechnicalReviewGuidePdfAnchorProps): JSX.Element {
  return (
    <a
      href={Assets.technicalReviewGuidePdf}
      className={styles.genericLink}
      target="technical-guide-pdf"
    >
      {children}
    </a>
  )
}

export default Anchor
