import { css, SerializedStyles } from '@emotion/core'

import styles from '../../../styles/variables.scss'

const Styles = {
  input: (props: {
    icon: JSX.Element | string | null | undefined
    error: boolean
  }): SerializedStyles => css`
    ${props.icon
      ? `
      padding-left: 40px;
    `
      : ''}

    border-bottom-width: 1px;
    border-bottom-style: solid;
    border-bottom-color: ${styles.lightGrey};
    ${props.error ? `border-color: ${styles.error};` : ''};
    ${props.error
      ? `& + svg { filter: invert(28%) sepia(61%) saturate(7232%) hue-rotate(349deg) brightness(95%) contrast(91%) };`
      : ''}
  `,
}

export default Styles
