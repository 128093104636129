import React from 'react'

import { TextField } from '../base-form/fields'
import CountryDropdown from './country-dropdown'
import StateProvinceDropdown from './state-province-dropdown'
import styles from './styles.scss'

function AddressFields(): JSX.Element {
  return (
    <div className={styles.formSection}>
      <h3 className={styles.formSectionHeader}>Company Address</h3>
      <div className={styles.row}>
        <div className={styles.wideInput}>
          <TextField
            name="address.address1"
            label="Street *"
            placeholder=""
            testId="Address/address1"
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.wideInput}>
          <TextField
            name="address.address2"
            label="Suite, Unit #, etc"
            placeholder=""
            testId="Address/address2"
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.wideInput}>
          <TextField name="address.city" label="City/town *" placeholder="" testId="Address/city" />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.input}>
          <StateProvinceDropdown />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.input}>
          <TextField
            name="address.postalCode"
            label="ZIP/Postal code *"
            placeholder=""
            testId="Address/postalCode"
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.input}>
          <CountryDropdown />
        </div>
      </div>
    </div>
  )
}

export default AddressFields
