import React from 'react'

import Strings from '@/constants'

import type { Props } from './default-modal'
import DefaultModal from './default-modal'

export type MinImageSizeProps = {
  width: number
  height: number
} & Props

function MinImageSize({ close, width, height }: MinImageSizeProps): JSX.Element {
  return (
    <DefaultModal
      title={Strings.profile.minImageSizeTitle}
      paragraphs={[Strings.profile.minImageSize(width, height)]}
      successText={Strings.general.ok}
      close={close}
    />
  )
}

export default MinImageSize
