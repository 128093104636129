/* eslint-disable react/no-unused-prop-types */
import { ApiPayload } from '@partnerslate/radio-dispatch'
import type { CompanyPayload } from 'partnerslate-models'
import * as React from 'react'

import TestIds from '@/accessibility/test-ids'
import { BaseForm } from '@/components'
import AddressFields from '@/components/address-fields'
import { SubmitBar } from '@/components/base-form/buttons'
import { CheckboxField, PhoneField, TextField } from '@/components/base-form/fields'
import FormError from '@/components/base-form/form-error'
import Strings from '@/constants'
import { Address } from '@/domain/address'
import services, { endpoints } from '@/helpers/services'
import { CompanyResponse } from '@/redux/api-payloads'

import FieldNames, { UpdateCompanySchema } from '../schemas'
import styles from './styles.scss'

export type UpdateCompanyFormValues = {
  companyName: string
  companyPhone: string
  isPublic: boolean
  address: Address
}

interface UpdateCompanyFormProps {
  handleSubmit: (
    data: UpdateCompanyFormValues,
  ) => ApiPayload<typeof services, CompanyPayload, CompanyResponse | undefined>
  initialValues: UpdateCompanyFormValues
}

const UpdateCompanyForm: React.FC<UpdateCompanyFormProps> = (formProps) => {
  const { handleSubmit, initialValues } = formProps

  if (!initialValues.address.countryCode) {
    initialValues.address.countryCode = 'US'
  }

  return (
    <BaseForm
      initialValues={initialValues}
      onSubmitPayload={handleSubmit}
      validationSchema={UpdateCompanySchema}
      formKey={endpoints.updateCompany}
    >
      <div className={styles.formSection}>
        <div className={styles.row}>
          <div className={styles.input}>
            <TextField
              name={FieldNames.companyName}
              label={Strings.auth.signup.companyDetails.companyName}
              placeholder={Strings.auth.signup.companyDetails.enterCompanyName}
              testId={TestIds.SignUp.CompanyName}
            />
          </div>
          <div className={styles.input}>
            <PhoneField
              name={FieldNames.companyPhone}
              label={Strings.auth.signup.companyDetails.companyPhone}
              placeholder={Strings.inputs.enterHere}
              testId={TestIds.SignUp.CompanyPhone}
            />
          </div>
        </div>
      </div>
      <AddressFields />
      <div className={styles.formSection}>
        <div className={styles.row}>
          <CheckboxField
            name="isPublic"
            label="Make Profile Public"
            ariaLabel="Make Profile Public"
            testId={TestIds.Company.IsProfilePublic}
          />
        </div>
      </div>
      <FormError formKey={endpoints.updateCompany} />
      <SubmitBar />
    </BaseForm>
  )
}

export default UpdateCompanyForm
