import React from 'react'

import { Input } from '@/components'
import { Images } from '@/constants'

import { InputProps } from '.'

function SearchField({ name, placeholder, onChange, ...rest }: InputProps): JSX.Element {
  return (
    <Input
      {...rest}
      placeholder={placeholder}
      icon={Images.searchIcon}
      type="search"
      name={name}
      onChange={onChange}
    />
  )
}
SearchField.defaultProps = Input.defaultProps

export default SearchField
