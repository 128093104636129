import { Company } from 'partnerslate-models'

import { CompanyStoreState } from '@/redux/company'

import { StoreState } from '../redux'
import type { SearchedCompaniesStoreState } from '../redux/company'

const getCompanyStore = (store: StoreState): CompanyStoreState => store.company

const getCompany = (store: StoreState, slug: string): Company =>
  getCompanyStore(store).companies[slug] || new Company()

const getCompanySearchData = (store: StoreState): SearchedCompaniesStoreState =>
  getCompanyStore(store).searchedCompanies

export default {
  getCompany,
  getCompanySearchData,
}
