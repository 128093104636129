// we import specific JSON files to avoid pulling a bunch of other data files into the bundle
import CountryList from 'country-list'
import CA from 'countrystatesjs/data/canada.json'
import USA from 'countrystatesjs/data/united_states_of_america.json'
import { Address as ModelAddress } from 'partnerslate-models/lib/company'

export type Address = {
  address1: string
  address2: string
  city: string
  state: string | null
  postalCode: string
  countryCode: string
}

const notEmpty = (s: string | null): boolean => {
  return !!(s && s.trim() !== '')
}

export const displayFlattenedAddress = (address: Address | undefined | null): string => {
  if (!address) {
    return ''
  }
  // TODO: human-friendly mapping for state
  // TODO: display human-friendly country (if not US?)
  const stateAndPostalCode = [address.state, address.postalCode].filter(notEmpty).join(' ')
  const components = [address.address1, address.address2, address.city, stateAndPostalCode]
  return components.filter(notEmpty).join(', ')
}

export const domainAddressFromModelAddress = (address: ModelAddress): Address => {
  // although instances of ModelAddress as technically type-compatible with
  // Address, they are troublesome at runtime due to their use of getters. For
  // example, if you try to do something like {...address, state=null} to update
  // a value, this will not work as expected. {...address} would copy the
  // underlying postal_code field over, but not postalCode, which is a getter
  // :-( Because of this, we just copy over from the ModelAddress object to a
  // fresh POJO
  return {
    address1: address.address1,
    address2: address.address2,
    city: address.city,
    state: address.state,
    postalCode: address.postalCode,
    countryCode: address.countryCode,
  }
}

export const domainAddressToModelAddress = (address: Address): ModelAddress => {
  return new ModelAddress({
    ...address,
    postal_code: address.postalCode, // eslint-disable-line camelcase
    country_code: address.countryCode, // eslint-disable-line camelcase
  })
}

export const canadaOrUsa = (address: Address): boolean => {
  return ['US', 'CA'].includes(address.countryCode)
}

export const defaultCountryToUsaIfUnspecified = (address: Address): Address => {
  return {
    ...address,
    countryCode: address.countryCode === '' ? 'US' : address.countryCode,
  }
}

export const clearStateIfAppropriate = (address: Address): Address => {
  if (canadaOrUsa(address)) {
    return address
  }
  return {
    ...address,
    state: null,
  }
}

export type State = {
  id: string // two-letter uppercase state code
  label: string
}
export const STATES_AND_PROVINCES: Array<State> = (() => {
  return [...USA.states, ...CA.states].map(({ abbreviation, name }) => {
    return { id: abbreviation, label: name }
  })
})()

export type Country = {
  id: string // ISO 3166-1 alpha-2
  label: string
}
export const COUNTRIES: Array<Country> = (() => {
  // North America exceptionalism: we put USA and Canada to the top of the list of options
  const unprioritizedCountryCodes = CountryList.getCodes().filter((code) => {
    return code !== 'US' && code !== 'CA'
  })

  // creating these by hand because default label for US is quite verbose: "United States of America"
  const prioritizedFields = [
    { id: 'US', label: 'USA' },
    { id: 'CA', label: 'Canada' },
  ]
  const unprioritizedFields = unprioritizedCountryCodes.map((code) => {
    return { id: code, label: CountryList.getName(code) as string }
  })

  return [...prioritizedFields, ...unprioritizedFields]
})()
