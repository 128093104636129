import { reducer as api, reduxSet as apiAC } from '@partnerslate/radio-dispatch'
import * as Sentry from '@sentry/react'
import {
  AnyAction,
  applyMiddleware,
  CombinedState,
  combineReducers,
  compose,
  createStore,
  Store,
} from 'redux'
import createSagaMiddleware from 'redux-saga'

import rootSaga from '@/sagas'

import { APIStoreState, INITIAL_STATE as APIInitialState } from './api'
import {
  CompanyStoreState,
  INITIAL_STATE as CompanyInitialState,
  reducer as company,
  reduxSet as companyAC,
} from './company'
import {
  ACTIONS as USER_ACTIONS,
  INITIAL_STATE as UserInitialState,
  reducer as user,
  reduxSet as userAC,
  UserStoreState,
} from './user'

export interface StoreState {
  api: APIStoreState
  user: UserStoreState
  company: CompanyStoreState
}

// Strange higher-order function to potentially modify the result
const logAction =
  (store: StoreState) => (next: (action: StandardAction) => void) => (action: StandardAction) => {
    // @ts-ignore
    const before = store.getState()
    const result = next(action)
    if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
      // Group these console logs into one closed group
      /* eslint-disable no-console */
      // @ts-ignore
      const after = store.getState()
      console.groupCollapsed(`dispatching action => ${action.type}`)
      console.log('BEFORE', before)
      console.log('ACTION', action.type, action)
      console.log('AFTER', after)
      console.groupEnd()
      /* eslint-enable no-console */
    }

    return result
  }

const reducers = combineReducers({
  api,
  user,
  company,
})

const rootReducer = (state: CombinedState<StoreState> | undefined, action: AnyAction) => {
  let newState: CombinedState<StoreState> | undefined = state
  if (action.type === USER_ACTIONS.LOGOUT && state) {
    newState = {
      api: APIInitialState,
      user: UserInitialState,
      company: {
        ...state.company,
        searchedCompanies: CompanyInitialState.searchedCompanies,
        pending: CompanyInitialState.pending,
        error: CompanyInitialState.error,
      },
    }
  }
  return reducers(newState, action)
}

export const InitialState = {
  api: APIInitialState,
  user: UserInitialState,
  company: CompanyInitialState,
}

export const ActionCreators = {
  api: apiAC,
  user: userAC,
  company: companyAC,
}

const configureStore = (
  initialState: StoreState = InitialState,
): Store<CombinedState<StoreState>> => {
  const sagaMiddleware = createSagaMiddleware({
    onError: (error: Error) => {
      console.error(error)
      Sentry.captureException(error)
    },
  })
  const middlewares = [sagaMiddleware, logAction]
  // @ts-ignore
  let middleware = applyMiddleware(...middlewares)

  if (process.env.NODE_ENV !== 'production' && process.env.NODE_ENV !== 'test') {
    const { __REDUX_DEVTOOLS_EXTENSION__ } = window as any
    if (typeof __REDUX_DEVTOOLS_EXTENSION__ === 'function') {
      middleware = compose(middleware, __REDUX_DEVTOOLS_EXTENSION__())
    }
  }

  const store = createStore(rootReducer, initialState, middleware)
  sagaMiddleware.run(rootSaga)

  return store
}

export default configureStore
