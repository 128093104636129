export function startRequest<StateType>(
  state: StateType,
  payload: { [key: string]: null | null | undefined } | null,
): StateType {
  return {
    ...state,
    pending: true,
    ...payload,
  }
}

export function endRequest<StateType>(
  state: StateType,
  payload: { [key: string]: Error | null | undefined } | null,
): StateType {
  return {
    ...state,
    pending: false,
    ...payload,
  }
}

export const resetStoreKeyClosure =
  <S>(initialState: S) =>
  (state: S, payload: keyof S): S => ({
    ...state,
    [payload]: initialState[payload],
  })

export function spreadReducer<StateType, PayloadType>(
  state: StateType,
  payload: PayloadType,
): StateType {
  return {
    ...state,
    ...payload,
  }
}

export const noOp = <S>(state: S): S => ({
  ...state,
})

const apiReducers = {
  startRequest,
  endRequest,
  noOp,
}

export default apiReducers
