import { joinStr } from '@clearsummit/carabiners'
import React, { useEffect, useRef } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'

import Loading from '../loading'
import DefaultEmptyComponent from './default-empty-component'
import styles from './styles.scss'

const SCROLLABLE_ID = 'infinite-scroll-div'

type Props<T> = {
  children?: React.ReactNode | string | null
  className?: string
  items: Array<T>
  itemMapper: (item: T, index: number) => React.ReactNode
  getMore: () => void
  hasMore: boolean
  loading: boolean
  inverse?: boolean
  emptyStateComponent?: React.ReactNode
  loadingStateComponent?: React.ReactNode
}

function Infinite<T>(props: Props<T>): JSX.Element {
  const {
    children,
    className,
    items,
    itemMapper,
    getMore,
    emptyStateComponent,
    loadingStateComponent,
    loading,
    hasMore,
    inverse,
  } = props

  const scrollRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    if (scrollRef.current && inverse) {
      scrollRef.current.scrollIntoView()
    }
  }, [items, inverse, scrollRef])

  return (
    <div className={joinStr(styles.infinite, className)} id={SCROLLABLE_ID}>
      {!loading && !children && items.length === 0 ? (
        emptyStateComponent
      ) : (
        <InfiniteScroll
          dataLength={items.length}
          hasMore={hasMore}
          loader={loadingStateComponent}
          next={getMore}
          inverse={inverse}
          scrollableTarget={SCROLLABLE_ID}
        >
          {inverse && children}
          {items.map(itemMapper)}
          {!inverse && children}
        </InfiniteScroll>
      )}
      <div ref={scrollRef} />
    </div>
  )
}

Infinite.defaultProps = {
  className: undefined,
  children: null,
  emptyStateComponent: <DefaultEmptyComponent />,
  loadingStateComponent: <Loading />,
  inverse: false,
}

export default Infinite
