import { Engagement, EngagementPerspective } from '@/domain/engagements'

export function getInterTeamTitleByPerspective(engagement: Engagement): string {
  const isPartnerPerspective = engagement.perspective !== EngagementPerspective.Brand

  if (isPartnerPerspective) {
    return 'Message the brand'
  }

  return 'Message the manufacturer'
}
