"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.emptyArrayToUndefined = exports.emptyStringToADefault = exports.emptyStringToUndefined = exports.defaultCamelCaseObject = exports.defaultObject = exports.defaultEnum = exports.defaultArray = exports.defaultBoolean = exports.defaultDateOrNull = exports.defaultNumberOrNull = exports.defaultFloatOrNull = exports.defaultFloat = exports.defaultNumber = exports.defaultString = exports.snakeToCamel = void 0;
const lodash_1 = require("lodash");
const _1 = require(".");
const snakeToCamel = (key) => key.replace(/([_][a-z])/gi, ($1) => $1.toUpperCase().replace('_', ''));
exports.snakeToCamel = snakeToCamel;
const defaultString = (value) => {
    var _a;
    if (value && typeof ((_a = value) === null || _a === void 0 ? void 0 : _a.toString) === 'function') {
        return value.toString();
    }
    return '';
};
exports.defaultString = defaultString;
const defaultNumber = (value) => {
    var _a;
    if (value && typeof ((_a = value) === null || _a === void 0 ? void 0 : _a.toString) === 'function') {
        const num = parseInt(value.toString(), 10);
        if ((0, lodash_1.isFinite)(num)) {
            return num;
        }
    }
    return 0;
};
exports.defaultNumber = defaultNumber;
const defaultFloat = (value) => {
    var _a;
    if (value && typeof ((_a = value) === null || _a === void 0 ? void 0 : _a.toString) === 'function') {
        const num = parseFloat(value.toString());
        if ((0, lodash_1.isFinite)(num)) {
            return num;
        }
    }
    return 0;
};
exports.defaultFloat = defaultFloat;
const defaultFloatOrNull = (value) => {
    const num = (0, exports.defaultFloat)(value);
    if (num === 0 || undefined) {
        return null;
    }
    return num;
};
exports.defaultFloatOrNull = defaultFloatOrNull;
const defaultNumberOrNull = (value) => {
    const num = (0, exports.defaultNumber)(value);
    if (num === 0 || undefined) {
        return null;
    }
    return num;
};
exports.defaultNumberOrNull = defaultNumberOrNull;
const defaultDateOrNull = (value) => {
    if (value instanceof Date) {
        return value;
    }
    if (typeof value === 'string' && typeof new Date(value) === 'object') {
        return new Date(value);
    }
    return null;
};
exports.defaultDateOrNull = defaultDateOrNull;
const defaultBoolean = (value) => !!value;
exports.defaultBoolean = defaultBoolean;
const defaultArray = (value) => {
    let list = [];
    if (Array.isArray(value)) {
        list = value;
    }
    else if (value) {
        list = [value];
    }
    return list;
};
exports.defaultArray = defaultArray;
function defaultEnum(value, enumObj, defaultValue) {
    if (value && Object.values(enumObj).includes(value)) {
        return value;
    }
    return defaultValue;
}
exports.defaultEnum = defaultEnum;
function defaultObject(value, defaultObj) {
    let obj = (0, lodash_1.isPlainObject)(value) || (value instanceof _1.BaseModel && value) ? value : {};
    if (defaultObj) {
        obj = Object.assign(defaultObj, obj);
    }
    return obj;
}
exports.defaultObject = defaultObject;
function defaultCamelCaseObject(value) {
    const obj = {};
    if (value) {
        Object.keys(value).forEach((key) => {
            const currentValue = value[key];
            obj[(0, exports.snakeToCamel)(key)] = currentValue;
            if (Array.isArray(currentValue)) {
                obj[(0, exports.snakeToCamel)(key)] = currentValue.map((x) => defaultObject(x));
            }
            else if (typeof currentValue === 'object') {
                obj[(0, exports.snakeToCamel)(key)] = defaultObject(currentValue);
            }
            else if (currentValue === 'true' || currentValue === 'false') {
                obj[(0, exports.snakeToCamel)(key)] = JSON.parse(currentValue);
            }
        });
    }
    return obj;
}
exports.defaultCamelCaseObject = defaultCamelCaseObject;
function emptyStringToUndefined(value) {
    if (typeof value === 'string' && value.length > 0) {
        return value;
    }
    return undefined;
}
exports.emptyStringToUndefined = emptyStringToUndefined;
function emptyStringToADefault(value, defaultValue) {
    if (typeof value === 'string' && value.length > 0) {
        return value;
    }
    return defaultValue;
}
exports.emptyStringToADefault = emptyStringToADefault;
function emptyArrayToUndefined(value) {
    if (Array.isArray(value) && value.length > 0) {
        return value;
    }
    return undefined;
}
exports.emptyArrayToUndefined = emptyArrayToUndefined;
exports.default = {
    defaultBoolean: exports.defaultBoolean,
    defaultCamelCaseObject,
    defaultEnum,
    defaultFloat: exports.defaultFloat,
    defaultNumber: exports.defaultNumber,
    defaultObject,
    defaultString: exports.defaultString,
    defaultDateOrNull: exports.defaultDateOrNull,
    emptyStringToUndefined,
    emptyStringToADefault,
    emptyArrayToUndefined,
};
