// Imports
import ___CSS_LOADER_API_IMPORT___ from "../../../node_modules/css-loader/dist/runtime/api.js";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
// Module
___CSS_LOADER_EXPORT___.push([module.id, ".src-components-paper-___styles__paper___3cyl_{\n  box-shadow:0 2px 5px 0 rgba(0,0,0,0.1);\n  border-radius:4px;\n  overflow:hidden\n}\n\n.src-components-paper-___styles__paper___3cyl_ :hover{\n  transition:all 0.2s ease-in-out\n}\n", "",{"version":3,"sources":["webpack://styles.scss","webpack://../../../styles/mixins.scss"],"names":[],"mappings":"AAKA;EC8CE,sCAA0C;ED5C1C,iBAAkB;EAClB;AAAgB;;AAHlB;EAKI;AAAgC","sourcesContent":["@import '../../../styles/colors';\n@import '../../../styles/metrics';\n@import '../../../styles/grid';\n@import '../../../styles/mixins';\n\n.paper {\n  @include boxShadow();\n  border-radius: 4px;\n  overflow: hidden;\n  :hover {\n    transition: all 0.2s ease-in-out;\n  }\n}\n","// @include transition(all,2s,ease-out);\n@mixin transition($what: all, $time: 0.2s, $how: ease-in-out) {\n  transition: $what $time $how;\n}\n\n@mixin growOnHover($amount: 1.02) {\n  @include transition();\n\n  &:hover {\n    transform: scale($amount);\n  }\n}\n\n@mixin container() {\n  @include row();\n  height: calc(100vh - #{$headerHeightDesktop});\n  padding: 0;\n  @media (max-width: $mobileBreakpoint) {\n    height: calc(100vh - #{$headerHeightMobile});\n  }\n}\n\n@mixin sidebarContainer($col: 5) {\n  @include lg($col);\n  @include row();\n  align-items: center;\n  display: grid;\n  justify-content: center;\n}\n\n@mixin contentContainer($col: 11) {\n  @include lg($col);\n  display: grid;\n}\n\n@mixin backgroundImageContainer($image: '') {\n  @include contentContainer();\n\n  background-image: url('../src/#{$image}');\n  background-repeat: no-repeat;\n  background-size: cover;\n  overflow: hidden;\n}\n\n@mixin typesSelectorContainer() {\n  @include row(12);\n\n  margin-top: 40px;\n}\n\n@mixin boxShadow() {\n  box-shadow: 0 2px 5px 0 rgba(0, 0, 0, 0.1);\n}\n\n@mixin formInput() {\n  margin-right: $bigSpacer;\n  width: calc(275px + 50px);\n  input {\n    width: 275px;\n  }\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#2b352f",
	"buttonShadowGrey": "rgba(102,102,102,0.25)",
	"darkerGrey": "#4f4f4f",
	"darkGrey": "#828282",
	"error": "#e52a2a",
	"fontBlack": "#333",
	"grey": "#e0e0e0",
	"greyBorder": "#f1f1f1",
	"lightestGreen": "#f4fcfa",
	"lightestGrey": "#fcfcfc",
	"lightGrey": "#f5f6fa",
	"middleGrey": "#a9a9a9",
	"primary": "#20cb9a",
	"primaryDark": "#111827",
	"secondary": "#2f80ed",
	"secondaryOpacity": "rgba(47,128,237,0.3)",
	"secondaryLightestOpacity": "rgba(47,128,237,0.1)",
	"success": "#20cb9a",
	"tertiary": "#56666e",
	"warning": "#e88f40",
	"white": "#fff",
	"buttonWidthMobile": "100px",
	"mobileBreakpoint": "600px",
	"bigspacer": "25px",
	"buttonHeight": "44px",
	"cardTitleFont": "18px",
	"inputHeight": "48px",
	"profilePhoto": "135px",
	"slimButtonHeight": "24px",
	"smallAction": "26px",
	"smallestFont": "12px",
	"smallestSpacer": "8px",
	"smallFont": "14px",
	"spacer": "15px",
	"standardFont": "16px",
	"subtitleFont": "16px",
	"titleFont": "26px",
	"paper": "src-components-paper-___styles__paper___3cyl_"
};
export default ___CSS_LOADER_EXPORT___;
