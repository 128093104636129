import React from 'react'

import RadioButton from './radio-button'
import RadioList from './styles'
import styles from './styles.scss'
import type RadioInputProps from './types'

function RadioInput(props: RadioInputProps): JSX.Element {
  const { value, options, onChange, label, horizontal, required, testId } = props

  return (
    <div>
      {label ? (
        <span className={styles.inputLabel}>
          {label}
          {required ? ' *' : ''}
        </span>
      ) : null}
      <RadioList horizontal={horizontal}>
        {options.map((opt) => (
          <RadioButton
            key={opt.id}
            label={opt.label}
            description={opt.subText}
            checked={opt.id === value?.id}
            value={opt}
            onChange={onChange}
            testId={testId}
          />
        ))}
      </RadioList>
    </div>
  )
}

RadioInput.defaultProps = {
  label: '',
  horizontal: false,
  required: false,
  testId: undefined,
}

export default RadioInput
