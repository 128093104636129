import React from 'react'

import Strings, { Images } from '@/constants'

import Button, { ActionButton, SecondaryButton } from '../../button'
import styles from './styles.scss'

export interface Props {
  successCallback?: () => void
  failCallback?: () => void
  close: () => void
}

export interface SetupProps {
  title?: string
  paragraphs?: Array<string>
  successText?: string
  failText?: string
  successCallback?: () => void
  failCallback?: () => void
  close: () => void
  children?: JSX.Element
}

function DefaultModal({
  successCallback,
  failCallback,
  close,
  title,
  paragraphs,
  successText,
  failText,
  children,
}: Props & SetupProps): JSX.Element {
  return (
    <div className={styles.defaultModal}>
      <ActionButton
        onClick={close}
        className={styles.close}
        icon={Images.blackThinX}
        ariaLabel={Strings.general.close}
        iconAlt={Strings.general.close}
      />
      <div className={styles.defaultModalContents}>
        <h1>{title}</h1>
        {paragraphs ? paragraphs.map((p) => <span key={p}>{p}</span>) : null}
        <span>{children}</span>
      </div>
      <div className={styles.buttonRow}>
        {failText ? (
          <SecondaryButton
            onClick={() => {
              close()
              if (failCallback) {
                failCallback()
              }
            }}
            ariaLabel={failText}
            title={failText}
          />
        ) : null}
        {successText ? (
          <Button
            ariaLabel={successText}
            title={successText}
            onClick={() => {
              close()
              if (successCallback) {
                successCallback()
              }
            }}
          />
        ) : null}
      </div>
    </div>
  )
}

export default DefaultModal
