import React from 'react'

import { SecondaryExternalAnchor } from '@/components'
import Strings, { StaticLinks } from '@/constants'

function TOSLink(): React.ReactElement {
  return (
    <SecondaryExternalAnchor
      label={Strings.general.tos}
      path={StaticLinks.tos}
      ariaLabel={Strings.general.tos}
      newWindow
    />
  )
}

function PrivacyLink(): React.ReactElement {
  return (
    <SecondaryExternalAnchor
      label={Strings.general.privacyPolicy}
      path={StaticLinks.privacyPolicy}
      ariaLabel={Strings.general.privacyPolicy}
      newWindow
    />
  )
}

function TermsOfService(): React.ReactElement {
  return (
    <div>
      I agree to PartnerSlate’s <TOSLink /> & <PrivacyLink />. *
    </div>
  )
}

export default TermsOfService
