import React, { useRef } from 'react'

import modal from '@/components/modal/modal-events'
import { createImage } from '@/helpers/image'

import Loading from '../loading'
import styles from './styles.scss'

interface Props {
  onClick: (image: File) => void
  minSize: { width: number; height: number }
  pending: boolean
  children: React.ReactElement<Partial<{ onClick: () => void }>>
}

function AddImage({ onClick, minSize, pending, children }: Props): JSX.Element {
  const ref = useRef<HTMLInputElement>(null)
  const onChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { files } = e.target

    if (files && files[0]) {
      const file = files[0]
      const image = await createImage(file)
      if (image && image.height >= minSize.height && image.width >= minSize.width) {
        onClick(files[0])
      } else {
        modal.renderModal('minImageSize', { width: minSize.width, height: minSize.height })
      }
    }
  }
  const onClickFocus = () => {
    if (ref.current) {
      ref.current.click()
    }
  }
  if (pending) {
    return <Loading />
  }
  return (
    <>
      {React.cloneElement(children, { onClick: onClickFocus })}
      <input
        ref={ref}
        type="file"
        id={styles.fileUpload}
        name="filename"
        onChange={onChange}
        accept="image/*"
      />
    </>
  )
}

export default AddImage
