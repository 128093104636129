import { Grid, Tooltip } from '@mui/material'
import { Company } from 'partnerslate-models'
import React from 'react'

import { instrumentation } from '@/helpers'

import { CertifiedCertBadgeSrc } from './images'

const comanBadgeKeys = ['VERIFIED_CERTIFICATION'] as const
type ComanBadgeKey = typeof comanBadgeKeys[number]

type BadgeDisplay = {
  description: string
  badgeIconSrc: string
}

export const COMAN_BADGES: Record<ComanBadgeKey, BadgeDisplay> = {
  VERIFIED_CERTIFICATION: {
    description: "This manufacturer's certifications have been verified by PartnerSlate",
    badgeIconSrc: CertifiedCertBadgeSrc,
  },
}

type ComanBadgesProps = {
  badgeKeys: Company['comanBadgeKeys']
}
export function ComanBadges({ badgeKeys }: ComanBadgesProps): JSX.Element | null {
  if (!badgeKeys.length) return null

  return (
    <Grid container display="flex" flexDirection="row" columnSpacing={1} rowSpacing={1}>
      {badgeKeys.map((k) => {
        // @ts-ignore
        const badge = COMAN_BADGES[k]
        return (
          <Grid item display="flex" alignItems="center">
            <Tooltip
              title={badge.description}
              onMouseOver={() => instrumentation.hoverOverBadge(k)}
            >
              <img src={badge.badgeIconSrc} alt={`${k} badge icon`} />
            </Tooltip>
          </Grid>
        )
      })}
    </Grid>
  )
}
