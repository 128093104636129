import type { ErrorType } from '@partnerslate/radio-dispatch'
import {
  loadStripe,
  PaymentMethod,
  Stripe,
  StripeCardNumberElement,
  StripeError,
} from '@stripe/stripe-js'

import config from '@/config'
import Strings from '@/constants'

const createStripeClient = (): Promise<Stripe | null> => {
  if (STORYBOOK) {
    return Promise.resolve(null)
  }
  return loadStripe(config.stripeApiKey) as Promise<Stripe | null>
}

const stripePromise: Promise<Stripe | null> = createStripeClient()

export interface UpdateCardPayload {
  type: 'card'
  card: StripeCardNumberElement
}

export const stripeService = {
  updateCard: async (
    data: UpdateCardPayload,
  ): Promise<[{ paymentMethod?: PaymentMethod; error?: StripeError } | null, ErrorType | null]> => {
    const stripe = await stripePromise
    let error: ErrorType | null = null
    let response = null
    if (stripe) {
      response = await stripe.createPaymentMethod(data)
      if (!response) {
        error = { statusCode: 500, details: Strings.profile.unableToSavePaymentMethod }
      }
    }
    return [response, error]
  },
}

export default stripePromise
