import { Box, Typography } from '@mui/material'
import { User } from 'partnerslate-models'
import React from 'react'
import { connect } from 'react-redux'
import { Redirect, RouteComponentProps } from 'react-router'

import { Loading } from '@/components'
import { Routes } from '@/constants'
import { Engagement } from '@/domain/engagements'
import { instrumentation } from '@/helpers'
import { useEngagementDetails } from '@/helpers/services/queries'
import { StoreState } from '@/redux'
import FourOhFour from '@/screens/four-oh-four'
import selectors from '@/selectors'

import ScreenContainer from '../../screen-container'
import BackLink from '../back-link'
import ExpandedCardGrid from '../expanded-card-grid'
import Todo from './todo'
import { allTodosFor, summaryContentFor } from './todo-content'

export type RouteParams = {
  engagementId: string
}

interface PropsWithUser extends RouteComponentProps<RouteParams> {
  user: User
}

type TodosProps = {
  engagement: Engagement
  isExpanded: boolean
  // user is marked as optional because we also use the Todos in preview-cards.tsx but there we don't need the user
  user?: User
}

export function Todos({ isExpanded, engagement, user }: TodosProps): JSX.Element {
  const todos = allTodosFor(engagement)

  if (isExpanded) instrumentation.viewedToDos(engagement, user)

  return (
    <Box>
      <Typography
        variant={isExpanded ? 'h4' : 'body1'}
        color={isExpanded ? 'text.primary' : 'text.secondary'}
      >
        This list is not necessarily the correct order for your process nor a complete list, but
        will help ensure that you are completing the most important tasks to get your project to
        production.
      </Typography>

      {todos.map((todo, idx) => {
        if (!isExpanded && idx > 2) return null
        const content = isExpanded ? todo.expandedContent : summaryContentFor(todo)
        return (
          <Todo
            key={todo.key}
            todo={todo}
            engagement={engagement}
            isExpanded={isExpanded}
            user={user}
          >
            {content}
          </Todo>
        )
      })}
    </Box>
  )
}

function EngagementTodos({ match, user }: PropsWithUser): JSX.Element {
  const { engagementId } = match.params
  const { data: engagement, isLoading } = useEngagementDetails(engagementId)

  if (isLoading) {
    return <Loading />
  }

  if (!engagement) {
    return <FourOhFour />
  }

  // We dont' want to allow users to see the todos for an engagement that is still in the NDA stage.
  // We dont' take into account engagement.nda_stage because the engagement might have an off platform NDA
  if (engagement.stage === 'nda') {
    return <Redirect to={Routes.EngagementDetailSlug(engagementId)} />
  }

  return (
    <ScreenContainer>
      <BackLink engagement={engagement} />
      <ExpandedCardGrid title="To Do's" engagement={engagement}>
        <Todos isExpanded engagement={engagement} user={user} />
      </ExpandedCardGrid>
    </ScreenContainer>
  )
}

type StateProps = {
  user: User
}

const mapStateToProps = (state: StoreState): StateProps => {
  const user = selectors.user.getUser(state)
  return {
    user,
  }
}

export default connect(mapStateToProps)(EngagementTodos)
