import {
  Badge,
  Box,
  Card,
  CardActionArea,
  CardContent,
  CardHeader,
  Grid,
  IconButton,
} from '@mui/material'
import React from 'react'
import { Link } from 'react-router-dom'

import ContentExplorerOrNothing from '@/components/box-content-explorer'
import Notes from '@/components/notes'
import { Routes } from '@/constants'
import { Engagement } from '@/domain/engagements'
import { MessagesType } from '@/domain/messages'

import { Todos } from '../../expanded-pages/todos'
import { allTodosFor } from '../../expanded-pages/todos/todo-content'

const ARBITRARY_FIXED_HEIGHT_FOR_PREVIEW_CARD = '342px'

type PreviewCardProps = {
  children: React.ReactNode
  route: string
  title: React.ReactNode
  midSizeColumns?: number
}
function PreviewCard({ children, route, title, midSizeColumns = 6 }: PreviewCardProps) {
  const largeSizeColumns = 6

  return (
    <Grid item xs={12} md={midSizeColumns} lg={largeSizeColumns}>
      <Link to={route}>
        <Card
          sx={(theme) => ({
            transition: theme.transitions.create(['transform'], {
              duration: theme.transitions.duration.short,
            }),
            '&:hover': {
              transform: 'translateY(2px)',
            },
          })}
        >
          <CardActionArea disableRipple>
            <CardHeader
              sx={{ paddingBottom: 0 }}
              title={title}
              titleTypographyProps={{ variant: 'h3', color: 'text.primary' }}
            />
            {/* Add overlay to prevent the user from interacting with the preview card
            besides clicking on it and opening an expanded view */}
            <Box position="absolute" height="100%" width="100%" zIndex={1000} top={0} left={0} />
            <CardContent sx={{ height: ARBITRARY_FIXED_HEIGHT_FOR_PREVIEW_CARD }}>
              {children}
            </CardContent>
          </CardActionArea>
        </Card>
      </Link>
    </Grid>
  )
}

type PreviewProps = {
  engagement: Engagement
}

export function DocumentsPreviewCard({ engagement }: PreviewProps): JSX.Element {
  const boxUiPreviewOverrides = {
    canCreateNewFolder: false,
    canDelete: false,
    canDownload: false,
    canPreview: false,
    canRename: false,
    canUpload: false,
  }

  return (
    <PreviewCard route={Routes.EngagementDocumentsSlug(engagement.id)} title="Documents">
      <Box className="hide-box-items">
        <ContentExplorerOrNothing
          boxDetails={engagement.boxAccessDetails}
          engagement={engagement}
          isExpanded={false}
          boxUiOverrides={boxUiPreviewOverrides}
        />
      </Box>
    </PreviewCard>
  )
}

export function TodosPreviewCard({ engagement }: PreviewProps): JSX.Element {
  const todos = allTodosFor(engagement)
  const incompleteTodos = todos.length - engagement.completedTodos.length
  return (
    <PreviewCard
      route={Routes.EngagementTodosSlug(engagement.id)}
      title={
        <>
          To-Do's
          {!!incompleteTodos && (
            <IconButton
              sx={{ ml: 2 }}
              aria-label={`There are ${incompleteTodos} todos left to complete`}
            >
              <Badge
                badgeContent={incompleteTodos}
                sx={{
                  '& .MuiBadge-badge': {
                    color: 'white',
                    backgroundColor: 'secondary.main',
                  },
                }}
              />
            </IconButton>
          )}
        </>
      }
    >
      <Todos isExpanded={false} engagement={engagement} />
    </PreviewCard>
  )
}

type MessagePreviewCard = PreviewProps & {
  title: string
  route: string
  messagesType: MessagesType
}
export function MessagesPreviewCard({
  engagement,
  title,
  route,
  messagesType,
}: MessagePreviewCard): JSX.Element {
  const midSizeColumns = 6
  const channelConnection =
    messagesType === 'intra' ? engagement.intraTeamMessages : engagement.interTeamMessages

  return (
    <PreviewCard route={route} title={title} midSizeColumns={midSizeColumns}>
      {channelConnection && (
        <Notes
          channelConnection={channelConnection!}
          messagesType={messagesType}
          engagement={engagement}
        />
      )}
    </PreviewCard>
  )
}
