/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { useFormikContext } from 'formik'

import { Button, Paper, SecondaryButton } from '@/components'
import type ButtonProps from '@/components/button/types'
import Strings from '@/constants'

import jsStyles from './styles'
import styles from './styles.scss'

export function SubmitButton(
  props: ButtonProps & { emptyOnSubmit?: boolean; validateOnReset?: boolean },
): JSX.Element {
  const { submitForm, resetForm, dirty, isValid, values, initialValues, validateForm } =
    useFormikContext()
  const { emptyOnSubmit, validateOnReset, ...rest } = props
  return (
    <Button
      {...rest}
      type="submit"
      disabled={!dirty || !isValid}
      onClick={() => {
        submitForm().finally(() => {
          if (emptyOnSubmit) {
            resetForm({ values: initialValues })
            if (validateOnReset) {
              validateForm()
            }
          } else {
            resetForm({ values })
          }
        })
      }}
    />
  )
}

SubmitButton.defaultProps = {
  emptyOnSubmit: false,
  validateOnReset: false,
}

function CancelButton({
  testId,
  onCancel,
}: {
  testId?: string
  onCancel?: () => void
}): JSX.Element {
  const { resetForm, initialValues } = useFormikContext()
  return (
    <SecondaryButton
      ariaLabel={Strings.general.cancel}
      title={Strings.general.cancel}
      onClick={() => {
        resetForm({ values: initialValues })
        if (onCancel) onCancel()
      }}
      testId={testId}
    />
  )
}

CancelButton.defaultProps = {
  testId: undefined,
  onCancel: undefined,
}

export function SubmitBar({
  submitTestId,
  cancelTestId,
  emptyOnSubmit,
  showAlways,
  onCancel,
}: {
  submitTestId?: string
  cancelTestId?: string
  emptyOnSubmit?: boolean
  showAlways?: boolean
  onCancel?: () => void
}): JSX.Element {
  const { dirty } = useFormikContext()
  return (
    <Paper className={styles.submitBar} css={jsStyles.submit(dirty || !!showAlways)}>
      <CancelButton testId={cancelTestId} onCancel={onCancel} />
      <SubmitButton
        title={Strings.general.save}
        ariaLabel={Strings.general.save}
        testId={submitTestId}
        emptyOnSubmit={emptyOnSubmit}
      />
    </Paper>
  )
}

SubmitBar.defaultProps = {
  submitTestId: undefined,
  cancelTestId: undefined,
  emptyOnSubmit: false,
  showAlways: false,
  onCancel: undefined,
}

type BackButtonProps = {
  onBack: (data: any) => void
  testId?: string
}

export const BackButton: React.FunctionComponent<BackButtonProps> = ({
  onBack,
  testId,
}: BackButtonProps) => {
  const { values } = useFormikContext()
  return (
    <SecondaryButton
      ariaLabel={Strings.general.back}
      title={Strings.general.back}
      testId={testId}
      onClick={() => {
        onBack(values)
      }}
    />
  )
}

BackButton.defaultProps = {
  onBack: () => {},
  testId: undefined,
}
