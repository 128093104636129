import React from 'react'

import { Input } from '@/components'

import { InputProps } from '.'

function NumberField({ name, placeholder, onChange, ...rest }: InputProps): JSX.Element {
  return <Input {...rest} type="number" placeholder={placeholder} name={name} onChange={onChange} />
}

NumberField.defaultProps = Input.defaultProps

export default NumberField
