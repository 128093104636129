import { css, SerializedStyles } from '@emotion/core'

import PaperProps from './types'

const Styles = {
  paper: (props: Partial<PaperProps>): SerializedStyles => css`
    ${props.variant === 'full-shadow'
      ? `
        box-shadow: 1px 2px 10px 0 rgba(0, 0, 0, 0.15);
        `
      : ''}
  `,
}
export default Styles
