import React, { Component } from 'react'

import styles from './styles.scss'

interface InputErrorProps extends Partial<React.PropsWithChildren<Component>> {
  error: string | null | undefined
}

function InputError({ error }: InputErrorProps): JSX.Element {
  return <span className={styles.error}>{error}</span>
}

export default InputError
