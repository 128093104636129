import { joinStr } from '@clearsummit/carabiners'
import { Box } from '@mui/material'
import { User } from 'partnerslate-models'
import React from 'react'

import { ActionButton, AddImage, TransparentButton } from '@/components'
import { CropArea } from '@/components/image-cropper'
import modal from '@/components/modal/modal-events'
import Strings, { Images, ImageTypes, Metrics } from '@/constants'
import { UpdateUserForm } from '@/forms'
import { UpdateUserFormValues } from '@/forms/update-user'

import EditProfileImage from './edit-profile'
import styles from './styles.scss'

interface YourInfoProps {
  user: User
  submit: (data: UpdateUserFormValues) => void
  deleteAccount: () => void
  uploadImage: (data: { image: File; crop?: CropArea; type: ImageTypes }) => void
  photoMeta: { [key: string]: unknown } | null
  photoPending: boolean
  deleteImage: () => void
}

export function YourInfo({
  user,
  submit,
  deleteAccount,
  uploadImage,
  photoPending,
  photoMeta,
  deleteImage,
}: YourInfoProps): JSX.Element {
  const openCropper = React.useCallback(
    (image: File, aspect: number) => {
      modal.renderModal('cropper', {
        image,
        callback: (data: { image: File; crop?: CropArea }) =>
          uploadImage({ ...data, type: ImageTypes.profile }),
        aspect,
      })
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [user.profilePhotoUrl],
  )
  const confirmDeleteAccount = React.useCallback(() => {
    modal.renderModal('deleteAccount', { successCallback: deleteAccount })
  }, [deleteAccount])
  return (
    <>
      <div className={joinStr(styles.row, styles.flex)}>
        <h1>{Strings.profile.yourInformation}</h1>
        <div className={styles.relativeContainer}>
          <AddImage
            onClick={(image: File) => openCropper(image, Metrics.profilePhoto)}
            minSize={Metrics.minProfileSize}
            pending={photoPending && photoMeta?.type === ImageTypes.profile}
          >
            <EditProfileImage image={user.profilePhotoUrl} />
          </AddImage>
          {user.profilePhotoUrl && (
            <ActionButton
              className={styles.trashcan}
              icon={Images.trashcan}
              onClick={deleteImage}
              ariaLabel={Strings.profile.deleteProfilePhoto}
              iconAlt={Strings.profile.deleteProfilePhoto}
            />
          )}
        </div>
      </div>
      <UpdateUserForm
        handleSubmit={submit}
        initialValues={{
          email: user.email,
          firstName: user.firstName,
          lastName: user.lastName,
          jobTitle: user.jobTitle,
        }}
      />
      <Box display="none">
        <TransparentButton
          className={styles.deleteAccount}
          title={Strings.profile.deleteAccount}
          onClick={confirmDeleteAccount}
          ariaLabel={Strings.profile.deleteAccount}
        />
      </Box>
    </>
  )
}
export default YourInfo
