/* eslint-disable react/no-unused-prop-types */
import { Formik } from 'formik'
import * as React from 'react'

import TestIds from '@/accessibility/test-ids'
import { SubmitBar } from '@/components/base-form/buttons'
import { TextField } from '@/components/base-form/fields'
import FormError from '@/components/base-form/form-error'
import Strings from '@/constants'
import { endpoints } from '@/helpers/services'

import { UpdateUserSchema } from '../schemas'
import styles from './styles.scss'

enum FieldNames {
  email = 'email',
  firstName = 'firstName',
  lastName = 'lastName',
  jobTitle = 'jobTitle',
}

export interface UpdateUserFormValues {
  [FieldNames.email]: string
  [FieldNames.firstName]: string
  [FieldNames.lastName]: string
  [FieldNames.jobTitle]: string
}

interface UpdateUserFormProps {
  handleSubmit: (data: UpdateUserFormValues) => void
  initialValues: UpdateUserFormValues
}

const UpdateUserForm: React.FC<UpdateUserFormProps> = (formProps) => {
  const { handleSubmit, initialValues } = formProps
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={UpdateUserSchema}
      validateOnChange
      validateOnMount
    >
      <>
        <div className={styles.row}>
          <div className={styles.input}>
            <TextField
              name={FieldNames.email}
              label={Strings.auth.signup.inputEmail}
              placeholder={Strings.auth.signup.email}
              testId={TestIds.SignUp.Email}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.input}>
            <TextField
              name={FieldNames.firstName}
              label={Strings.auth.signup.firstName}
              placeholder={Strings.auth.signup.enterFirstName}
              testId={TestIds.SignUp.FirstName}
            />
          </div>
          <div className={styles.input}>
            <TextField
              name={FieldNames.lastName}
              label={Strings.auth.signup.lastName}
              placeholder={Strings.auth.signup.enterLastName}
              testId={TestIds.SignUp.LastName}
            />
          </div>
          <div className={styles.input}>
            <TextField
              name={FieldNames.jobTitle}
              label={Strings.auth.signup.jobTitle}
              placeholder={Strings.auth.signup.enterJobTitle}
              testId={TestIds.SignUp.JobTitle}
            />
          </div>
        </div>
        <FormError formKey={endpoints.updateMe} />
        <SubmitBar />
      </>
    </Formik>
  )
}

export default UpdateUserForm
