import { createSearchObject } from '@clearsummit/carabiners'
import { ApiPayload } from '@partnerslate/radio-dispatch'
import { User } from 'partnerslate-models'
import React from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps } from 'react-router-dom'

import Strings from '@/constants'
import { ResetPasswordChangeForm } from '@/forms'
import services from '@/helpers/services'
import { StoreState } from '@/redux'
import { ResetPasswordChangePayload, resetPasswordChangePayload } from '@/redux/api-payloads'
import selectors from '@/selectors'

import styles from './styles.scss'

type StateProps = {
  // eslint-disable-next-line react/no-unused-prop-types
  user: User | null | undefined
}

type ResetPasswordChangeProps = StateProps & RouteComponentProps

export class ResetPasswordChangeScreen extends React.Component<ResetPasswordChangeProps> {
  resetPassword = (
    data: ResetPasswordChangePayload,
  ): ApiPayload<typeof services, ResetPasswordChangePayload> => {
    const { location } = this.props
    const { token } = createSearchObject(location.search)
    const requestData = { ...data, token }
    return resetPasswordChangePayload(requestData)
  }

  render(): React.ReactElement {
    return (
      <div className={styles.container}>
        <div className={styles.leftContainer}>
          <div className={styles.formContainer}>
            <h1 className={styles.title}>{Strings.auth.resetPasswordChange.title}</h1>
            <p className={styles.subtitle}>{Strings.auth.resetPasswordChange.subtitle}</p>
            <ResetPasswordChangeForm onSubmit={this.resetPassword} />
          </div>
        </div>
        <div className={styles.rightContainer} />
      </div>
    )
  }
}

const mapStateToProps = (state: StoreState): StateProps => ({
  user: selectors.user.getUser(state),
})

export default connect(mapStateToProps)(ResetPasswordChangeScreen)
