/*  eslint-disable camelcase */

import { FieldOption, FieldTypes } from 'partnerslate-models'

const getFieldOptionReferenceData = (fieldType: string): Array<FieldOption> => {
  const rawReferenceData = (window as any)?.partnerSlate?.referenceData || {}
  const rawOptions = rawReferenceData[fieldType] as Array<any>
  return rawOptions.map((raw) => new FieldOption(raw))
}

export const getAllergens = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('allergens')
}

export const getProductCategories = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('product_categories')
}

export const getDistributionTypes = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('distribution_types')
}

export const getBrandStages = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('brand_stages')
}

export const getManufacturerTypes = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('manufacturer_types')
}

// unused reference data?
export const getBusinessTypes = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('business_types')
}

// not really reference data
export const getMatchPreferences = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('match_preferences')
}

export const getCertifications = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('certifications')
}

// unused reference data?
export const getFacilityLocations = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('facility_locations')
}

export const getPackagingFormats = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('packaging_formats')
}

export const getServiceModelOptions = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('service_models')
}

// unused reference data?
export const getProcessingCapabilties = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('processing_capabilities')
}

export const getServicesOffered = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('services_offered')
}

export const getDiverseBackgrounds = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('diverse_backgrounds')
}

export const getDiverseSocialImpacts = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('diverse_social_impacts')
}

// unused reference data?
export const getAdditionalServices = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('additional_services')
}

// unused reference data?
export const getProductionRunSizes = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('production_run_sizes')
}

export const getComanPreviousBrandSizes = (): Array<FieldOption> => {
  return getFieldOptionReferenceData('coman_previous_brand_sizes')
}

export const getFieldOptions = (field: FieldTypes): Array<FieldOption> => {
  switch (field) {
    case FieldTypes.manufacturerType:
      return getManufacturerTypes()
    case FieldTypes.distributionType:
      return getDistributionTypes()
    case FieldTypes.brandStage:
      return getBrandStages()
    case FieldTypes.productCategories:
      return getProductCategories()
    case FieldTypes.facilityLocation:
      return getFacilityLocations()
    case FieldTypes.packagingFormats:
      return getPackagingFormats()
    case FieldTypes.processingCapabilities:
      return getProcessingCapabilties()
    case FieldTypes.certifications:
      return getCertifications()
    case FieldTypes.productionRunSize:
      return getProductionRunSizes()
    case FieldTypes.additionalServices:
      return getAdditionalServices()
    case FieldTypes.servicesOffered:
      return getServicesOffered()
    case FieldTypes.businessType:
      return getBusinessTypes()
    case FieldTypes.matchPreferences:
      return getMatchPreferences()
    case FieldTypes.comanPreviousBrandSizes:
      return getComanPreviousBrandSizes()
    case FieldTypes.diverseBackgrounds:
      return getDiverseBackgrounds()
    case FieldTypes.diverseSocialImpacts:
      return getDiverseSocialImpacts()
    default:
      return []
  }
}
