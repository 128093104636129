import React from 'react'

import { Loading } from '@/components'
import { Engagement } from '@/domain/engagements'
import { usePartneredEngagements } from '@/helpers/services/queries'

import Engagements from './engagements'

export interface Props {
  engagements: Engagement[]
}

export default function ComanEngagementsScreen(): JSX.Element {
  const { data: engagements, isLoading } = usePartneredEngagements()

  if (isLoading || engagements === undefined) {
    return <Loading />
  }

  return <Engagements engagements={engagements} />
}
