import React from 'react'
import { Redirect, RouteComponentProps } from 'react-router'

import { Loading } from '@/components'
import Notes from '@/components/notes'
import { Routes } from '@/constants'
import { MessagesType } from '@/domain/messages'
import { useEngagementDetails } from '@/helpers/services/queries'
import FourOhFour from '@/screens/four-oh-four'

import { getInterTeamTitleByPerspective } from '../getInterTeamTitleByPerspective'
import ScreenContainer from '../screen-container'
import BackLink from './back-link'
import ExpandedCardGrid from './expanded-card-grid'

export type RouteParams = {
  engagementId: string
}

type Props = RouteComponentProps<RouteParams> & {
  messagesType: MessagesType
}

function ExpandedMessages({ match, messagesType }: Props) {
  const { engagementId } = match.params
  const { data: engagement, isLoading } = useEngagementDetails(engagementId)

  if (isLoading) {
    return <Loading />
  }

  if (!engagement) {
    return <FourOhFour />
  }

  // We dont' want to allow users to see the todos for an engagement that is still in the NDA stage.
  // We dont' take into account engagement.nda_stage because the engagement might have an off platform NDA
  if (engagement.stage === 'nda') {
    return <Redirect to={Routes.EngagementDetailSlug(engagementId)} />
  }

  const isIntraChannel = messagesType === 'intra'
  const title = isIntraChannel ? 'Internal Notes' : getInterTeamTitleByPerspective(engagement)
  const channelConnection = isIntraChannel
    ? engagement.intraTeamMessages
    : engagement.interTeamMessages

  return (
    <ScreenContainer>
      <BackLink engagement={engagement} />
      <ExpandedCardGrid title={title} engagement={engagement} isFullHeight>
        <Notes
          channelConnection={channelConnection!}
          isExpanded
          messagesType={messagesType}
          engagement={engagement}
        />
      </ExpandedCardGrid>
    </ScreenContainer>
  )
}

export function EngagementInterMessages(props: RouteComponentProps<RouteParams>): JSX.Element {
  return <ExpandedMessages {...props} messagesType="inter" />
}

export function EngagementIntraNotes(props: RouteComponentProps<RouteParams>): JSX.Element {
  return <ExpandedMessages {...props} messagesType="intra" />
}
