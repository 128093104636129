import { css, SerializedStyles } from '@emotion/core'

import styles from '../../../styles/variables.scss'

const { darkGrey, secondary, primary } = styles
const Styles = {
  anchorVariant: (variant: string | undefined): SerializedStyles => css`
    ${variant === 'secondary'
      ? `
            color: ${secondary};
            `
      : `
            color: ${darkGrey};
            `}
  `,
  disabled: (disabled = false): SerializedStyles => css`
    ${disabled &&
    `
        background-color: #cecece;
        border: 2px solid #cecece;
        cursor: default;
    `}
  `,
  statefulAnchor: (selected: boolean): SerializedStyles => css`
    ${selected &&
    `
        span {
            color: ${primary} !important;
        }
        svg {
            filter: invert(58%) sepia(97%) saturate(351%) hue-rotate(112deg) brightness(94%) contrast(91%);
        }
    `}
  `,
}
export default Styles
