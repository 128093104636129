import { Menu, Transition } from '@headlessui/react'
import { DotsVerticalIcon, MailIcon } from '@heroicons/react/solid'
import * as Sentry from '@sentry/react'
import classNames from 'classnames'
import { Company } from 'partnerslate-models'
import React, { useState } from 'react'

import { Loading, Toast } from '@/components'
import HeaderCardLayout from '@/components/header-card-layout'
import { InvitedUser, TeamMembers, User } from '@/domain/team'
import { instrumentation } from '@/helpers'
import { BackendError, PresentableError, presentableError } from '@/helpers/services/backendError'
import {
  cancelCompanyInvite,
  resendCompanyInvite,
  sendCompanyInvite,
} from '@/helpers/services/backendGateway'
import { useTeamMembers } from '@/helpers/services/queries'

const DEFAULT_INVITE_MESSAGE = `Join our team to collaborate on upcoming projects and view partner documents.`

type Props = {
  company: Company
}

export default function TeamScreen({
  company: { slug: companySlug, id: companyId },
}: Props): JSX.Element {
  const { data, isLoading, refetch } = useTeamMembers(companySlug)

  if (isLoading || data === undefined) {
    return <Loading />
  }

  return (
    <Team
      data={data}
      companySlug={companySlug}
      companyId={companyId}
      onTeamMembersAreStale={refetch}
    />
  )
}

type TeamProps = {
  data: TeamMembers
  companySlug: string
  companyId: string
  onTeamMembersAreStale: () => void
}

// eslint-disable-next-line max-lines-per-function
export function Team({
  data,
  companySlug,
  companyId,
  onTeamMembersAreStale,
}: TeamProps): JSX.Element {
  const [email, setEmail] = useState('')
  const [inviteError, setInviteError] = useState<PresentableError>()
  const [inviteMessage, setInviteMessage] = useState(DEFAULT_INVITE_MESSAGE)

  const handleSendingInvite = async () => {
    setInviteError(undefined)
    try {
      const didSendInviteSuccessfully = await sendCompanyInvite(companySlug, email, inviteMessage)

      if (didSendInviteSuccessfully) {
        instrumentation.sentInviteSuccessfully(companyId, email)
        setEmail('')
        Toast.success('invite sent', `an invite email has been sent to ${email}`)
        onTeamMembersAreStale()
      }
    } catch (ex) {
      if (ex instanceof BackendError) {
        setInviteError(ex)
      } else {
        Sentry.captureException(ex)
        setInviteError(presentableError('An error occurred'))
      }
    }
  }

  const resendInviteTo = async (user: InvitedUser) => {
    await resendCompanyInvite(user.inviteId)
    Toast.success('invite re-sent', 'a new invite email has been sent')
  }

  const handleCancelInvite = async (user: InvitedUser) => {
    const didCancelSuccessfully = await cancelCompanyInvite(user.inviteId)

    if (didCancelSuccessfully) {
      Toast.success('invite canceled')
    }

    onTeamMembersAreStale()
  }

  return (
    <HeaderCardLayout
      title="Your team"
      desc="See who's a member of your team, and invite new members."
    >
      <section className="pb-6 space-y-10">
        <div className="">
          <Subhead>Invite team member (one at a time)</Subhead>
          <div className="px-6 mt-3 flex">
            <div className="flex-grow">
              <input
                type="email"
                className="block w-full shadow-sm focus:ring-green-500 focus:ring focus:outline-none sm:text-sm border border-gray-300 rounded-md px-2 py-2"
                placeholder="Email address"
                value={email}
                onChange={(e) => setEmail(e.target.value)}
              />
            </div>
          </div>
          <div className="text-sm px-6 mt-4 text-gray-400">Message to invitee (optional):</div>
          <div className="px-6 flex">
            <textarea
              className="w-full shadow-sm focus:ring-green-500 focus:ring focus:outline-none sm:text-sm text-gray-600 border border-gray-300 rounded-md px-2 py-2"
              rows={4}
              value={inviteMessage}
              onChange={(e) => setInviteMessage(e.target.value)}
            />
          </div>
          <div className="px-6 mt-2 flex items-center justify-end">
            <ErrorOrNothing error={inviteError} />
            <span className="ml-3">
              <button
                type="button"
                onClick={handleSendingInvite}
                className="bg-green-400 hover:bg-green-500 inline-flex items-center px-4 py-2 border-transparent rounded-md focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-green-500"
              >
                <span className="text-sm font-medium text-white">Invite</span>
                <MailIcon className="ml-2 -mr-1 h-5 w-5 text-white" aria-hidden="true" />
              </button>
            </span>
          </div>
        </div>
        {data.members.length > 0 && (
          <div className="">
            <Subhead>Current team members</Subhead>
            <ul className="px-6">
              {data.members.map((user) => (
                <FullUserRow key={user.email} user={user} />
              ))}
            </ul>
          </div>
        )}
        {data.invites.length > 0 && (
          <div className="">
            <Subhead>Pending invites</Subhead>
            <ul className="px-6">
              {data.invites.map((user) => (
                <InvitedUserRow
                  key={user.inviteId}
                  user={user}
                  onResendInvite={() => resendInviteTo(user)}
                  onCancelInvite={() => handleCancelInvite(user)}
                />
              ))}
            </ul>
          </div>
        )}
      </section>
    </HeaderCardLayout>
  )
}

function ErrorOrNothing({ error }: { error: PresentableError | undefined }): JSX.Element | null {
  if (!error) {
    return null
  }

  return <span className="text-red-500 text-md">{error.humanReadableError()}</span>
}

function Subhead({ children }: { children: React.ReactNode }): JSX.Element {
  return <div className="text-md px-6 mt-4 text-gray-400">{children}</div>
}

function FullUserRow({ user }: { user: User }): JSX.Element {
  return (
    <li>
      <div className="min-w-0 w-full py-0 flex">
        <div className="flex-grow">
          <p className="text-xl mb-1 font-medium text-gray-900 truncate">{user.displayName}</p>
          <p className="mt-0 flex items-center text-base text-gray-500">
            <span className="truncate">{user.email}</span>
          </p>
        </div>
      </div>
    </li>
  )
}

type InvitedUserRowProps = {
  user: InvitedUser
} & InviteMenuProps

function InvitedUserRow({
  user,
  onResendInvite,
  onCancelInvite,
}: InvitedUserRowProps): JSX.Element {
  return (
    <li>
      <div className="group min-w-0 w-full py-1 flex">
        <div className="flex-grow">
          <p className="text-md font-medium text-gray-500 truncate">{user.email}</p>
        </div>
        <div>
          <Badge>invited</Badge>
        </div>
        <InviteMenu onResendInvite={onResendInvite} onCancelInvite={onCancelInvite} />
      </div>
    </li>
  )
}

function Badge({ children }: { children: React.ReactNode }): JSX.Element {
  return (
    <p className="inline-block py-1 px-3 mr-3 rounded-full text-xs bg-green-100 text-green-800 uppercase font-bold tracking-normal">
      {children}
    </p>
  )
}

type InviteMenuProps = {
  onResendInvite: () => void
  onCancelInvite: () => void
}

function InviteMenu({ onResendInvite, onCancelInvite }: InviteMenuProps) {
  return (
    <Menu as="div" className="relative inline-block text-left flex flex-col justify-center">
      <div>
        <Menu.Button className="flex items-center text-gray-400 hover:text-gray-600 rounded-full focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-offset-gray-100 focus:ring-green-500">
          <span className="sr-only">Open options</span>
          <DotsVerticalIcon className="h-5 w-5" aria-hidden="true" />
        </Menu.Button>
      </div>
      <Transition
        as={React.Fragment}
        enter="transition ease-out duration-100"
        enterFrom="transform opacity-0 scale-95"
        enterTo="transform opacity-100 scale-100"
        leave="transition ease-in duration-75"
        leaveFrom="transform opacity-100 scale-100"
        leaveTo="transform opacity-0 scale-95"
      >
        <Menu.Items className="z-10 origin-top-right absolute right-0 mt-2 w-56 rounded-md shadow-lg bg-white ring-1 ring-black ring-opacity-5 focus:outline-none">
          <div className="py-1">
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full text-left px-4 py-2 text-sm',
                  )}
                  onClick={() => onCancelInvite()}
                >
                  Cancel invite
                </button>
              )}
            </Menu.Item>
            <Menu.Item>
              {({ active }) => (
                <button
                  type="button"
                  className={classNames(
                    active ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                    'w-full text-left px-4 py-2 text-sm',
                  )}
                  onClick={() => onResendInvite()}
                >
                  Re-send invite
                </button>
              )}
            </Menu.Item>
          </div>
        </Menu.Items>
      </Transition>
    </Menu>
  )
}
