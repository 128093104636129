/* eslint-disable max-lines-per-function */
import { Box, Button, Grid, Tooltip, Typography } from '@mui/material'
import { ButtonWithLoading } from '@partnerslate/ui-components'
import { differenceInHours } from 'date-fns'
import React from 'react'
import { connect } from 'react-redux'

import EmailLink from '@/components/email-link'
import EmailLinkSupport from '@/components/email-link-support'
import { Engagement, hasPartySigned, needsOurSignature } from '@/domain/engagements'
import { instrumentation } from '@/helpers'
import { useSendNdaReminder } from '@/helpers/services/queries'
import { StoreState } from '@/redux'
import selectors from '@/selectors'

import ExpandedCardGrid from '../expanded-pages/expanded-card-grid'

type Props = {
  engagement: Engagement
  isComan?: boolean
}

function TextForUnsigned() {
  return (
    <>
      <Typography color="text.primary" variant="h3" paddingBottom={2}>
        The NDA needs to be signed
      </Typography>

      <p>
        To move forward with this engagement we need you and the other party to sign an NDA. We have
        sent you the NDA via email using a service called Dropbox Sign. You can resend it by
        clicking the button below.
      </p>
    </>
  )
}

function TextForNeedsOurSignature() {
  return (
    <>
      <Typography color="text.primary" variant="h3" paddingBottom={2}>
        You still need to sign the NDA
      </Typography>

      <p>
        To move forward with this engagement you must sign the NDA. We have sent you the NDA via
        email using a service called Dropbox Sign. You can resend it by clicking the button below.
      </p>
    </>
  )
}

function TextForNeedsTheirSignature() {
  return (
    <>
      <Typography color="text.primary" variant="h3" paddingBottom={2}>
        The other party still needs to sign the NDA
      </Typography>

      <p>To move forward with this engagement, the other party must sign the NDA.</p>
    </>
  )
}

type TextForProps = {
  engagement: Engagement
}

function TextFor({ engagement }: TextForProps) {
  if (engagement.ndaStage === 'unsigned') {
    return <TextForUnsigned />
  }
  if (needsOurSignature(engagement)) {
    return <TextForNeedsOurSignature />
  }

  return <TextForNeedsTheirSignature />
}

function EngagementStageNda({ engagement, isComan }: Props): JSX.Element {
  const sendNdaReminder = useSendNdaReminder()
  const stillNeedsOurSignature = needsOurSignature(engagement)

  if (!engagement.nda) {
    return <section />
  }

  const handleDownload = () => {
    instrumentation.downloadedNDA(engagement.id)
  }

  // Hellosign has a limitation of one reminder per hour: https://developers.hellosign.com/api/reference/operation/signatureRequestRemind/
  const isReadyToSendReminder = (sentAt: Date | null) => {
    // If no reminder has been sent yet we are good to go
    if (sentAt === null) return true
    const hoursDifference = differenceInHours(new Date(), sentAt)
    return hoursDifference >= 1
  }

  const canSendReminder = isReadyToSendReminder(engagement.nda.remindedAt)
  const handleResendNda = () => {
    sendNdaReminder.mutate({ engagementId: engagement.id })
  }

  const textStyles = (perspective: 'brand' | 'partner') => {
    const hasSigned = hasPartySigned(engagement, perspective)
    const color = hasSigned ? 'text.primary' : 'error'
    const fontWeight = hasSigned ? 'regular' : 'bold'
    return { color, fontWeight }
  }

  const { brand, coman, nda } = engagement
  return (
    <ExpandedCardGrid>
      <TextFor engagement={engagement} />
      <p>
        If changes to the NDA are required, please contact the other party to discuss. Once you have
        an agreed upon version, email a copy to <EmailLinkSupport /> and we will send the revised
        document for digital signing.
      </p>

      <Typography variant="h4" sx={{ mb: 2 }}>
        Signature Status:
      </Typography>

      <Typography sx={{ ml: 1 }}>{brand.companyName}</Typography>
      <Typography {...textStyles('brand')} sx={{ ml: 2 }}>
        Signature: {hasPartySigned(engagement, 'brand') ? 'Completed' : 'Pending'}
      </Typography>
      {!isComan && nda.signers.brand?.email ? (
        <Typography sx={{ ml: 2 }}>
          <strong>Sent to:</strong> <EmailLink email={nda.signers.brand.email} />
        </Typography>
      ) : null}

      <Typography sx={{ ml: 1, mt: 1 }}>{coman.companyName}</Typography>
      <Typography {...textStyles('partner')} sx={{ ml: 2 }}>
        Signature: {hasPartySigned(engagement, 'partner') ? 'Completed' : 'Pending'}
      </Typography>
      {isComan && nda.signers.partner?.email ? (
        <Typography sx={{ ml: 2 }}>
          <strong>Sent to:</strong> <EmailLink email={nda.signers.partner.email} />
        </Typography>
      ) : null}

      <Typography variant="h4" sx={{ mt: 3 }}>
        Next steps after signing:
      </Typography>
      <p>Once both of you have signed the NDA you will be given next steps on how to connect.</p>
      <Grid container>
        <Grid item xs={6}>
          {nda.pdfUrl ? (
            <Button href={nda.pdfUrl} onClick={handleDownload}>
              Download a copy
            </Button>
          ) : null}
        </Grid>
        {stillNeedsOurSignature ? (
          <Grid item xs={6} display="flex" justifyContent="end">
            <Tooltip
              title={
                canSendReminder
                  ? ''
                  : 'We only allow one NDA resend per hour. It seems one was sent recently by you or the other party. Please try again later.'
              }
            >
              <Box>
                <ButtonWithLoading
                  onClick={handleResendNda}
                  disabled={!canSendReminder}
                  isLoading={sendNdaReminder.isLoading}
                >
                  Resend NDA
                </ButtonWithLoading>
              </Box>
            </Tooltip>
          </Grid>
        ) : null}
      </Grid>
    </ExpandedCardGrid>
  )
}

const mapStateToProps = (state: StoreState) => {
  const user = selectors.user.getUser(state)
  const company = selectors.company.getCompany(state, user.company)

  return {
    isComan: company.company_type === 'manufacturer',
  }
}

export default connect(mapStateToProps, null)(EngagementStageNda)
