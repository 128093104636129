import { joinStr } from '@clearsummit/carabiners'
import { Company } from 'partnerslate-models'
import React, { useEffect } from 'react'
import { useHistory } from 'react-router-dom'

import { Button, ExternalAnchor, Paper, SecondaryButton } from '@/components'
import modal from '@/components/modal/modal-events'
import Strings, { Images, MarketingLinks, ProductTypes } from '@/constants'
import { useQuery } from '@/helpers'
import mixpanel from '@/helpers/mixpanel'

import styles from './styles.scss'

function Check() {
  return (
    <svg width="24" height="24">
      <image xlinkHref={Images.check} width="24" height="24" />
    </svg>
  )
}

interface Props {
  checkout: (product: ProductTypes, companySlug: string, mode: 'subscription' | 'setup') => void
  downgrade: (subscriptionId: string, slug: string) => void
  company: Company
}

// eslint-disable-next-line max-lines-per-function
function Subscriptions({ checkout, company, downgrade }: Props): JSX.Element {
  const queryParams = useQuery()
  const success = queryParams.get('success')
  const history = useHistory()
  useEffect(() => {
    if (success === 'true') {
      // @ts-ignore
      modal.renderModal('subscriptionSuccess')
    }
  }, [success, history])

  useEffect(() => {
    mixpanel.viewPremiumSubscriptionPage()
  }, [])

  // eslint-disable-next-line react/no-unstable-nested-components
  function BasicActions() {
    if (company.subscription.isLegacy) {
      return null
    }

    if (company.subscription.isActive && !company.subscription.cancelled) {
      // downgrade button
      return (
        <SecondaryButton
          title={Strings.profile.downgrade}
          ariaLabel={Strings.profile.downgrade}
          onClick={() =>
            modal.renderModal('downgradeSubscription', {
              failCallback: () => downgrade(company.subscription.subscriptionId, company.slug),
            })
          }
        />
      )
    }

    return <p className={styles.currentPlan}>{Strings.profile.currentPlan}</p>
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function FirstPremiumAction() {
    const isPendingCancellation = company.subscription.isActive && company.subscription.cancelled
    if (isPendingCancellation && !company.subscription.isLegacy) {
      // pending expiration message
      return (
        <span className={joinStr(styles.error, styles.errorSpacer)}>
          <svg width="24" height="24">
            <image xlinkHref={Images.info} width="24" height="24" />
          </svg>
          {Strings.profile.expires(company.subscription.exipration)}
        </span>
      )
    }

    return <span className={styles.errorSpacer} />
  }

  // eslint-disable-next-line react/no-unstable-nested-components
  function SecondPremiumAction() {
    if (company.subscription.isLegacy) {
      return (
        <p className={styles.legacyNotice}>
          You currently have a premium plan. Email{' '}
          <a href="mailto:support@partnerslate.com">support@partnerslate.com</a> to change or cancel
          your plan.
        </p>
      )
    }

    if (company.subscription.isActive && !company.subscription.cancelled) {
      return <p className={styles.currentPlan}>{Strings.profile.currentPlan}</p>
    }

    // upgrade button
    return (
      <Button
        title={Strings.profile.upgrade}
        ariaLabel={Strings.profile.upgrade}
        className={styles.upgrade}
        onClick={() =>
          checkout(ProductTypes.BrandSupplierSubscription, company.slug, 'subscription')
        }
      />
    )
  }

  return (
    <div className={joinStr(styles.row, styles.flex)}>
      <h1>{Strings.profile.subscriptions}</h1>
      <p>
        {Strings.profile.manageSubscriptions}
        <ExternalAnchor
          className={styles.a}
          ariaLabel={Strings.profile.learnMoreAboutSubscriptions}
          label={Strings.profile.learnMoreAboutSubscriptions}
          path={MarketingLinks.aboutSubscription}
          newWindow
        />
      </p>
      <div className={styles.planCards}>
        <Paper className={joinStr(styles.planCard, styles.basic)}>
          <div>
            <h1>{Strings.profile.basic}</h1>
            <span>{Strings.profile.foreverFree}</span>
            <hr />
            <h6>
              <Check /> {Strings.profile.detailedBrand}
            </h6>
            <h6>
              <Check /> {Strings.profile.createProject}
            </h6>
            <h6>
              <Check /> {Strings.profile.submitGeneral}
            </h6>
            <h6>
              <Check /> {Strings.profile.accessLearning}
            </h6>
          </div>
          <div className={styles.buttonRow}>
            <span className={styles.errorSpacer} />
            <BasicActions />
          </div>
        </Paper>
        <Paper className={joinStr(styles.planCard, styles.premium)}>
          <div>
            <h1>{Strings.profile.premium}</h1>
            <span>{Strings.profile.premiumDescription}</span>
            <hr />
            <h6>
              <Check /> {Strings.profile.fullSearchAccess}
            </h6>
            <h6>
              <Check /> {Strings.profile.createAdditional}
            </h6>
            <h6>
              <Check /> {Strings.profile.accessPremium}
            </h6>
            <h6>
              <Check /> {Strings.profile.unlimitedInquires}
            </h6>
            <h6>
              <Check /> {Strings.profile.DedicatedSupport}
            </h6>
          </div>
          <div className={styles.buttonRow}>
            <FirstPremiumAction />
            <SecondPremiumAction />
          </div>
        </Paper>
      </div>
    </div>
  )
}

export default Subscriptions
