import { AxiosAdapterErrorResponse } from './api'

export type PresentableError = {
  humanReadableError: () => string
}

export const presentableError = (error: string): PresentableError => {
  return {
    humanReadableError: () => error,
  }
}

type HttpMethod = 'GET' | 'POST'

export class BackendError extends Error implements PresentableError {
  private apiError: AxiosAdapterErrorResponse

  private errors: BackendErrors

  constructor(apiError: AxiosAdapterErrorResponse, method: HttpMethod, url: string) {
    const message = `${apiError.statusCode} ${method} ${url}`
    super(message)
    this.name = 'BackendError'
    this.apiError = apiError
    this.errors = this.apiError.details as BackendErrors

    // we need to set the prototype explicitly, per https://github.com/Microsoft/TypeScript/wiki/Breaking-Changes#extending-built-ins-like-error-array-and-map-may-no-longer-work
    Object.setPrototypeOf(this, BackendError.prototype)
  }

  statusCode(): number {
    return this.apiError.statusCode
  }

  isExceptional(): boolean {
    const UNEXCEPTIONAL_STATUS_CODES = [401, 403]
    return !UNEXCEPTIONAL_STATUS_CODES.includes(this.statusCode())
  }

  humanReadableError(): string {
    if (typeof this.errors === 'string') {
      return this.errors
    }

    if (!this.errors) {
      return 'unknown error'
    }

    return this.errors.map((e) => e.message).join('\n')
  }
}

// this structure is defined in the backend in helpers/viewsets.py#custom_exception_handler
type BackendErrorDetail = {
  field: string
  message: string
}

type BackendErrors = BackendErrorDetail[] | string
