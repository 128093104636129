import { DescriptionOutlined } from '@mui/icons-material'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import { Box, Grid, IconButton, Tooltip, Typography } from '@mui/material'
import { GridColDef, GridComparatorFn, GridEventListener } from '@mui/x-data-grid'
import { Dialog } from '@partnerslate/ui-components'
import { ProjectDetail } from '@partnerslate/ui-components/lib/ProjectDetails/domain/project'
import React, { ReactNode, useEffect, useState } from 'react'

import { ColumnCell, DataGrid } from '@/components/DataGrid'
import type { NonVisibleColumns } from '@/components/DataGrid/useNonVisibleColumns'
import { MoveToProductionConfirmationModal } from '@/components/MoveToProductionConfirmationModal'
import { Routes } from '@/constants'
import type { EngagementProject, EngagementStatusDisplay } from '@/domain/engagements'
import {
  ActiveEngagementStagesWithNda,
  AllPossibleComanEngagementStages,
  Engagement,
  ENGAGEMENT_STATUSES_BY_COMAN,
  formatProjectToProjectDetailContent,
  isEngagementInactive,
} from '@/domain/engagements'
import { instrumentation } from '@/helpers'
import history from '@/helpers/history'

import ScreenContainer from '../engagement-details/screen-container'
import ProjectDetailModal from './project-detail-modal'
import { StatusCell } from './StatusCell'

type EngagementsGridProps = {
  columns: GridColDef[]
  rows: GridRowDef[]
}

function EngagementsGrid({ columns, rows }: EngagementsGridProps): JSX.Element {
  const handleRowClick: GridEventListener<'rowClick'> = (params) => {
    history.push(Routes.EngagementDetailSlug(params.row.id))
  }

  return (
    <DataGrid<GridRowDef>
      columns={columns}
      nonVisibleColumns={nonVisibleColumns}
      rows={rows}
      onRowClick={handleRowClick}
    />
  )
}

type GridRowDef = {
  id: string
  projectName: string
  createdAt: Date
  companyName: string
  companyWebsite: string
  contactName: string
  contactEmail: string
  engagementStatus: EngagementStatusDisplay
  project: EngagementProject
  engagement: Engagement
}

const nonVisibleColumns: NonVisibleColumns = {
  tablet: {
    created: false,
    contactInfo: false,
  },
  mobile: {
    companyInfo: false,
  },
}

const statusComparator: GridComparatorFn<AllPossibleComanEngagementStages> = (v1, v2) => {
  const statusOrder = AllPossibleComanEngagementStages
  return statusOrder.indexOf(v1) - statusOrder.indexOf(v2)
}

type EngagementsProps = {
  engagements: Engagement[]
}
// eslint-disable-next-line max-lines-per-function
export default function Engagements({ engagements }: EngagementsProps): JSX.Element {
  useEffect(() => instrumentation.viewedEngagementsForCompany(), [])
  const [engagementToMoveToProduction, setEngagementToMoveToProduction] =
    useState<Engagement | null>(null)

  const [project, setProject] = useState<ProjectDetail | null>(null)
  const [engagementStatus, setEngagementStatus] = useState<EngagementStatusDisplay>('nda')
  const [isStatusInfoModalOpen, setIsStatusInfoModalOpen] = useState(false)

  const showProjectDetail = (
    project: EngagementProject,
    engagementStatus: EngagementStatusDisplay,
  ) => {
    if (project) {
      const formattedProject = formatProjectToProjectDetailContent(project)
      setProject(formattedProject)
      setEngagementStatus(engagementStatus)
    }
  }

  const columns: GridColDef<GridRowDef>[] = [
    {
      field: 'projectName',
      headerName: 'Project name',
      flex: 1,
      minWidth: 220,
      disableColumnMenu: true,
      renderCell: (params) => {
        const handleClick = (event: Event) => {
          event.stopPropagation()
          showProjectDetail(params.row.project, params.row.engagementStatus)
        }
        return <CellLink onClick={handleClick}>{params.value}</CellLink>
      },
    },
    {
      field: 'created',
      headerName: 'Created',
      flex: 1,
      minWidth: 150,
      disableColumnMenu: true,
      type: 'date',
      valueGetter: (params) => params.row.createdAt,
      renderCell: (params) => <ColumnCell value={params.row.createdAt.toLocaleDateString()} />,
    },
    {
      field: 'companyInfo',
      headerName: 'Company name',
      flex: 1,
      minWidth: 250,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.companyName,
      renderCell: (params) => (
        <Box>
          <ColumnCell value={params.row.companyName} />
          <ColumnCell value={params.row.companyWebsite} />
        </Box>
      ),
    },
    {
      field: 'contactInfo',
      headerName: 'Primary contact',
      flex: 1,
      minWidth: 300,
      disableColumnMenu: true,
      valueGetter: (params) => params.row.contactName,
      renderCell: (params) => {
        if (params.row.engagementStatus === 'nda') {
          return 'Pending'
        }

        return (
          <Box>
            <ColumnCell value={params.row.contactName} />
            <ColumnCell value={params.row.contactEmail} />
          </Box>
        )
      },
    },
    {
      field: 'engagementStatus',
      headerName: 'Engagement status',
      flex: 1,
      minWidth: 140,
      disableColumnMenu: true,
      sortable: false,
      renderHeader: () => (
        <>
          <Typography variant="body2" fontWeight={700}>
            Status
          </Typography>
          <IconButton onClick={() => setIsStatusInfoModalOpen(true)}>
            <InfoOutlinedIcon color="primary" />
          </IconButton>
        </>
      ),
      sortComparator: statusComparator,
      renderCell: (params) => (
        <StatusCell
          engagement={params.row.engagement}
          setEngagementToMoveToProduction={setEngagementToMoveToProduction}
        />
      ),
    },
  ]

  const sortedEngagements = preSortEngagements(engagements)
  const rows: GridRowDef[] = sortedEngagements.map((engagement) => ({
    id: engagement.id,
    projectName: engagement.project.name,
    createdAt: engagement.createdAt,
    companyName: engagement.brand.companyName,
    companyWebsite: engagement.brand.website,
    contactName: engagement.brand.contactName,
    contactEmail: engagement.brand.contactEmail,
    engagementStatus: engagement.stage,
    project: engagement.project,
    engagement,
  }))

  return (
    <ScreenContainer>
      <Grid container>
        <Grid item xs={12} sm={6}>
          <Typography variant="h3" mt={2} ml={1}>
            Engagements
          </Typography>
        </Grid>
      </Grid>

      <EngagementsGrid columns={columns} rows={rows} />
      <ProjectDetailModal
        project={project}
        engagementStatus={engagementStatus}
        onClose={() => setProject(null)}
      />
      <MoveToProductionConfirmationModal
        engagementToMoveToProduction={engagementToMoveToProduction}
        setEngagementToMoveToProduction={setEngagementToMoveToProduction}
      />
      <StatusInfoModal isOpen={isStatusInfoModalOpen} setIsOpen={setIsStatusInfoModalOpen} />
    </ScreenContainer>
  )
}

export function preSortEngagements(engagements: Engagement[]): Engagement[] {
  const stages = ActiveEngagementStagesWithNda as ReadonlyArray<string>
  const sortedEngagments = [...engagements].sort((a, b) => {
    const aIsInactive = isEngagementInactive(a)
    const bIsInactive = isEngagementInactive(b)

    // If we are comparing two engagements that are active
    if (!aIsInactive && !bIsInactive) {
      // sort first by descending stage, then by descending created at
      // based on https://stackoverflow.com/a/46256174/53529
      return (
        stages.indexOf(b.stage) - stages.indexOf(a.stage) ||
        b.createdAt.getTime() - a.createdAt.getTime()
      )
    }
    // otherwise, if one of the engagement is inactive, place that one after an
    // active one
    if (aIsInactive && !bIsInactive) return 1
    if (!aIsInactive && bIsInactive) return -1
    // and if two enagements are inactive, place the latest engagement first
    if (aIsInactive && bIsInactive) {
      return b.createdAt.getTime() - a.createdAt.getTime()
    }
    return 0
  })

  return sortedEngagments
}

type CellLinkProps = {
  children: ReactNode
  onClick: (event: any) => void
}

function CellLink({ children, onClick }: CellLinkProps) {
  return (
    <Grid container display="flex" flexDirection="row" alignItems="center">
      <Grid item xs={10}>
        <Typography
          variant="body2"
          mr={1}
          maxWidth={200}
          sx={{ textOverflow: 'ellipsis', overflowX: 'hidden' }}
        >
          {children}
        </Typography>
      </Grid>
      <Grid item xs={2}>
        <Tooltip title="Project details">
          <IconButton color="primary" onClick={onClick}>
            <DescriptionOutlined />
          </IconButton>
        </Tooltip>
      </Grid>
    </Grid>
  )
}

type EngagementStatusDescriptionsProps = {
  isOpen: boolean
  setIsOpen: (b: boolean) => void
}

function StatusInfoModal({ isOpen, setIsOpen }: EngagementStatusDescriptionsProps) {
  return (
    <Dialog title="Engagement Statuses" open={isOpen} onClose={() => setIsOpen(false)}>
      <ul>
        {AllPossibleComanEngagementStages.map((stage) => {
          const stageDisplay = ENGAGEMENT_STATUSES_BY_COMAN[stage]
          if (stageDisplay.description && stage !== 'nda') {
            return (
              <li key={stageDisplay.name}>
                <Typography component="span">
                  <Typography fontWeight="bold" component="span" color="black">
                    {stageDisplay.name}:{' '}
                  </Typography>
                  {stageDisplay.description}
                </Typography>
              </li>
            )
          }
          return null
        })}
      </ul>
    </Dialog>
  )
}
