/** @jsx jsx */
/** @jsxFrag React.Fragment */
import { jsx } from '@emotion/core'
import { Formik } from 'formik'
import * as React from 'react' // eslint-disable-line @typescript-eslint/no-unused-vars

import TestIds from '@/accessibility/test-ids'
import { SubmitBar } from '@/components/base-form/buttons'
import { PasswordField } from '@/components/base-form/fields'
import FormError from '@/components/base-form/form-error'
import Strings from '@/constants'
import { endpoints } from '@/helpers/services'

import { ChangePasswordSchema } from '../schemas'
import styles from './styles.scss'

enum FieldNames {
  oldPassword = 'oldPassword',
  password = 'password',
  confirmPassword = 'confirmPassword',
}

export interface ChangePasswordFormValues {
  [FieldNames.oldPassword]: string
  [FieldNames.password]: string
  [FieldNames.confirmPassword]: string
}

interface ChangePasswordFormProps {
  handleSubmit: (data: ChangePasswordFormValues) => void
}

const initialValues = {
  [FieldNames.oldPassword]: '',
  [FieldNames.password]: '',
  [FieldNames.confirmPassword]: '',
}

function ChangePasswordForm(formProps: ChangePasswordFormProps): JSX.Element {
  const { handleSubmit } = formProps
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={handleSubmit}
      validationSchema={ChangePasswordSchema}
      validateOnChange
      validateOnMount
    >
      <>
        <div className={styles.row}>
          <div className={styles.input}>
            <PasswordField
              name={FieldNames.oldPassword}
              label={Strings.profile.currentPassword}
              placeholder={Strings.profile.enterYourCurrentPassword}
              testId={TestIds.User.CurrentPassword}
            />
          </div>
        </div>
        <div className={styles.row}>
          <div className={styles.input}>
            <PasswordField
              name={FieldNames.password}
              label={Strings.profile.newPassword}
              placeholder={Strings.profile.enterYourNewPassword}
              testId={TestIds.User.Password}
            />
          </div>
          <div className={styles.input}>
            <PasswordField
              name={FieldNames.confirmPassword}
              label={Strings.profile.confirmNewPassword}
              placeholder={Strings.profile.confirmYourNewPassword}
              testId={TestIds.User.ConfirmPassword}
            />
          </div>
        </div>
        <FormError formKey={endpoints.changePassword} />
        <SubmitBar submitTestId={TestIds.User.Submit} emptyOnSubmit />
      </>
    </Formik>
  )
}

export default ChangePasswordForm
