/** @jsx jsx */
import { jsx } from '@emotion/core'
import * as React from 'react'

import InputError from '../input/input-error'
import InputLabel from '../input/input-label'
import jsStyles from '../input/styles'
import commonStyles from '../input/styles.scss'
import { InputContainer } from './styles'
import styles from './styles.scss'

interface RequiredProps {
  name: string
  placeholder: string | undefined
  onChange: (value: React.ChangeEvent<HTMLTextAreaElement>) => void
}

type DefaultProps = {
  ariaLabel: string
  error: string
  label: string
  required?: boolean
  className?: string | undefined
  value?: string | undefined
  onFocus: ((e: React.FocusEvent<HTMLTextAreaElement>) => void) | undefined
  onBlur: ((e: React.FocusEvent<HTMLTextAreaElement>) => void) | undefined
  fullWidth: boolean
  noMargin: boolean
  autoGrow: boolean
  rows: number
  maxLength?: number
  showCounter: boolean
  testId: string | undefined
}

type Props = RequiredProps & Partial<DefaultProps>

type State = {
  rows: number
  textLength: number
}

class TextArea extends React.Component<Props, State> {
  inputRef: HTMLTextAreaElement | null | undefined

  static defaultProps: DefaultProps = {
    ariaLabel: '',
    error: '',
    label: '',
    required: false,
    onFocus: undefined,
    onBlur: undefined,
    value: undefined,
    className: undefined,
    testId: undefined,
    fullWidth: false,
    noMargin: false,
    autoGrow: false,
    rows: 3,
    maxLength: undefined,
    showCounter: false,
  }

  constructor(props: Props) {
    super(props)

    const defaultState = { textLength: props.value?.length ?? 0 }

    if (props.autoGrow) {
      this.autoGrow()
      this.state = { ...defaultState, rows: 1 }
    } else {
      this.state = { ...defaultState, rows: props.rows ? props.rows : TextArea.defaultProps.rows }
    }
  }

  onFocus = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    const { onFocus } = this.props
    if (onFocus) {
      onFocus(e)
    }
  }

  onBlur = (e: React.FocusEvent<HTMLTextAreaElement>): void => {
    const { onBlur } = this.props
    if (onBlur) {
      onBlur(e)
    }
  }

  onChange = (e: React.ChangeEvent<HTMLTextAreaElement>): void => {
    const { autoGrow, showCounter, onChange } = this.props

    if (showCounter) {
      this.setState({ textLength: e.target.value.length })
    }

    if (autoGrow) {
      this.autoGrow()
    }

    onChange(e)
  }

  focus = (): void => {
    if (this.inputRef) {
      this.inputRef.focus()
    }
  }

  renderError = (): string | null => {
    const { error } = this.props
    if (error) {
      return error
    }
    return null
  }

  autoGrow = (): void => {
    if (this.inputRef) {
      const minRows = 1
      const maxRows = 3

      const previousRows = this.inputRef.rows
      this.inputRef.rows = minRows // reset number of rows in textarea

      const currentRows = this.inputRef.value.split('\n').length

      if (currentRows === previousRows) {
        this.inputRef.rows = currentRows
      }

      if (currentRows >= maxRows) {
        this.inputRef.rows = maxRows
        this.inputRef.scrollTop = this.inputRef.scrollHeight
      }

      this.setState({
        rows: currentRows < maxRows ? currentRows : maxRows,
      })
    }
  }

  counterRender = (textLength: number, maxLength: number): JSX.Element => (
    <p className={styles.textareaCounter}>{`${textLength} / ${maxLength}`}</p>
  )

  render(): JSX.Element {
    const {
      name,
      ariaLabel,
      label,
      required,
      fullWidth,
      noMargin,
      autoGrow,
      testId,
      maxLength,
      showCounter,
      error,
      ...rest
    } = this.props
    const { rows, textLength } = this.state
    return (
      <InputContainer
        className={commonStyles.inputContainer}
        fullWidth={fullWidth}
        noMargin={noMargin}
        autoGrow={autoGrow}
        counterVisible={showCounter}
      >
        <InputLabel label={label} required={required} inputName={name} />
        <textarea
          ref={(ref) => {
            this.inputRef = ref
          }}
          {...rest}
          aria-label={ariaLabel}
          id={name}
          name={name}
          className={styles.baseStyle}
          css={jsStyles.input({ icon: null, error: !!error })}
          onFocus={this.onFocus}
          onBlur={this.onBlur}
          onChange={this.onChange}
          rows={rows}
          maxLength={maxLength}
          data-testid={testId}
        />
        {maxLength && showCounter && this.counterRender(textLength, maxLength)}
        <InputError error={this.renderError()} />
      </InputContainer>
    )
  }
}

export default TextArea
