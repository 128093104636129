import { User } from 'partnerslate-models'

import { StoreState } from '../redux'
import { UserStoreState } from '../redux/user'

const getUserStore = (store: StoreState): UserStoreState => store.user

const getUser = (store: StoreState): User => getUserStore(store).user

const getAccounts = (store: StoreState): Record<string, User> => getUserStore(store).accounts

export default {
  getUser,
  getAccounts,
}
