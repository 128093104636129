import { toast } from 'react-toastify'

import Strings, { Images } from '@/constants'

import styles from './styles.scss'
import Toast, { ToastCloseButton } from './toasts'

const success = (title: string = Strings.general.success, text = ''): void => {
  const options = {
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 2500,
    className: styles.successContainer,
    bodyClassName: styles.body,
    pauseOnHover: true,
    closeButton: ToastCloseButton,
  }

  toast(Toast({ title, text, icon: Images.toastSuccess }), options)
}

const error = (
  title: string = Strings.general.error,
  text: string = Strings.general.unableToCompleteAction,
): void => {
  const options = {
    hideProgressBar: true,
    position: toast.POSITION.BOTTOM_LEFT,
    autoClose: 2500,
    className: styles.errorContainer,
    bodyClassName: styles.body,
    pauseOnHover: true,
    closeButton: ToastCloseButton,
  }

  toast(Toast({ title, text, icon: Images.toastError }), options)
}

export default {
  success,
  error,
}
