import { css, SerializedStyles } from '@emotion/core'

import styles from './styles.scss'
import CheckboxProps from './types'

const Styles = {
  span: (props: CheckboxProps): SerializedStyles => css`
    ${props.error
      ? `
          border: 1px solid ${styles.red};
        `
      : `
          border: 1px solid ${styles.lightGrey};
        `};
  `,
}

export default Styles
