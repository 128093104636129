import { Company } from 'partnerslate-models'
import React from 'react'

import { Paper } from '@/components'
import { CropArea } from '@/components/image-cropper'
import { ImageTypes, Metrics } from '@/constants'

import Banner from './banner'
import Photos from './company-photos'
import EditableAvatar from './editable-avatar'
import EditableCompanyDetails from './editable-company-details'
import EditingButtons from './editing-buttons'
import styles from './styles.scss'

type Props = {
  company: Company
  userCompany: Company
  editing: boolean
  setEditing: (editing: boolean) => void
  openCropper: (image: File, aspect: number) => void
  openProfileCropper: (image: File, aspect: number) => void
  deletePhoto: (id: string, type: ImageTypes, slug: string) => void
  deletePending: boolean
  uploadBannerImage: (data: {
    image: File
    crop?: CropArea
    type: ImageTypes
    companySlug: string
  }) => void
  photoPending: boolean
  photoMeta: { [key: string]: unknown } | null
  deleteMeta: { [key: string]: unknown } | null
}

function EditableDetailsFields(props: Props): JSX.Element {
  const {
    company,
    userCompany,
    editing,
    setEditing,
    openProfileCropper,
    openCropper,
    deletePhoto,
    deletePending,
    photoPending,
    photoMeta,
    deleteMeta,
    uploadBannerImage,
  } = props

  const isMyCompany = company.id === userCompany.id

  return (
    <Paper className={styles.content}>
      <header>
        <Banner
          image={company.bannerPhotoUrl}
          editing={editing}
          addImage={(image: File) => openCropper(image, Metrics.bannerAspectRatio)}
          pending={photoPending && photoMeta?.type === ImageTypes.banner}
          deleteImage={() => deletePhoto(company.bannerPhotoId, ImageTypes.banner, company.slug)}
        />
      </header>
      <main className={styles.mainSection}>
        <div className={styles.contentIntro}>
          <EditableAvatar
            image={company.profilePhotoUrl}
            onClick={(image: File) => openProfileCropper(image, Metrics.profilePhoto)}
            editing={editing}
            pending={photoPending && photoMeta?.type === ImageTypes.companyProfile}
            deleteImage={() =>
              deletePhoto(company.profilePhotoId, ImageTypes.companyProfile, company.slug)
            }
          />
          <div className={styles.profileBtnArea}>
            <EditingButtons
              setEditing={setEditing}
              visible={company.isAdmin && isMyCompany}
              editing={editing}
            />
          </div>
        </div>
        <div>
          <EditableCompanyDetails
            isOwnEditableCompany={company.isAdmin && isMyCompany}
            company={company}
            editing={editing}
            setEditing={setEditing}
          />
          <Photos
            editing={editing}
            onClick={(image: File) =>
              uploadBannerImage({ image, type: ImageTypes.more, companySlug: company.slug })
            }
            photos={company.photos}
            pending={photoPending && photoMeta?.type === ImageTypes.more}
            deleteImage={(id: string) => deletePhoto(id, ImageTypes.more, company.slug)}
            deleteMeta={{ id: (deleteMeta?.id as string) ?? undefined, pending: deletePending }}
          />
        </div>
      </main>
    </Paper>
  )
}

export default EditableDetailsFields
