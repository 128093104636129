import { User } from 'partnerslate-models'
import React, { Suspense } from 'react'
import { connect } from 'react-redux'
import { RouteComponentProps, withRouter } from 'react-router'
import { Redirect, Route } from 'react-router-dom'

import { Loading } from '@/components'
import { Routes } from '@/constants'
import { getAuth } from '@/helpers/auth'
import { endpoints } from '@/helpers/services'
import { StoreState } from '@/redux'
import selectors from '@/selectors'

type StateToPropsType = {
  user: User
  pending: boolean
}

interface PrivateRouteProps {
  component: React.ComponentType<any> | React.FunctionComponent<any>
  exact?: boolean
  path: string | undefined
}

function PrivateRoute(
  props: PrivateRouteProps & StateToPropsType & RouteComponentProps,
): JSX.Element {
  const { component, user, path, exact, location, pending } = props
  const token = getAuth()

  if (user.valid) {
    return (
      <Route
        exact={exact}
        path={path}
        location={location}
        component={component}
        search={location.search}
      />
    )
  }
  if (!user.valid && user.id) {
    return <Redirect to={{ pathname: Routes.VerifyEmail, search: location.search }} />
  }
  if (pending || token) {
    // Fallback to suspense will user is loading
    throw Promise.reject()
  }

  const toRoute = location.pathname === '/logout' ? '' : location.pathname
  return (
    <Redirect
      to={{
        pathname: Routes.Login,
        search: location.search,
        state: { toRoute },
      }}
    />
  )
}

PrivateRoute.defaultProps = {
  exact: false,
}

const mapStateToProps = (state: StoreState): StateToPropsType => ({
  user: selectors.user.getUser(state),
  pending: selectors.api.getPending(state, endpoints.me),
})

const PrivateRouteWithRouter = withRouter(connect(mapStateToProps)(PrivateRoute))

function WithSuspense(props: PrivateRouteProps): JSX.Element {
  return (
    <Suspense fallback={<Loading />}>
      <PrivateRouteWithRouter {...props} />
    </Suspense>
  )
}

WithSuspense.defaultProps = {
  exact: false,
}

export default WithSuspense
