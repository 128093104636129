import { Alert } from '@mui/material'
import * as React from 'react'
import { connect } from 'react-redux'

import { FutureThemeProvider } from '@/helpers/mui'
import { EndpointsWithIgnored } from '@/helpers/services'
import { StoreState } from '@/redux'
import selectors from '@/selectors'

import EmailLinkSupport from '../email-link-support'

interface FormErrorProps {
  /** FormKey to grab and reset submissions errors in the store and get error state
   * as we migrate away from redux fetching, the formKey wouldn't be used so we purposefully
   * pass IGNORED here as props
   */
  formKey: EndpointsWithIgnored // eslint-disable-line react/no-unused-prop-types
  error?: string | Array<{ field: string; message: string }> | null
}

interface Props extends FormErrorProps {
  isSupportLinkVisible?: boolean
}

function addPeriodIfNeeded(str: string) {
  if (!str.endsWith('.')) {
    return `${str}.`
  }
  return str
}

function MuiFormError({ error, isSupportLinkVisible = false }: Props) {
  return error ? (
    <FutureThemeProvider>
      <Alert severity="error" sx={{ marginY: 1 }}>
        {Array.isArray(error)
          ? error.map((e) => `${addPeriodIfNeeded(e.message)}\n`)
          : addPeriodIfNeeded(error)}
        {isSupportLinkVisible ? (
          <div>
            Contact <EmailLinkSupport linkText="customer support" /> for help.
          </div>
        ) : null}
      </Alert>
    </FutureThemeProvider>
  ) : null
}

MuiFormError.defaultProps = {
  error: null,
}

const mapStateToProps = (state: StoreState, props: FormErrorProps) => ({
  // As we transition away from redux fetching, more errors will be passed as props
  error: props.error || selectors.api.getError(state, props.formKey),
})

export default connect(mapStateToProps)(MuiFormError)
