import { FieldOption } from 'partnerslate-models'
import { useLocation } from 'react-router-dom'

import config from '../config'

export * as instrumentation from './instrumentation'

export const staticPath = (path: string): string => `${config.staticPath}${path}`

export const useQuery = (): URLSearchParams => new URLSearchParams(useLocation().search)

export const getLabelFromValue = (options: Array<FieldOption>, value: string): string | undefined =>
  options.find((opt) => opt.id === value)?.label

export const formatFieldOptions = (options: Array<FieldOption>): Array<FieldOption> => {
  const formattedFieldOptions: Array<FieldOption> = options.map(
    (opt) =>
      new FieldOption({
        ...opt,
        label: opt.group ? `${opt.group.name} - ${opt.label}` : opt.label,
      }),
  )

  return formattedFieldOptions
}

export default {}
