import styled from '@emotion/styled'
import Select from 'react-select'

import styles from './styles.scss'

type StyledReactProps = {
  error: boolean
}

const StyleSelect = styled(Select)`
  ${({ error }: StyledReactProps) =>
    error
      ? `
      > div {
        border-bottom-color: ${styles.red};
      }
    `
      : ''};
`

export default StyleSelect
