import React from 'react'

import { Paper, PrimaryButtonAnchor } from '@/components'
import Strings, { Images } from '@/constants'
import history from '@/helpers/history'

import styles from './styles.scss'

export function FourOhFour(): JSX.Element {
  return (
    <div className={styles.background}>
      <Paper className={styles.container}>
        <img
          src={Images.fourOhFour}
          alt={Strings.general.pageNotFound}
          className={styles.fourOhFourImage}
        />
        <h2>{Strings.general.pageNotFound}</h2>
        <p>{Strings.general.weCantFind}</p>
        <PrimaryButtonAnchor
          label={Strings.general.goBack}
          ariaLabel={Strings.general.goBack}
          onClick={() => history.goBack()}
          path=""
        />
      </Paper>
    </div>
  )
}

export default FourOhFour
