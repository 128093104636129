import * as React from 'react'

import TestIds from '@/accessibility/test-ids'
import { Input } from '@/components'
import Strings from '@/constants'

import { FieldNames } from '../schemas'
import styles from './styles.scss'

export interface UpdateCardFormValues {
  [FieldNames.nameOnCard]: string
  [FieldNames.cardNumber]: string
  [FieldNames.cardExpiration]: string
  [FieldNames.cvc]: string
}

interface Props {
  initialValues: UpdateCardFormValues
}

function UpdateCardForm({ initialValues }: Props): JSX.Element {
  return (
    <>
      <div className={styles.row}>
        <div className={styles.input}>
          <Input
            name={FieldNames.nameOnCard}
            label={Strings.profile.nameOnCard}
            placeholder={Strings.profile.johnSmith}
            testId={TestIds.Profile.NameOnCard}
            value={initialValues[FieldNames.nameOnCard]}
            onChange={() => {}}
            required
            disabled
          />
        </div>
      </div>
      <div className={styles.row}>
        <div className={styles.input}>
          <Input
            label={Strings.profile.cardNumber}
            placeholder={Strings.profile.cardNumber}
            name={FieldNames.cardNumber}
            value={initialValues[FieldNames.cardNumber].padStart(12, '*')}
            onChange={() => {}}
            required
            disabled
          />
        </div>
        <div className={styles.input}>
          <Input
            label={Strings.profile.cardExpiration}
            placeholder={Strings.profile.cardExpiration}
            name={FieldNames.cardExpiration}
            value={initialValues[FieldNames.cardExpiration]}
            onChange={() => {}}
            required
            disabled
          />
        </div>
        <div className={styles.input}>
          <Input
            label={Strings.profile.cvc}
            placeholder={Strings.profile.cvc}
            name={FieldNames.cvc}
            value={initialValues[FieldNames.cvc].padStart(3, '*')}
            onChange={() => {}}
            required
            disabled
          />
        </div>
      </div>
    </>
  )
}

export default UpdateCardForm
