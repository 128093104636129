import { ApiPayload, reduxSet as apiAC } from '@partnerslate/radio-dispatch'
import { Formik } from 'formik'
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { Dispatch } from 'redux'
import * as yup from 'yup'

import { endpoints } from '@/helpers/services'

import FormError from './form-error'
import UnsavedChangesConfirmation from './unsaved-changes-confirmation'

interface FormProps {
  /** FormKey to grab and reset submissions errors in the store and get pending / error state */
  formKey: keyof typeof endpoints
  /** Initial values to populate the form */
  initialValues: any
  /** Children can be used to render form fields */
  children: React.ReactElement<any> | Array<React.ReactElement<any>>
  /** Yup schema used to validate form inputs */
  validationSchema: yup.Schema<any>
  /** Function with curried access to the model being updated that returns the request payload */
  onSubmitPayload: (data: any) => ApiPayload<any, any, any>
  /** Shows a modal to confirm the navigation when the form is dirty and tries to navigate */
  confirmUnsavedChanges?: boolean
}
interface DispatchToProps {
  /** Dispatch request payload */
  makeRequest: (payload: ApiPayload<unknown>) => void
  /** Dispatch to clear store errors on unmount */
  clearError: (key: string) => void
}

type Props = FormProps & DispatchToProps

function BaseForm(props: Props) {
  const {
    onSubmitPayload,
    initialValues,
    validationSchema,
    clearError,
    formKey,
    children,
    makeRequest,
    confirmUnsavedChanges,
  } = props
  useEffect(() => () => clearError(formKey), [clearError, formKey])

  const onSubmit = (data: unknown) => {
    const payload = onSubmitPayload(data)
    makeRequest(payload)
  }
  return (
    <Formik
      initialValues={initialValues}
      onSubmit={onSubmit}
      validationSchema={validationSchema}
      validateOnChange
      validateOnMount
    >
      <>
        {children}
        {confirmUnsavedChanges && <UnsavedChangesConfirmation />}
      </>
    </Formik>
  )
}

BaseForm.defaultProps = {
  confirmUnsavedChanges: false,
}

const mapDispatchToProps = (dispatch: Dispatch) => ({
  clearError: (key: string) => dispatch(apiAC.clearError.dispatch(key)),
  makeRequest: (payload: ApiPayload<unknown>) => dispatch(apiAC.makeRequest.dispatch(payload)),
})

export { FormError }

export default connect(undefined, mapDispatchToProps)(BaseForm)
