// Imports
import ___CSS_LOADER_API_IMPORT___ from "../node_modules/css-loader/dist/runtime/api.js";
import ___CSS_LOADER_GET_URL_IMPORT___ from "../node_modules/css-loader/dist/runtime/getUrl.js";
import ___CSS_LOADER_URL_IMPORT_0___ from "../fonts/Lato-Regular.ttf";
import ___CSS_LOADER_URL_IMPORT_1___ from "../fonts/Lato-Black.ttf";
import ___CSS_LOADER_URL_IMPORT_2___ from "../fonts/Lato-Bold.ttf";
import ___CSS_LOADER_URL_IMPORT_3___ from "../fonts/Lato-Medium.ttf";
import ___CSS_LOADER_URL_IMPORT_4___ from "../fonts/Lato-Semibold.ttf";
import ___CSS_LOADER_URL_IMPORT_5___ from "../fonts/SourceSansPro-Regular.ttf";
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(true);
var ___CSS_LOADER_URL_REPLACEMENT_0___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_0___);
var ___CSS_LOADER_URL_REPLACEMENT_1___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_1___);
var ___CSS_LOADER_URL_REPLACEMENT_2___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_2___);
var ___CSS_LOADER_URL_REPLACEMENT_3___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_3___);
var ___CSS_LOADER_URL_REPLACEMENT_4___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_4___);
var ___CSS_LOADER_URL_REPLACEMENT_5___ = ___CSS_LOADER_GET_URL_IMPORT___(___CSS_LOADER_URL_IMPORT_5___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, "@font-face{\n  font-family:'Lato';\n\n  font-weight:normal;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_0___ + ")\n}\n\n@font-face{\n  font-family:'Lato';\n\n  font-weight:900;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_1___ + ")\n}\n\n@font-face{\n  font-family:'Lato';\n\n  font-weight:bold;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_2___ + ")\n}\n\n@font-face{\n  font-family:'Lato';\n\n  font-weight:lighter;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_3___ + ")\n}\n\n@font-face{\n  font-family:'Lato';\n\n  font-weight:500;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_4___ + ")\n}\n\n@font-face{\n  font-family:'Source Sans Pro';\n\n  font-display:block;\n\n  font-weight:normal;\n\n  src:url(" + ___CSS_LOADER_URL_REPLACEMENT_5___ + ")\n}\n", "",{"version":3,"sources":["webpack://fonts.scss"],"names":[],"mappings":"AAKA;EACE,kBAAmB;;EACnB,kBAAmB;;EACnB;AAAqC;;AAGvC;EACE,kBAAmB;;EACnB,eAAgB;;EAChB;AAAmC;;AAGrC;EACE,kBAAmB;;EACnB,gBAAiB;;EACjB;AAAkC;;AAGpC;EACE,kBAAmB;;EACnB,mBAAoB;;EACpB;AAAoC;;AAGtC;EACE,kBAAmB;;EACnB,eAAgB;;EAChB;AAAsC;;AAGxC;EACE,6BAA8B;;EAC9B,kBAAmB;;EACnB,kBAAmB;;EACnB;AAA8C","sourcesContent":["@import 'colors';\n@import 'metrics';\n@import 'grid';\n@import 'mixins';\n\n@font-face {\n  font-family: 'Lato';\n  font-weight: normal;\n  src: url('../fonts/Lato-Regular.ttf');\n}\n\n@font-face {\n  font-family: 'Lato';\n  font-weight: 900;\n  src: url('../fonts/Lato-Black.ttf');\n}\n\n@font-face {\n  font-family: 'Lato';\n  font-weight: bold;\n  src: url('../fonts/Lato-Bold.ttf');\n}\n\n@font-face {\n  font-family: 'Lato';\n  font-weight: lighter;\n  src: url('../fonts/Lato-Medium.ttf');\n}\n\n@font-face {\n  font-family: 'Lato';\n  font-weight: 500;\n  src: url('../fonts/Lato-Semibold.ttf');\n}\n\n@font-face {\n  font-family: 'Source Sans Pro';\n  font-display: block;\n  font-weight: normal;\n  src: url('../fonts/SourceSansPro-Regular.ttf');\n}\n"],"sourceRoot":""}]);
// Exports
___CSS_LOADER_EXPORT___.locals = {
	"black": "#2b352f",
	"buttonShadowGrey": "rgba(102,102,102,0.25)",
	"darkerGrey": "#4f4f4f",
	"darkGrey": "#828282",
	"error": "#e52a2a",
	"fontBlack": "#333",
	"grey": "#e0e0e0",
	"greyBorder": "#f1f1f1",
	"lightestGreen": "#f4fcfa",
	"lightestGrey": "#fcfcfc",
	"lightGrey": "#f5f6fa",
	"middleGrey": "#a9a9a9",
	"primary": "#20cb9a",
	"primaryDark": "#111827",
	"secondary": "#2f80ed",
	"secondaryOpacity": "rgba(47,128,237,0.3)",
	"secondaryLightestOpacity": "rgba(47,128,237,0.1)",
	"success": "#20cb9a",
	"tertiary": "#56666e",
	"warning": "#e88f40",
	"white": "#fff",
	"buttonWidthMobile": "100px",
	"mobileBreakpoint": "600px",
	"bigspacer": "25px",
	"buttonHeight": "44px",
	"cardTitleFont": "18px",
	"inputHeight": "48px",
	"profilePhoto": "135px",
	"slimButtonHeight": "24px",
	"smallAction": "26px",
	"smallestFont": "12px",
	"smallestSpacer": "8px",
	"smallFont": "14px",
	"spacer": "15px",
	"standardFont": "16px",
	"subtitleFont": "16px",
	"titleFont": "26px"
};
export default ___CSS_LOADER_EXPORT___;
