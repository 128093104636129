import ArrowCircleRightIcon from '@mui/icons-material/ArrowCircleRight'
import { Box, Button, Stack, Typography } from '@mui/material'
import { Company, User } from 'partnerslate-models'
import React from 'react'
import { useQueryClient } from 'react-query'
import { connect } from 'react-redux'
import { Redirect, RouteComponentProps, withRouter } from 'react-router'

import { Loading } from '@/components'
import Anchor, { ExternalAnchor } from '@/components/anchor'
import Strings, { Routes } from '@/constants'
import { LoginForm } from '@/forms'
import { getAuth } from '@/helpers/auth'
import { LocationState } from '@/helpers/history'
import { StoreState } from '@/redux'
import postLoginRoute from '@/screens/login/post-login-route'
import selectors from '@/selectors'

import styles from './styles.scss'

interface Props {
  user: User
  company: Company
}

type LoginProps = {
  useComanMarketingLingo?: boolean
}

function Login({ useComanMarketingLingo = false }: LoginProps) {
  return (
    <div className={styles.container}>
      <div className={styles.leftContainer}>
        <div className={styles.formContainer}>
          <h1 className={styles.title}>
            {useComanMarketingLingo ? 'Welcome' : Strings.auth.login.title}
          </h1>
          <LoginForm />
          <Anchor
            label={Strings.auth.login.forgotPassword}
            ariaLabel={Strings.auth.login.forgotPassword}
            className={styles.forgotPasswordButton}
            path={Routes.ResetPassword}
          />
          <div className={styles.loginDivider} />
          <div className={styles.signUpContainer}>
            <p>{Strings.auth.login.haveAnAccount}</p>
            <ExternalAnchor
              label={Strings.auth.login.signup}
              path="/register"
              ariaLabel={Strings.auth.login.signup}
              className={styles.signUpButton}
            />
          </div>
        </div>
      </div>
      {useComanMarketingLingo ? (
        <Box className={styles.rightContainerComanLingo} display="flex" flexDirection="column">
          <Box minHeight={{ md: '200px', sm: 0 }} />
          <Box display="flex" alignItems="center" justifyContent="center" height="100vh">
            <Box className={styles.welcomeText} color="primary.contrastText" sx={{ px: 1 }}>
              <Typography fontSize="36px" color="primary.contrastText" mb={3} textAlign="center">
                Welcome to PartnerSlate!
              </Typography>
              <Typography
                fontSize="24px"
                lineHeight="28px"
                color="primary.contrastText"
                mb={3}
                textAlign="center"
              >
                Get started today for FREE and join our network of 6,000+ co-manufacturers&nbsp;to:
              </Typography>
              <Box mx={8}>
                <Box display="flex" flexDirection="column" textAlign="initial" my={1}>
                  <Stack direction="row" alignItems="center" gap={1} py={0.25}>
                    <ArrowCircleRightIcon />
                    <Typography fontSize="18px" color="primary.contrastText">
                      Browse projects on the marketplace
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" gap={1} py={0.25}>
                    <ArrowCircleRightIcon />
                    <Typography fontSize="18px" color="primary.contrastText">
                      Get custom leads directly to your inbox
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" gap={1} py={0.25}>
                    <ArrowCircleRightIcon />
                    <Typography fontSize="18px" color="primary.contrastText">
                      Leverage PartnerSlate Matching Advisors
                    </Typography>
                  </Stack>
                  <Stack direction="row" alignItems="center" gap={1} py={0.25}>
                    <ArrowCircleRightIcon />
                    <Typography fontSize="18px" color="primary.contrastText">
                      Be found by Fortune 500 companies
                    </Typography>
                  </Stack>
                  <Button
                    href="/register"
                    variant="contained"
                    sx={{ mt: 5, mb: 2, py: 1 }}
                    style={{
                      borderRadius: 4,
                      backgroundColor: '#FF6E40',
                    }}
                  >
                    Start for free *
                  </Button>
                  <Typography variant="subtitle1" color="primary.contrastText" textAlign="center">
                    *You only pay a success fee of 3% when you reach production with a PartnerSlate
                    brand.
                  </Typography>
                </Box>
              </Box>
            </Box>
          </Box>
        </Box>
      ) : (
        <div className={styles.rightContainer} />
      )}
    </div>
  )
}

function safeLocationReplace(redirectRoute: string) {
  let safeInternalRedirect = '/'
  if (redirectRoute.charAt(0) === '/') {
    safeInternalRedirect = redirectRoute
  }
  window.location.replace(safeInternalRedirect)
}

export function LoginScreen({
  user,
  company,
  location,
}: Props & RouteComponentProps<any, any, LocationState>): JSX.Element {
  const queryClient = useQueryClient()
  const token = getAuth()

  if (token && !user.id) {
    return <Loading />
  }

  if (user.valid) {
    // user might be logging back in as a result of being logged out via
    // session expiry so we want to clear the query cache in case
    queryClient.clear()

    // if main app has existing redirect (i.e. from email) continue to it and ignore projects/engagement count
    if (location.state) {
      return <Redirect to={{ pathname: location.state.toRoute, search: location.search }} />
    }

    // if leadmanager has existing redirect continue to it and ignore projects/engagement count
    if (location.search && location.search.includes('nextUrl')) {
      const nextURL = decodeURIComponent(location.search.replace('?nextUrl=', ''))
      // here we use window.location.replace because after login, we want to actually do a
      // browser location redirect, instead of an internal redirection via React Router's
      // <Redirect to=..> which will potentially land on a 404 (for the /leads route)Added
      safeLocationReplace(nextURL)
    } else {
      const nextRoute = postLoginRoute({
        user,
        company,
      })

      if (nextRoute.useReactRouter) {
        return (
          <Redirect
            to={{
              pathname: nextRoute.nextUrl,
              search: location.search,
            }}
          />
        )
      }
      safeLocationReplace(nextRoute.nextUrl)
    }
  }
  const queryParams = new URLSearchParams(location.search)
  const utmSource = queryParams.get('utm_source')
  const utmMedium = queryParams.get('utm_medium')
  const useComanMarketingLingo = utmSource === 'ps_app' && utmMedium === 'mp_connect_me'

  return <Login useComanMarketingLingo={useComanMarketingLingo} />
}

const mapStateToProps = (state: StoreState): Props => {
  const user = selectors.user.getUser(state)
  const company = selectors.company.getCompany(state, user.company)

  return {
    user,
    company,
  }
}

export default withRouter(connect(mapStateToProps)(LoginScreen))
