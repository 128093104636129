import { ModalActions } from './constants'
import ModalTypes from './types'

interface EventManager {
  events: Map<string, (type?: keyof ModalTypes, payload?: Record<string, unknown>) => void>
  rendered: boolean
  mounted: boolean
  register: (action: string, callback: (args?: any) => void) => EventManager
  clear: (action: string) => EventManager
  emit: (
    action: typeof ModalActions[keyof typeof ModalActions],
    type?: keyof ModalTypes,
    props?: Record<string, unknown>,
  ) => EventManager
}

const eventManager = {
  events: new Map<string, (type?: keyof ModalTypes, payload?: Record<string, unknown>) => void>(),
  rendered: false,
  mounted: false,

  register(action: string, callback: (args?: any) => void): EventManager {
    this.events.set(action, callback)
    return this
  },

  clear(action: string): EventManager {
    this.events.delete(action)
    return this
  },

  emit(
    action: typeof ModalActions[keyof typeof ModalActions],
    type?: keyof ModalTypes,
    payload?: Record<string, unknown>,
  ): EventManager {
    if (action === ModalActions.RENDER_MODAL) {
      this.rendered = true
      if (this.events.has(ModalActions.DEACTIVATE_APP_SCROLL)) {
        this.emit(ModalActions.DEACTIVATE_APP_SCROLL)
      }
    } else if (action === ModalActions.REMOVE_MODAL) {
      this.emit(ModalActions.ACTIVATE_APP_SCROLL)
      this.rendered = false
    }
    const fn = this.events.get(action)
    if (fn) {
      fn.call(null, type, payload)
    }
    return this
  },
}

export default eventManager
