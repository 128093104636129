import { joinStr } from '@clearsummit/carabiners'
import React from 'react'

import { Images } from '@/constants'

import styles from './styles.scss'

interface Props {
  image: string
  alt: string
  className?: string
}

const Avatar: React.FC<Props> = (props: Props) => {
  const { image, alt, className } = props
  return <img className={joinStr(styles.avatar, className)} src={image || Images.user} alt={alt} />
}

Avatar.defaultProps = {
  image: undefined,
  className: undefined,
}

export const MediumAvatar: React.FC<Props> = (props: Props) => {
  const { image, alt, className } = props
  return (
    <img className={joinStr(styles.mediumAvatar, className)} src={image || Images.user} alt={alt} />
  )
}

MediumAvatar.defaultProps = {
  image: undefined,
  className: undefined,
}

export const SmallAvatar: React.FC<Props> = (props: Props) => {
  const { image, alt, className } = props
  return (
    <img className={joinStr(styles.smallAvatar, className)} src={image || Images.user} alt={alt} />
  )
}

SmallAvatar.defaultProps = {
  image: undefined,
  className: undefined,
}

export default Avatar
