import api from './api'
import s3 from './s3'
import { stripeService } from './stripe'

const Services = {
  ...api,
  ...s3,
  ...stripeService,
}

export type ServiceEndpoints = keyof typeof Services

// @ts-ignore
export function keyObj<O>(obj: O): { [key in O]: keyof O } {
  // @ts-ignore
  return Object.keys(obj).reduce((acc: { [key in O]: string }, curr: string) => {
    // @ts-ignore
    acc[curr] = curr
    return acc
  }, {})
}

// @ts-ignore
export const endpoints: { [key in ServiceEndpoints]: ServiceEndpoints } =
  keyObj<typeof Services>(Services)

export type EndpointsWithIgnored = 'IGNORED' | keyof typeof endpoints

export default Services
